import React from 'react';
import cls from 'classnames';
import classes from './styles.module.scss';
import FormFieldGrid from './form-field-grid';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import PassportScan1Image from 'assets/images/passport-scan-1.jpg'
import { validationError } from './validation-error';

const UploadField = ({isDisabled, ...props}) => {
  const { 
    input, 
    FormFieldGridProps,
    validateOnMount,
    meta
  } = useFieldApi(props);

  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);
  const invalid = validationError(meta, validateOnMount)

  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      input.onChange({"inputValue": e.dataTransfer.files[0].name, "inputFiles":e.dataTransfer.files});
    }
  };

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <div onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} className={cls(classes.filedrop, !!invalid ? classes.filedrop__error:'', dragActive ? classes['filedrop--dragging']:undefined)} style={isDisabled ? {opacity: '0.5'}:{}}>

            <label htmlFor={isDisabled ? null:input.name}>
              <div className={cls(classes.filedrop__passport_scan)}>
                <img src={PassportScan1Image} alt="the first page of the passport" height='110px' />
              </div>
              <span className={cls(classes.upload__label, classes.upload__label_container)}>
                <span className={cls(classes.upload__label_blue)} style={isDisabled ? {cursor: 'default'}:{}}>Загрузите или перетяните сюда первую страницу паспорта</span>{" "}
                {/* <span className={cls(classes.upload__label_black)}>
                  
                </span> */}{" "}
                
                (pdf/jpeg/jpg/png файл до 5 МБ) {/* props.isRequired &&  */<span aria-hidden="true" className={cls('MuiFormLabel-asterisk', 'MuiInputLabel-asterisk', 'css-wgai2y-MuiFormLabel-asterisk')}> *</span>}
              </span>
            </label>
      </div>
      <input  name={input.name} type='text' style={{opacity: 0, height: 0, border: 0, padding: 0}}/>
      <input id={input.name} name={input.name} onBlur={input.onBlur} ref={inputRef} onChange={isDisabled ? null:input.onChange} onFocus={input.onFocus} type={input.type} style={{display: 'none'}} />
      
    </FormFieldGrid>
  );
};

export default UploadField