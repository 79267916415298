import * as React from 'react';
import SurveySimpleMenuStepNumber from 'components/elements/survey-simple-menu-step-number';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import classes from './styles.module.scss';
import { CheckIcon } from 'components/elements/icons';

export default function IconMenu({ customerState, activeNumber, callback }) {
  const { t } = useTranslation('main');

  const finished = [
    null,
    customerState.QuestionnaireSteps?.FirstQuestionnaireStep,
    customerState.QuestionnaireSteps?.SecondQuestionnaireStep,
    customerState.QuestionnaireSteps?.ThirdQuestionnaireStep,
    customerState.QuestionnaireSteps?.FourthQuestionnaireStep,
    customerState.QuestionnaireSteps?.FifthQuestionnaireStep,
    customerState.QuestionnaireSteps?.FifthQuestionnaireStep 
  ]

  const step6Enabled = customerState.QuestionnaireSteps?.FirstQuestionnaireStep &&
  customerState.QuestionnaireSteps?.SecondQuestionnaireStep &&
  customerState.QuestionnaireSteps?.ThirdQuestionnaireStep &&
  customerState.QuestionnaireSteps?.FourthQuestionnaireStep &&
  customerState.QuestionnaireSteps?.FifthQuestionnaireStep

  return (
      <div className={cls(classes['menu'])}>
        <Link to='/survey/s1' className={cls(classes['menu__link'])} onClick={callback}>
          <SurveySimpleMenuStepNumber active={activeNumber === 1} finished={finished[1]} name={t(`survey-page.steps.1.name`)} step='1'/>
          {activeNumber === 1 && <CheckIcon className={cls(classes['menu__icon'])}/>}
        </Link>
        <Link to='/survey/s2' className={cls(classes['menu__link'])} onClick={callback}>
          <SurveySimpleMenuStepNumber active={activeNumber === 2} finished={finished[2]} name={t(`survey-page.steps.2.name`)} step='2'/>
          {activeNumber === 2 && <CheckIcon className={cls(classes['menu__icon'])}/>}
        </Link>
        <Link to='/survey/s3' className={cls(classes['menu__link'])} onClick={callback}>
          <SurveySimpleMenuStepNumber active={activeNumber === 3} finished={finished[3]} name={t(`survey-page.steps.3.name`)} step='3'/>
          {activeNumber === 3 && <CheckIcon className={cls(classes['menu__icon'])}/>}
        </Link>
        <Link to='/survey/s4' className={cls(classes['menu__link'])} onClick={callback}>
          <SurveySimpleMenuStepNumber active={activeNumber === 4} finished={finished[4]} name={t(`survey-page.steps.4.name`)} step='4'/>
          {activeNumber === 4 && <CheckIcon className={cls(classes['menu__icon'])}/>}
        </Link>
        <Link to='/survey/s5' className={cls(classes['menu__link'])} onClick={callback}>
          <SurveySimpleMenuStepNumber active={activeNumber === 5} finished={finished[5]} name={t(`survey-page.steps.5.name`)} step='5'/>
          {activeNumber === 5 && <CheckIcon className={cls(classes['menu__icon'])}/>}
        </Link>
        {
          step6Enabled ?
        <Link to='/survey/s6' className={cls(classes['menu__link'])} onClick={callback}>
          <SurveySimpleMenuStepNumber active={activeNumber === 6} finished={finished[6]} disabled={!step6Enabled} name={t(`survey-page.steps.6.name`)} step='6'/>
          {activeNumber === 6 && <CheckIcon className={cls(classes['menu__icon'])}/>}
        </Link>:
        <div className={cls(classes['menu__link'])}>
        <SurveySimpleMenuStepNumber active={activeNumber === 6} finished={finished[6]} disabled={!step6Enabled} name={t(`survey-page.steps.6.name`)} step='6'/>
        {activeNumber === 6 && <CheckIcon className={cls(classes['menu__icon'])}/>}
        </div>
        }
      </div>
  );
}
