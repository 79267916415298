import React from 'react';
import cls from 'classnames';
import classes from './footer.module.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return  <footer className={cls(classes['footer'])}>
            <div className={cls(classes['footer__inner'])}>
              <div className={cls(classes['footer__part-one'])}>
                <div className={cls(classes['footer__item'], classes['footer__item_mb'])}  dangerouslySetInnerHTML={{ __html: t('footer:copyright', { year: new Date().getFullYear() }) }} />
                <div className={cls(classes['footer__item'])}><a href='https://payture.com/privacy' target='_blank' rel="noreferrer">{t('footer:privacy_title')}</a></div>
              </div>
              <div className={cls(classes['footer__part-two'])}>
                <div className={cls(classes['footer__item'])}><a href='tel:+74957838393'>{t('footer:phone_number')}</a></div>
                <div className={cls(classes['footer__item'])}><a href={`mailto:${t('footer:email_address')}`}>{t('footer:email_address')}</a></div>
              </div>
            </div>
          </footer>
}

export default React.memo(Footer)
