import React from 'react';
import { styled } from '@mui/material/styles';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import PropTypes from 'prop-types';
import { Grid, Button as MUIButton, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import clsx from 'clsx';
import { LoadingButton } from 'components/elements/icons';
import FormTemplate from './template-rewritten';

const PREFIX = 'MuiFormTemplate';

const classes = {
  buttonGroup: `${PREFIX}-buttonGroup`,
};


const Form = ({ children, GridContainerProps, GridProps, ...props }) => (
  <Grid item xs={12} {...GridProps}>
    <form noValidate {...props}>
      <Grid container rowSpacing={2} item xs={12} {...GridContainerProps}>
        {children}
      </Grid>
    </form>
  </Grid>
);

Form.propTypes = {
  children: PropTypes.node,
  GridProps: PropTypes.object,
  GridContainerProps: PropTypes.object,
};

const Description = ({ children, GridProps, ...props }) => (
  <Grid item xs={12} {...GridProps}>
    <Typography variant="body1" gutterBottom {...props}>
      {children}
    </Typography>
  </Grid>
);

Description.propTypes = {
  children: PropTypes.node,
  GridProps: PropTypes.object,
};

const Title = ({ children, GridProps, ...props }) => (
  <Grid item xs={12} {...GridProps}>
    <Typography variant="h3" gutterBottom {...props}>
      {children}
    </Typography>
  </Grid>
);

Title.propTypes = {
  children: PropTypes.node,
  GridProps: PropTypes.object,
};

const ButtonGroup = ({ children, GridProps, ...props }) => {
  return <StyledButtonGroup item xs={12} {...GridProps}>
    <div className={classes.buttonGroup} {...props}>
      {children}
    </div>
  </StyledButtonGroup>
}

ButtonGroup.propTypes = {
  children: PropTypes.node,
  GridProps: PropTypes.object,
};


const StyledButtonGroup = styled(Grid)(() => ({
  [`& .${classes.buttonGroup}`]: {
    display: 'flex',
    paddingTop: '16px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    //gap: '8px',
    "& :nth-of-type(2)": {
      width: "60%!important"
    },
    ' > * + *': { 
      marginRight: '8px'
    }
  },
}));

const StyledButton = styled(MUIButton)(() => ({
  
    width: '100%',
    color: '#ffffff!important',
    borderRadius: '90px',
    backgroundColor: '#8B74D8',

    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    padding: '14px 24px',
    '&:active': {
      backgroundColor: '#625FBF'
    },
    '&:hover': {
      backgroundColor: '#625FBF'
    },
    '&:disabled': {
      backgroundColor: '#BFB8D5'
    },
    '&.submitting': {
      backgroundColor: '#8B74D8',
      height: '48px',
      'svg': {
        animationName: 'rotation',
        animationDuration: '2s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear'
      }
    }
}));

const StyledButtonCancel = styled(MUIButton)((style) => ({
  
  width: '60%',
  ...style,
  color: '#8B74D8!important',
  borderRadius: '90px',
  backgroundColor: 'transparent!important',
  border: '2px solid #8B74D8!important', 
  textTransform: 'none',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  padding: '14px 24px',
  '&:active': {
    borderColor: '#625FBF!important',
    color: '#625FBF!important'
  },
  '&:hover': {
    borderColor: '#625FBF!important',
    color: '#625FBF!important'
  },
  '&:disabled': {
    borderColor: '#BFB8D5!important',
    color: '#BFB8D5!important'
  }
}));

const Button = ({ label, variant, children, buttonType, loadingButton, ...props }) => {
  const formOptions = useFormApi();
  
  if (buttonType === 'cancel') {
    return <StyledButtonCancel variant="outlined" style={{width: '100%'}} {...props}>
      {label || children}
    </StyledButtonCancel> 
  } else {
    return <StyledButton variant="contained" {...props} className={formOptions.getState().submitting && 'submitting'}>
      {(formOptions.getState().submitting || loadingButton) ? <LoadingButton width="16" height="16" />:(label || children)}
    </StyledButton>   
  }
};

Button.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  variant: PropTypes.string,
  buttonType: PropTypes.string,
};

const StyledAlert = styled(Alert)(() => ({
  '& .alert': {
    width: '100%',
    margin: 8,
  },
}));

export const FormError = ({ formError, alertProps }) => {
  if (typeof formError === 'object' && (formError.title || formError.title)) {
    const { title, description, TitleProps, className, ...props } = formError;

    return (
      <StyledAlert severity="error" {...props} {...alertProps} className={clsx('alert', alertProps?.className, className)}>
        {title && <AlertTitle {...TitleProps}>{title}</AlertTitle>}
        {description}
      </StyledAlert>
    );
  }

  if (formError) {
    return (
      <StyledAlert severity="error" {...alertProps} className={clsx('alert', alertProps?.className)}>
        {formError}
      </StyledAlert>
    );
  }

  return null;
};

FormError.propTypes = {
  formError: PropTypes.any,
  alertProps: PropTypes.object,
};

const MuiFormTemplate = (props) => {
  return <FormTemplate
          BeforeError={FormError}
          FormWrapper={Form}
          Button={Button}
          ButtonGroup={ButtonGroup}
          Title={Title}
          Description={Description}
          {...props}
        />
}

export default MuiFormTemplate;
