
import componentMapper from "@data-driven-forms/mui-component-mapper/component-mapper";
import { TextField, PasswordField, PhoneField, Select, 
  FieldListenerWrapper, PlainText, Checkbox, DatePicker, SnilsField,
  FileUpload, FieldArray, Textarea, PassportField, FileNameField, 
  UploadField, DivisionField, AddressField, PassportWhomField,
  ActualTypeOfActivityField, BankNameField, SliderField, BetHelperField,
  PassportScanUploadField, PassportScanFileNameField, 
  PassportScanExtraUploadField, PassportScanExtraFileNameField,
  AddressIndexField } from 'ddf/form-controls'

const customComponentMapper = {
  ...componentMapper,
  'TextField': TextField,
  'PasswordField': PasswordField,
  'PhoneField': PhoneField,
  'Select': Select,
  'PlainText': PlainText,
  'field-listener': FieldListenerWrapper,
  'Checkbox': Checkbox,
  'DatePicker': DatePicker,
  'FileField': FileUpload,
  'FileNameField': FileNameField,
  'FieldArray': FieldArray,
  'Textarea': Textarea,
  'PassportField': PassportField,
  'SnilsField': SnilsField,
  'UploadField': UploadField,
  'DivisionField': DivisionField,
  'AddressField': AddressField,
  'PassportWhomField': PassportWhomField,
  'ActualTypeOfActivityField': ActualTypeOfActivityField,
  'BankNameField': BankNameField,
  'SliderField': SliderField,
  'BetHelperField': BetHelperField,
  'PassportScanUploadField': PassportScanUploadField,
  'PassportScanExtraUploadField': PassportScanExtraUploadField,
  'PassportScanFileNameField': PassportScanFileNameField,
  'PassportScanExtraFileNameField': PassportScanExtraFileNameField,
  'AddressIndexField': AddressIndexField
};


export default customComponentMapper
