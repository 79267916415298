import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { FileDrop } from "react-file-drop";

export default function FileUploader({
  className,
  children,
  onChange,
  id,
  maxSize,
  types = [],
  disabled = false,
  ...props
}) {
  const inputRef = React.useRef(null);
  const [over, setOver] = React.useState(false);

  const handleDragOver = () => {
    setOver(true);
  };

  const handleDragLeave = () => {
    setOver(false);
  };

  const handleSelect = (event) => {
    const { files } = event.target;

    if (disabled) {
      return;
    }

    if (typeof onChange === "function") {
      onChange(files);
    }
  };

  const handleDrop = (files) => {
    setOver(false);

    if (disabled) {
      return;
    }

    if (typeof onChange === "function") {
      onChange(files);
    }
  };

  return (
    <div className={cls(classes.field, className)}>
      <input
        type="file"
        id={id}
        accept=".pdf, .jpg, .jpeg, .png"
        className={cls(classes.input)}
        onChange={handleSelect}
        ref={inputRef}
        disabled={disabled}
      />
      <FileDrop
        className={cls(classes.filedrop, over && classes["filedrop--dragging"])}
        targetClassName={cls(classes.filedrop__target)}
        onTargetClick={() => inputRef?.current?.click()}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        {...props}
      >
        {typeof children === "function" &&
          children({
            draggedOver: over,
          })}
      </FileDrop>
    </div>
  );
}
