import React from 'react';
import cls from 'classnames';
import classes from './styles.module.scss';
import FormFieldGrid from './form-field-grid';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { PaperIcon, DownloadIcon, LoadingIcon, UploadedIcon } from 'components/elements/icons';
import { useTranslation } from 'react-i18next';

const Filename = (props) => {
  const {
    input,
    FormFieldGridProps,
    callback,
    isLoading,
    isDisabled,
    isDownloadable = true,
    isResult = true,
    checkerFieldName = false,
  } = useFieldApi(props);

  const formOptions = useFormApi();
  const fp = checkerFieldName && formOptions.schema.fields.find((i)=>i.name === checkerFieldName)
  const { meta } = useFieldApi(fp)
  //debugger
  const { t } = useTranslation();

  const nodeRef = React.createRef(null);

  const handleTryMore = () => {
    window.location.reload();
  }

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      {
        input.value &&
        <div className={cls(classes['upload__selection'])} style={isDisabled ? {opacity: '0.5'}:{}}>
          <TransitionGroup>
                <CSSTransition
                  nodeRef={nodeRef}
                  key={input.value}
                  timeout={300}
                >
                  <div
                    ref={nodeRef}
                    className={cls(
                      classes.file
                    )}
                  >
                    <div className={cls(classes.file__layout, classes.file__layout_order, (input.value === '' || (meta.error && checkerFieldName)) && classes.file__error_order)}>
                      {
                      isResult ? <PaperIcon height="24" width="24" />:
                                 <UploadedIcon height="24" width="24" className={classes.shrink} />
                      }

                      <span className={cls(classes.file__name)}>
                        {
                          input.value !== '' ? input.value:t('main:survey-page.steps.6.document-prepare-fail')
                        }
                      </span>
                      { checkerFieldName && meta.error &&
                        <div className={cls(classes.file__error)}><span>{meta.error}</span></div>
                      }
                      {
                        input.value !== '' ?
                        (isLoading ? <div className={cls(classes['download-section'])} onClick={callback} >
                                      <LoadingIcon 
                                        height='24'
                                        width='70'
                                        className="loading"
                                      />
                                    </div>:
                        (isDownloadable ? <div className={cls(classes['download-section'])} onClick={isDisabled ? null:callback}  style={isDisabled ? {cursor: 'default'}:{}}>
                          <DownloadIcon 
                            style={{ cursor: "pointer", marginLeft: !meta.error && "auto"}}
                            height='14'
                            width='14'
                            className={cls(classes.file__remove)}         
                          />
                          <span>{t('main:download')}</span>
                        </div>:null)):
                        <div onClick={handleTryMore} className={cls(classes['download-section'])}>
                          <span>{t('main:survey-page.steps.6.try-more')}</span>
                        </div>
                      }
                    </div>
                  </div>
                </CSSTransition>
          </TransitionGroup>
        </div>
      }
    </FormFieldGrid>
  );
};

export default Filename
