import React, { useEffect } from 'react';
import {router} from 'routes';
import { setupYandexMetrika } from 'components/utils/yandexMetrika';
/* import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; */
import { RouterProvider } from 'react-router-dom';

function App() {
  
  useEffect(() => {
    process.env.NODE_ENV === "production" && 
      setupYandexMetrika(92846897)
  }, []);

  return (
    <>
      <RouterProvider router={router} />
    </>

  );
}

export default App;
