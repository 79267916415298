import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { Link } from "react-router-dom";
import { CheckIcon } from "components/elements/icons";

const LinkWrapper = ({children, disabled, active, step, className}) => {
  return (!disabled&&!active) ? <Link to={`/survey/s${step}`} className={className}>
    {children}
  </Link>:<span className={cls(classes['step-number-link'])}>
    {children}
  </span>
}

const Element = ({ active=false, finished=false, disabled=false, step, name, lineClassName='', className='' }) => {
  return (
    <div className={cls(active && 'active', finished && 'finished', 'line-horizontal', classes['step-number-container'], className)}>
      {/* <Link to={`/survey/s${step}`} className={cls(classes['step-number-link'])}>*/}
      <LinkWrapper step={step} active={active} disabled={disabled} className={cls(classes['step-number-link'])}> 
      <div className={cls( active && 'active', finished && 'finished')}>
        <div className={cls(className,classes['step-number-wrapper'])}>
          <div className={
            cls(
              classes['step-number'], 
              active && classes['step-number_active'], 
              finished && classes['step-number_finished'],
              disabled && classes['step-number_disabled']
            )
          }>
          {
            finished ? <CheckIcon width='12' height='9'/>:step
          }  
          </div>        
        </div>
      </div>
      </LinkWrapper>
      {/* </Link> */}
      {
        (disabled||active) ?
        <span 
          dangerouslySetInnerHTML={{ __html: name}} 
          className={
            cls(
              classes['step-number__name'], 
              active   && classes['step-number__name_active'], 
              finished && classes['step-number__name_finished'],
              disabled && classes['step-number__name_disabled']
            )
          } 
        />:
        <Link 
          to={`/survey/s${step}`} 
          className={
            cls(
              classes['step-number__name'], 
              active   && classes['step-number__name_active'], 
              finished && classes['step-number__name_finished'],
              disabled && classes['step-number__name_disabled']
            )
          } 
          dangerouslySetInnerHTML={{ __html: name}} />
      }
    </div>
  );
}

export default Element