import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)(() => ({
  
  width: '100%',
  color: '#ffffff!important',
  borderRadius: '90px',
  backgroundColor: '#8B74D8',

  textTransform: 'none',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  padding: '14px 24px',
  '&:active': {
    backgroundColor: '#625FBF'
  },
  '&:hover': {
    backgroundColor: '#625FBF'
  },
  '&:disabled': {
    backgroundColor: '#BFB8D5'
  },
  '&.submitting': {
    backgroundColor: '#8B74D8',
    height: '48px',
    'svg': {
      animationName: 'rotation',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear'
    }
  }
}));

export const StyledButtonCancel = styled(Button)((style) => ({
  width: '60%',
  ...style,
  color: '#8B74D8!important',
  borderRadius: '90px',
  backgroundColor: 'transparent!important',
  border: '2px solid #8B74D8!important', 
  textTransform: 'none',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  padding: '14px 24px',
  '&:active': {
    borderColor: '#625FBF!important',
    color: '#625FBF!important'
  },
  '&:hover': {
    borderColor: '#625FBF!important',
    color: '#625FBF!important'
  },
  '&:disabled': {
    borderColor: '#BFB8D5!important',
    color: '#BFB8D5!important'
  }
}));

