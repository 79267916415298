import React from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import WhitePaper from 'components/elements/white-paper';
import classes from './profile.module.scss';
import { footnoteTranslate } from 'ddf/form-controls/utils';

const ProfilePageView = ({ customerState }) => {
  const { t } = useTranslation(['main', 'forms']);
  
  return <>
          <WhitePaper>  
            <div className={cls(classes['profile-page'])}>
              <div className={cls(classes['profile-page__title'])}>
                {t('profile-page.title')}
              </div>
              <div className={cls(classes['profile-page__items'])}>
              {
                t('profile-page.items', { returnObjects: true }).map((i, index)=>{
                  let obj = Object.entries(i)
                  
                  let value = ''

                  switch (obj[0][0]) {
                    case 'CustomerType':
                      value = customerState[obj[0][0]] === 1 ? "Индивидуальный предприниматель":"Юридическое лицо" 
                    break;
                    case 'Turnover':
                      let splited = String(customerState[obj[0][0]]).split('-');
                      value = splited.map((i)=>{ return i.replace(/(.)(?=(\d{3})+$)/g,'$1 ')}).join(' - ') + " ₽"
                    break;
                    case 'Phone':
                      let match = String(customerState[obj[0][0]]).match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

                      value =  '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4]
                    break;
                    default:
                      value = customerState[obj[0][0]]
                  }
                  return (
                    <>
                    <div className={cls(classes['profile-page__item'])}>
                      <div className={cls(classes['profile-page__caption'])}>
                        {obj[0][1]}
                      </div>
                      <div className={cls(classes['profile-page__value']/* , index < 3 && classes['profile-page__value_big'] */)}>
                        {value}
                      </div>
                    </div>
                    {
                      (index === 0 || index === 1 || index === 3 || index === 4) && <div className={cls(classes['profile-page__item'], classes['profile-page__item_wrapper'])}>
                        <div className={cls(classes['profile-page__wrapper'])}></div>
                      </div>
                    }
                    </>
                  )
                })
              }
              </div>
            </div>
          </WhitePaper>
          <WhitePaper  className={cls(classes['margin-top'])}>  
            <div className={cls(classes['profile-page'])}>
              <div className={cls(classes['profile-page__title'])}>
                {t('profile-page.title2')}
                {
                  footnoteTranslate({type: "help", message: "", hint: t('forms:turnover-hint'), threshold: "", svgClass: classes['question-mark'], style: {'marginTop':0}})
                }  
              </div>
              <div className={cls(classes['profile-page__items'])}>
                {
                  customerState.Bets.map((i, index)=>{

                    return <>
                    <div className={cls(classes['profile-page__item'], classes['profile-page__item_width'])}>
                      <div className={cls(classes['profile-page__caption'])}>
                      {
                        t(`banks:items.${i.BankId}.name`)
                      }
                      </div>
                      <div className={cls(classes['profile-page__value']/* , index < 3 && classes['profile-page__value_big'] */)}>
                      {
                        i.Bet ? i.Bet+"%":"В ближайшее время персональный менеджер свяжется с вами для согласования индивидуальных условий"
                      }
                      &nbsp;{i && i.MinFee ? ('(минимум ' + i.MinFee/100 + ' ₽)'):''}
                      </div>
                    </div>
                    {
                      ((index === 0 || index === 1 || index === 3 || index === 4) && customerState.Bets && customerState.Bets.length > index+1) && <div className={cls(classes['profile-page__item'], classes['profile-page__item_wrapper'])}>
                        <div className={cls(classes['profile-page__wrapper'])}></div>
                      </div>
                    }
                    </>
                })
              }
              </div>
            </div>
          </WhitePaper>
        </>         
}

export default ProfilePageView;
