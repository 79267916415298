import React, { useState } from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import WhitePaperWithTopper from 'components/elements/white-paper-with-topper';
import classes from './fz54.module.scss';
import { Link } from "react-router-dom";
import Modal from 'components/organizms/modal';
import ModalOrangeData from './modal-orangedata'
import ModalAtol from './modal-atol'

import Img1 from 'assets/images/orangedata.svg';
import Img2 from 'assets/images/atol.svg';

const images = {
  "orange-data": Img1,
  "atol": Img2
}

const FZ54PageView = () => {
  const { t } = useTranslation('main');
  const [isOpenOrangeData, setIsOpenOrangeData] = useState(false);
  const [isOpenAtol, setIsOpenAtol] = useState(false);

  const handleModalOrangeData = (e) => {
    e.preventDefault();
    setIsOpenOrangeData(true);
  }

  const handleModalAtol = (e) => {
    e.preventDefault();
    setIsOpenAtol(true);
  }

  return <><div className={cls(classes['fz54-page'])}>
          <WhitePaperWithTopper className={cls(classes['fz54-page__block_top'])}>  
            <div className={cls(classes['fz54-page__block-chip'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.1.page.top.chip') }}/>
            <div className={cls(classes['fz54-page__block-description'], classes['fz54-page__block-description_cut'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.1.page.top.description') }}/>
          </WhitePaperWithTopper>    
          <div className={cls(classes['fz54-page__title'])}>{t('fz54-page.blocks.1.page.title')}</div>
          <div className={cls(classes['fz54-page__container_papers'])}>
            <WhitePaperWithTopper className={cls(classes['fz54-page__block'])} topperColor='orange'>  
              <div className={cls(classes['fz54-page__block-image'])}>
                <img src={images[t('fz54-page.blocks.1.page.blocks.1.image')]} alt=""/>
              </div>
              <div className={cls(classes['fz54-page__block-title_inner'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.1.page.blocks.1.title') }}/>
              <div className={cls(classes['fz54-page__block-description'], classes['fz54-page__block-description_same-height'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.1.page.blocks.1.description') }}/>
              <div className={cls(classes['fz54-page__controls'], classes['fz54-page__controls_full-width'])}>
                <Link className={cls('button-bordered')} onClick={handleModalOrangeData} to={t('fz54-page.blocks.1.page.blocks.1.button.link')}>{t('fz54-page.blocks.1.page.blocks.1.button.name')}</Link>
              </div>
            </WhitePaperWithTopper>
            <WhitePaperWithTopper className={cls(classes['fz54-page__block'])} topperColor='red'> 
              <div className={cls(classes['fz54-page__block-image'])}>
                <img src={images[t('fz54-page.blocks.1.page.blocks.2.image')]} alt=""/>
              </div>             
              <div className={cls(classes['fz54-page__block-title_inner'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.1.page.blocks.2.title') }}/>
              <div className={cls(classes['fz54-page__block-description'], classes['fz54-page__block-description_same-height'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.1.page.blocks.2.description') }}/>
              <div className={cls(classes['fz54-page__controls'], classes['fz54-page__controls_full-width'])}>
                <Link className={cls('button-bordered')} onClick={handleModalAtol} to={t('fz54-page.blocks.1.page.blocks.2.button.link')}>{t('fz54-page.blocks.1.page.blocks.2.button.name')}</Link>
              </div>
            </WhitePaperWithTopper>
          </div>
        </div>    
        <Modal handleClose={() => setIsOpenOrangeData(false)} isOpen={isOpenOrangeData} >
          <ModalOrangeData/>
        </Modal>
        <Modal handleClose={() => setIsOpenAtol(false)} isOpen={isOpenAtol} >
          <ModalAtol/>
        </Modal>
        </>     
}

export default FZ54PageView;
