import { useEffect, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import ReactPortal from "./portal"
import { CloseIcon } from 'components/elements/icons'
import cls from 'classnames'
import "./modal.scss"

const Modal = ({ children, isOpen, handleClose, blocker, className = '' }) => {

	const nodeRef = useRef(null);
	
	useEffect(() => {
		const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
		document.body.addEventListener("keydown", closeOnEscapeKey);
		return () => {
			document.body.removeEventListener("keydown", closeOnEscapeKey);
		};
	}, [handleClose]);

	return (
		<ReactPortal wrapperId="portal">
			<CSSTransition
				in={isOpen}
				timeout={{ entry: 0, exit: 300 }}
				unmountOnExit
				classNames={cls("modal", className)}
				nodeRef={nodeRef}
			>
				<div className={cls("modal")} ref={nodeRef}>
          <div className={cls("modal-content")}>
					<button onClick={handleClose} className={cls("close-btn")}>
						<CloseIcon width="18px"/>
					</button>
					{children}
          </div>
				</div>
			</CSSTransition>
		</ReactPortal>
	);
}

export default Modal
