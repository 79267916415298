import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

import SurveySteps from "components/organizms/main-survey-steps";
import SurveyModification  from "components/organizms/main-survey-modification";
import SurveyConnectionAgreement  from "components/organizms/main-survey-connection-agreement";
import SurveyConnectionApproval  from "components/organizms/main-survey-connection-approval";

const StatusList = ({ customerState, status }) => {

  return (
      <>
        <SurveySteps status={status} customerState={customerState} className={cls(classes['contents-block__margin-null'])}  />
        <SurveyConnectionAgreement status={status} className={cls(classes['contents-block__margin-top'])}  />
        <SurveyModification status={status} className={cls(classes['contents-block__margin-top'])} />
        <SurveyConnectionApproval status={status} className={cls(classes['contents-block__margin-top'])} />
      </>     
  );
}

export default StatusList
