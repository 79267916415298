import React from 'react';
import FormFieldGrid from './form-field-grid';
import { styled } from '@mui/material/styles';
import { styles } from './styles';
import { footnoteTranslate } from './utils';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';

const BetHelperField = ({ ...props }) => {
  const {
    input,
    FormFieldGridProps
  } = useFieldApi(props);

  let footnote = {              
    type: input.value === 'exclusive' ? 'exclusive':'help',
    message: input.value === '-' ? 'forms:turnover-help_empty':'forms:turnover-help',
    threshold: input.value,
    hint: 'forms:turnover-hint'
  }
  
  return (
    <FormFieldGrid {...FormFieldGridProps} style={{paddingTop:0}}>
      {footnote && footnoteTranslate(footnote)}
    </FormFieldGrid>
  );  
}


const PlainText = styled(BetHelperField)(()=>(styles));

export default PlainText;
