import i18next from 'i18next'
import dayjs from 'dayjs';

const customValidatorMapper = {
  'email': () => (value) => {
    return (!value || value === '' || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) ? undefined :  i18next.t('messages:email')
  },
  'phone': () => (value, _, {change}) => {
    value = String(value).replace(/[^0-9]/g, '');

    if(value.length === 11 || value.length === 0) {
      return undefined
    } else {
      return i18next.t('messages:phone')
    }
  },
  'min-length': ({ threshold }) => (value) => value && value.length < threshold ? (`${i18next.t('messages:min')} ${threshold}`) : undefined,
  'max-length': ({ threshold }) => (value) => value && value.length > threshold ? (`${i18next.t('messages:max')} ${threshold}`) : undefined,
  'exact-length': ({ threshold }) => (value) => {
    if (value.length !== threshold) {
      return (`${i18next.t('messages:exact-length')} ${threshold}`)
     } else {
      return undefined
     }
  },
  'same-password': ({ threshold }) => (value, allValues) => ( value !== allValues[threshold] ? i18next.t('messages:same-password') :  undefined ),
  'correctly_written-password': () => (value) => {
    let valid = true;
    if (value) {
      valid = value.length >= 8 // eslint-disable-line no-useless-escape 
        && value.match(/\d{1,}/) != null        // должны быть цифры
        && value.match(/[a-z]{1,}/) != null  // должны быть буквы
        && value.match(/[A-Z]{1,}/) != null;      
    }

    return !valid ? i18next.t('messages:correctly_written-password') :  undefined
  } ,
  'filesize': ({ maxSize }) => (value) => {
    if (value && value.inputFiles && value.inputFiles[0] && value.inputFiles[0].size > maxSize) {
      return i18next.t('messages:file-size', {maxSize: parseInt(maxSize/1024), currentSize: parseInt(value.inputFiles[0].size/1024)});
    } else {
      return undefined
    }
  },
  'fileext': ({ threshold }) => (value) => {
    return (!threshold || !value || !value.inputValue  || threshold.indexOf(((value.inputValue).toLowerCase()).split('.').pop()) !== -1) ? undefined : i18next.t('messages:file-ext') 
  },
  'bik': () => (value) => {
    if(!/^\d{9}$/.test(value)) return i18next.t('messages:bik')
    const thirdPart = value.slice(-3);
    if(+thirdPart === 0 || +thirdPart === 1 || +thirdPart === 2) return undefined
    return (+thirdPart >= 50 && +thirdPart < 1000) ? undefined:i18next.t('messages:bik')
  },
  'bik-check': () => (value) => {
    if(!/^\d{9}$/.test(value)) return false
    const thirdPart = value.slice(-3);
    if(+thirdPart === 0 || +thirdPart === 1 || +thirdPart === 2) return true
    return +thirdPart >= 50 && +thirdPart < 1000
  },
  'payment-account': ({ bikFieldName }) => (value, allValues) => {
    const valueToString = value ? value.toString() : '';
    if (customValidatorMapper['bik-check']()(allValues[bikFieldName])) {
      if (!/[^0-9]/.test(valueToString) && valueToString.length === 20) {
        const bikRs = (allValues[bikFieldName]).toString().slice(-3) + valueToString;
        let checkSum = 0;
        const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
        for (var i in coefficients) {
          checkSum += coefficients[i] * (Number(bikRs[i]) % 10);
        }
        return checkSum % 10 === 0 ? undefined:i18next.t('messages:payment-account');
      }
    }
    return i18next.t('messages:payment-account');
  },
  'correspondent-account': ({ bikFieldName }) => (value, allValues) => {
    const valueToString = value ? value.toString() : ''
    if(customValidatorMapper['bik-check']()(allValues[bikFieldName])){
      if(!/[^0-9]/.test(valueToString) && valueToString.length === 20){
        const bikKs = '0' + (allValues[bikFieldName]).slice(4, 6) + valueToString;
        let checkSum = 0
        const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1]
        for (var i in coefficients) {
          checkSum += coefficients[i] * (Number(bikKs[i]) % 10);
        }
        return (checkSum % 10 === 0) ? undefined:i18next.t('messages:correspondent-account')
      }
    }
    return i18next.t('messages:correspondent-account')
  },
  "text-only": () => (value) => /^[а-яёА-ЯЁA-Za-z-\s]*$/.test(value) ? undefined : i18next.t('messages:only-text'),
  "english-only": () => (value) => /^[A-Za-z]*$/.test(value) ? undefined : i18next.t('messages:english-only'),
  "passport": () => (value) => {
    let passport =  value.toString().replace(/[^0-9]/g,'');
    return passport.length === 10 ? undefined:i18next.t('messages:incorrect-passport');
  },
  "division-code": () => (value) => {      
    var result = undefined;
    var snils = value; 
    if (typeof snils === 'number') {
      snils = snils.toString();
    } else if (typeof snils !== 'string' || typeof snils === 'undefined') {
      snils = '';
    }
  
    snils = snils.replace(/[^0-9]/g,'');

    if (!snils.length) {
      return undefined;
    } else if (/[^0-9]/.test(snils)) {
      return i18next.t('messages:incorrect-division-code');
    } else if (snils.length !== 6) {
      return i18next.t('messages:incorrect-division-code');
    }
    return result;
    //i18next.t("messages:incorrect-snils");
  },
  "address-index": () => (value) => {      
    var result = undefined;
    var snils = value; 
    if (typeof snils === 'number') {
      snils = snils.toString();
    } else if (typeof snils !== 'string' || typeof snils === 'undefined') {
      snils = '';
    }
  
    snils = snils.replace(/[^0-9]/g,'');

    if (!snils.length) {
      return undefined;
    } else if (/[^0-9]/.test(snils)) {
      return i18next.t('messages:incorrect-address-index');
    } else if (snils.length !== 6) {
      return i18next.t('messages:incorrect-address-index');
    }
    return result;
  },
  "snils": () => (value) => {      
      var result = false;
      var snils = value; 
      if (typeof snils === 'number') {
        snils = snils.toString();
      } else if (typeof snils !== 'string' || typeof snils === 'undefined') {
        snils = '';
      }
    
      snils = snils.replace(/[^0-9]/g,'');

      if (!snils.length) {
        return undefined;
      } else if (/[^0-9]/.test(snils)) {
        return i18next.t('messages:incorrect-snils');
      } else if (snils.length !== 11) {
        return i18next.t('messages:incorrect-snils');
      } else {
        var sum = 0;
        for (var i = 0; i < 9; i++) {
          sum += parseInt(snils[i]) * (9 - i);
        }
        var checkDigit = 0;
        if (sum < 100) {
          checkDigit = sum;
        } else if (sum > 101) {
          checkDigit = parseInt(sum % 101);
          if (checkDigit === 100) {
            checkDigit = 0;
          }
        }
        if (checkDigit === parseInt(snils.slice(-2))) {
          result = undefined;
        } else {
          return i18next.t('messages:incorrect-snils');
        }
      }
      return result;
      //i18next.t("messages:incorrect-snils");
    },

  "okpo": () => (value) => {
    if(!value) return i18next.t("messages:incorrect-okpo");
    
    if (value.length < 8 || value.length > 10) return i18next.t("messages:incorrect-okpo");

    const expectedValue = Number(value.slice(-1))

    const getWeight = (index) => {
      if(index < 10) return index + 1
      else return index % 10 + 1 
    }
    
    const testingValue = value.slice(0, -1);
    
    let summ = 0;
    
    for(const i in testingValue.split('')){
      summ += Number(testingValue[i]) * getWeight(Number(i))
    }
    
    let del11 = summ % 11;

    if(del11 === 10){
      summ = 0
      for(const i in testingValue.split('')){
        summ += Number(testingValue[i]) * ( getWeight(Number(i) + 2))
      }
      del11 = (summ % 11 === 10) ? 0 : (summ % 11);
    }
    return (del11 === expectedValue) ? undefined:i18next.t("messages:incorrect-okpo")
  },

  "ogrn": () => (ogrn) => {
    var result = false;
    if (typeof ogrn === 'number') {
      ogrn = ogrn.toString();
    } else if (typeof ogrn !== 'string') {
      ogrn = '';
    }
    if (!ogrn.length) {
      return i18next.t("messages:incorrect-ogrn");//'ОГРН пуст';
    } else if (/[^0-9]/.test(ogrn)) {
      return i18next.t("messages:incorrect-ogrn");//'ОГРН может состоять только из цифр';
    } else if (ogrn.length !== 13) {
      return i18next.t("messages:incorrect-ogrn");//'ОГРН может состоять только из 13 цифр';
    } else {
      var n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
      if (n13 === parseInt(ogrn[12])) {
        result = true;
      } else {
        return i18next.t("messages:incorrect-ogrn");//'Неправильное контрольное число';
      }
    }
    return result ? undefined:i18next.t("messages:incorrect-ogrn");
  },
  "ogrnip": () => (value) => {  
    const valueToString = value ? value.toString() : ''
    if (valueToString.length === 15) {
        const num14 = Math.floor((value / 10) % 13);
        var dgt15 = num14 % 10;
        return parseInt(value.toString()[14]) === dgt15 ? undefined:i18next.t("messages:incorrect-ogrnip")
    }
    return i18next.t("messages:incorrect-ogrnip")
  },
  // eslint-disable-next-line
  "digit-dot": () => (value) => /^[0-9\.]*$/.test(value) ? undefined : i18next.t("messages:digit-dot"),
  "digit": () => (value) => /^[1-9][0-9]*$/.test(value) ? undefined : i18next.t("messages:digit"),
  "digitAndZero": () => (value) => /^[0-9][0-9]*$/.test(value) ? undefined : i18next.t("messages:digit"),
  "float": () => (value) => /^\d{1,2}\.\d{1,2}$|^[1-9]{1}\d?$|^100$/.test(value) ? undefined : i18next.t("messages:incorrect"),
  // eslint-disable-next-line
  "url": () => (value) => {
    if (!/^(http(s)?:\/\/)/.test(value)) return i18next.t("messages:incorrect-url-protocol")
    // eslint-disable-next-line
    return (/(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}(\.[a-z]{2,6}|:[0-9]{3,4})\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/.test(value) ? undefined :  i18next.t("messages:incorrect-url"))
  },
  "inn": () => (value) => {
      const valueToString = value ? value.toString() : ''
      if (valueToString === '') return undefined;
      const getN = (index) => (parseInt(valueToString[index]))
      if(valueToString.length === 12){
        const dgt11 = (( 
          7 * getN(0) + 2 * getN(1) + 4 * getN(2) + 
          10 * getN(3) + 3 * getN(4) + 5 * getN(5) +
          9 * getN(6) + 4 * getN(7) + 6 * getN(8) +
          8 * getN(9)) % 11) % 10
        
        const dgt12 = ((
          3 * getN(0) + 7 * getN(1) + 2 * getN(2) +
          4 * getN(3) + 10 * getN(4) + 3 * getN(5) +
          5 * getN(6) + 9 * getN(7) + 4 * getN(8) +
          6 * getN(9) + 8 * getN(10)) % 11) % 10
        
        return (getN(10) === dgt11 && getN(11) === dgt12) ? undefined : i18next.t('messages:inn');
      } else {
        if(valueToString.length === 10){
          const dgt10 = ((
            2 * getN(0) + 4 * getN(1) + 10 * getN(2) +
            3 * getN(3) + 5 * getN(4) + 9 * getN(5) +
            4 * getN(6) + 6 * getN(7) + 8 * getN(8)
          ) % 11) % 10
          return (getN(9) === dgt10) ? undefined : i18next.t('messages:inn');
        }
      }
      return i18next.t('messages:inn')
    },
  "date": ({min}) => (value) => {
    const minDate = dayjs(min||'1900-01-01T00:00:00');
    const preparedValue = dayjs(value);
    return (preparedValue > minDate || !value) ? undefined : i18next.t('messages:date')
  }, 
  "currentDate": () => (value) => {
    let currentDay = new Date();
    return (new Date(value) < currentDay || !value) ?  undefined : i18next.t('messages:date')
  },
  "kpp": () => (value) => {
    const valueToString = value ? value.toString() : ''
    if(valueToString.length !== 9) return i18next.t('messages:kpp')
    if(!valueToString.match(/\d{4}[\dA-Z][\dA-Z]\d{3}/)) return i18next.t('messages:kpp')
    return undefined
  },
  "filepresent": ({ threshold }) => (value, allValues) => {
    return value || allValues[threshold] ? undefined:i18next.t('messages:required')
  },
  "not-zero": () => (value) => {
    return parseInt(value) > 0 ? undefined:i18next.t('messages:required')
  },
  "passport-issue-by-whom": () => (value) => {  
    const valueToString = value ? value.toString() : ''
    if(!valueToString.match(/^[а-яА-Я0-9/.,‘’"“”«»„“()№;'\-\s]+$/)) return i18next.t('messages:passport-issue-by-whom')
    return undefined
  },
  "address-string":  () => (value) => {
    const valueToString = value ? value.toString() : ''
    if(!valueToString.match(/^[a-zA-Zа-яА-ЯёЁ0-9.,()/-\s]+$/) && valueToString !== '') return i18next.t('messages:address-string')
    return undefined
  },
  "more-than-one-spaces":  () => (value) => {
    const valueToString = value ? value.toString() : ''
    if(valueToString.match(/\s{2,}/)) return i18next.t('messages:more-than-one-spaces')
    return undefined
  },
  "okved":  () => (value) => {
    return /(^([\d]{2})$)|(^([\d]{2})+?(\.[\d]{2})?(\.[\d]{1,2})$)/.test(value) ? undefined : i18next.t("messages:incorrect-okved")
  },
  "brand-eng": () => (value) => /^[a-zA-Z0-9]+$/.test(value) ? undefined : i18next.t('messages:brand-eng'),
  "value-from-dadata": ({ name }) => (value, allValues, obj) => {
    
    let helperValue = allValues[name+'Fias'];
    if(allValues.hasOwnProperty('BeneficiariesInfo')) {
      
      let fieldIndex = String(obj.name).match(/\[(.*?)\]/);
      if (fieldIndex && fieldIndex[1]) {
        helperValue = allValues['BeneficiariesInfo'][fieldIndex[1]][`${name}Fias`];
      }
    }
    return (!helperValue && value && value !== '') ? i18next.t('messages:value-from-dadata'):undefined;
  }
}

export default customValidatorMapper