import React from "react";
import cls from "classnames"
import classes from "./logo.module.scss"
import { Logo } from "components/elements/icons"

const LogoElement = ({ className }) => {
  return (
    <div className={cls(classes['logo'], classes[className])} title="Payture">
      <Logo height='16px'/>
    </div>
  );
}

export default LogoElement
