import React from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import WhitePaperWithTopper from 'components/elements/white-paper-with-topper';
import classes from './fz54.module.scss';

import { Link } from "react-router-dom";

const FZ54PageView = () => {
  const { t } = useTranslation('main');

  return <div className={cls(classes['fz54-page'])}>
          <div className={cls(classes['fz54-page__title'])}>{t('fz54-page.title')}</div>
          <div className={cls(classes['fz54-page__container_papers'])}>
            <WhitePaperWithTopper className={cls(classes['fz54-page__block'])} topperColor='violet'>  
              <div className={cls(classes['fz54-page__block-title'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.1.title') }}/>
              <div className={cls(classes['fz54-page__block-description'], classes['fz54-page__block-description_same-height'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.1.description') }}/>
              <div className={cls(classes['fz54-page__controls'])}>
                <Link className={cls('button-bordered')} to={t('fz54-page.blocks.1.button.link')}>{t('fz54-page.blocks.1.button.name')}</Link>
              </div>
            </WhitePaperWithTopper>
            <WhitePaperWithTopper className={cls(classes['fz54-page__block'])} topperColor='violet'>  
              <div className={cls(classes['fz54-page__block-title'])}>{t('fz54-page.blocks.2.title')}</div>
              <div className={cls(classes['fz54-page__block-description'], classes['fz54-page__block-description_same-height'])}>{t('fz54-page.blocks.2.description')}</div>
              <div className={cls(classes['fz54-page__controls'])}>
                <Link className={cls('button-bordered')} to={t('fz54-page.blocks.2.button.link')}>{t('fz54-page.blocks.2.button.name')}</Link>
              </div>              
            </WhitePaperWithTopper>
            <WhitePaperWithTopper className={cls(classes['fz54-page__block'])} topperColor='violet'>  
              <div className={cls(classes['fz54-page__block-title'])}>{t('fz54-page.blocks.3.title')}</div>
              <div className={cls(classes['fz54-page__controls'])}>
                <Link className={cls('button-bordered')} to={t('fz54-page.blocks.3.button.link')}>{t('fz54-page.blocks.3.button.name')}</Link>
              </div>            
            </WhitePaperWithTopper>
          </div>
        </div>         
}

export default FZ54PageView;
