import React from "react";
import cls from 'classnames';
import { styled } from '@mui/material/styles';
import { QuestionMark } from 'components/elements/icons';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import classes from './styles.module.scss';
import Zoom from '@mui/material/Zoom';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#8B74D8',
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(14),
    borderRadius: '8px',
    padding: '12px 16px',
    lineHeight: theme.typography.pxToRem(18),
    maxWidth: 350
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#8B74D8'
  }
}));

const Question = ({ text, className = '' }) => {
  return (
    <HtmlTooltip 
      title={<div dangerouslySetInnerHTML={{ __html: text}}/>} 
      TransitionComponent={Zoom}
      arrow
      enterTouchDelay={10}
    >
      <span className={cls(classes['help__question-icon_container'], className)}><QuestionMark className={classes['help__question-icon']} width="13.33px" heught="13.33px" /></span>
    </HtmlTooltip>
  )
}

export default Question
