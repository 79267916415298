import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ArrowDownIcon, QuestionMark } from 'components/elements/icons';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import classes from './development.module.scss';
import WhitePage from 'components/elements/white-paper';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  '::before': {
    backgroundColor: 'transparent'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownIcon height='6px' width='12px' />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

const DevelopmentPageView = () => {

  const { t } = useTranslation('main');

  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
  return (
    <>
    {
      <>
        {
          t('faq-page.items', { returnObjects: true }).map((i, index)=>{
            return (
              <WhitePage className={cls(classes['faq-page__part'])} key={`wp-${index+1}`}>
                <Accordion expanded={expanded === `panel${index+1}`} onChange={handleChange(`panel${index+1}`)}>
                  <AccordionSummary aria-controls={`panel${index+1}d-content`} id={`panel${index+1}d-header`}>
                  <div className={cls(classes['faq-page__title'])}><QuestionMark height="20px"/><span dangerouslySetInnerHTML={{__html: t(i['title'])}} /></div>
                  </AccordionSummary>
                  <AccordionDetails className={cls(classes['faq-page__hint-container'])}>
                    <span className={cls(classes['faq-page__hint'])} dangerouslySetInnerHTML={{__html: t(i['text'])}} />
                  </AccordionDetails>
                </Accordion>
              </WhitePage>            
            )
          })
        }
      </>
    }
    </>
  );
}

export default DevelopmentPageView;
