import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import FormFieldGrid from './form-field-grid';
import { styled } from '@mui/material/styles';
import { styles } from './styles';
import { footnoteTranslate } from './utils';

const CommonPlainText = ({ label, name, element, FormFieldGridProps, footnote, ...props }) => {
  return typeof label === 'string' ? (
    label.split('\n').map((paragraph, index) => (
      <FormFieldGrid {...FormFieldGridProps} key={`tp-${index}`}>
        <Typ text={paragraph} element={element} variant={element} />
        {footnote && footnoteTranslate(footnote)}
      </FormFieldGrid>
    ))
  ) : (
    <FormFieldGrid {...FormFieldGridProps}>
      <Typ text={label} element={element} variant={element} />
      {footnote && footnoteTranslate(footnote)}
    </FormFieldGrid>
  );  
}

const Typ = ({text, element, variant}) =>
  <CustomTypography variant={variant} {...(element && { component: element })}>
    {text}
  </CustomTypography>

CommonPlainText.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  component: PropTypes.any,
  element: PropTypes.elementType,
};

CommonPlainText.defaultProps = {
  variant: 'body1',
  gutterBottom: true,
}; 


const CustomTypography = styled(Typography)(()=>(styles));

export default CommonPlainText;
