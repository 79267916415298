import React, { useState } from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { MenuHomeIcon, MenuSurveyIcon, 
  MenuDevelopmentIcon, MenuProductionIcon, 
  MenuFeedbackIcon, MenuFZ54Icon, QuestionMark } from 'components/elements/icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import cls from 'classnames';
import classes from './styles.module.scss';

const MenuListCustomized = styled(MenuList)(() => ({
  //gap: '16px',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  ' > * + *': { 
    marginTop: '16px'
  }
}));

const MenuItemCustomized = styled(MenuItem)(() => ({
  '& .MuiTypography-root': {
    fontFamily: 'Inter',
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '18px'
  },
  
  borderRadius: '8px',
  padding: '16px 51px 16px 16px',
  '&.Mui-selected': {
    background: 'rgba(255, 255, 255, 0.8)',
    boxShadow: '0px 4px 25px rgba(92, 151, 255, 0.1)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.8)',
      boxShadow: '0px 4px 25px rgba(92, 151, 255, 0.1)'
    }    
  },
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.8)',
    boxShadow: '0px 4px 25px rgba(92, 151, 255, 0.1)'
  }

}));

export default function IconMenu({customerState}) {
  const { t } = useTranslation();
  const location = useLocation();
  const regex = new RegExp('/survey*', 'g');

  const [ hasNewComments ] = useState(false)

  return (
      <MenuListCustomized className={cls(classes['menu'])}>
        <MenuItemCustomized component={Link} to='/' selected={location.pathname === '/'}>
          <MenuHomeIcon height='16' className={cls(classes['menu__icon'])}/>
          <ListItemText>{t('main:menu-main')}</ListItemText>
        </MenuItemCustomized>
        <MenuItemCustomized component={Link} to='/survey' selected={regex.test(location.pathname)}>
          <MenuSurveyIcon height='16' className={cls(classes['menu__icon'])}/>
          <ListItemText>{t('main:menu-survey')}</ListItemText>
          { hasNewComments && <div className={cls(classes['menu__new-message-indicator'])} />  }
        </MenuItemCustomized>
        <MenuItemCustomized disabled={!customerState.HasSandboxTerminals} component={Link} to='/development' selected={location.pathname.startsWith('/development')}>
          <MenuDevelopmentIcon height='16' className={cls(classes['menu__icon'])}/>
          <ListItemText>{t('main:menu-test')}</ListItemText>
        </MenuItemCustomized>
        <MenuItemCustomized disabled={!customerState.HasProductionTerminals} component={Link} to='/production' selected={location.pathname.startsWith('/production')}>
          <MenuProductionIcon height='16' className={cls(classes['menu__icon'])}/>
          <ListItemText>{t('main:menu-prod')}</ListItemText>
        </MenuItemCustomized>
        <MenuItemCustomized component={Link} to='/feedback' selected={location.pathname.startsWith('/feedback')}>
          <MenuFeedbackIcon height='16' className={cls(classes['menu__icon'])}/>
          <ListItemText className={cls(customerState.HasNewComments && classes['fresh'])}>{t('main:menu-feedback')}</ListItemText>
          { hasNewComments && <div className={cls(classes['menu__new-message-indicator'])} />  }
        </MenuItemCustomized>
        <MenuItemCustomized component={Link} to='/fz54' selected={location.pathname.startsWith('/fz54')}>
          <MenuFZ54Icon height='16' className={cls(classes['menu__icon'])}/>
          <ListItemText><span dangerouslySetInnerHTML={{ __html: t('main:menu-fz54')}}/></ListItemText>
        </MenuItemCustomized>
        <MenuItemCustomized component={Link} to='/faq' selected={location.pathname.startsWith('/faq')}>
          <QuestionMark height='16' color="#000000" className={cls(classes['menu__icon'])}/>
          <ListItemText><span dangerouslySetInnerHTML={{ __html: t('main:menu-faq')}}/></ListItemText>
        </MenuItemCustomized>
      </MenuListCustomized>
  );
}
