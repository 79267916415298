export const isMultiLined = (field) => {

  return  field === 'PassportIssuedByWhom' || 
          field === 'RegistrationAddress'  ||
          field === 'LegalAddress'  || 
          field === 'ActualAddress'  ||
          field === 'PlaceOfRegistration' || 
          field === 'WhomGiveLicense'  ||
          field === 'PlaceGiveLicense'  ||
          field === 'ListTypesLicense'  ||
          field === 'RegionLicense'  ||
          field === 'ListTypesLicense'  ||
          field === 'NameLawEnforcement'  ||
          field === 'FioPdl'  ||
          field === 'DocumentSpecialOfficial'  ||
          field === 'ValueEnableControl'  ||
          field === 'Address'  ||
          field === 'NameRelatives'  || 
          field === 'PassportIssuedByWhomAttorney'  ||
          field === 'RegistrationPlaceAttorney'   ||
          field === 'TextAttorney'   ||
          field === 'NameLawEnforcementAttorney'  || 
          field === 'FioPdlAttorney'  ||
          field === 'BankName'   ||
          field === 'ControlOrganizationInfo'  ||  
          field === 'PassportIssuedByWhomExecutive'  || 
          field === 'RegistrationPlaceExecutive'   ||
          field === 'NameLawEnforcementExecutive'   ||
          field === 'NumberExecutive' ||
          field === 'RegistrationNumberExecutive' ||
          field === 'InfoAboutLegalExecutive' ||
          field === 'ProtocolExecutive' ||
          field === 'ActualTypeOfActivity' ||
          field === 'PlaceOfBirth' ||
          field === 'PlaceOfBirthAttorney' ||
          field === 'PlaceOfBirthExecutive' ||
          /BeneficiariesInfo\[.*\]\.PlaceOfBirth/.test(field) ||
          /BeneficiariesInfo\[.*\]\.NameRelatives/.test(field) ||
          /BeneficiariesInfo\[.*\]\.FioPdl/.test(field)
}