import React, { lazy } from 'react'
import { ScrollRestoration, Outlet  } from "react-router-dom";
import { createBrowserRouter, createRoutesFromElements, Route, useRouteError } from 'react-router-dom'
import PrivatePage from 'components/layouts/private-page';
import EmptyPage from 'components/layouts/empty-page';

import MainPage from 'pages/main';
import SurveyPage from 'pages/survey';
import DevelopmentPage from 'pages/development';
import ProductionPage from 'pages/production';
import FeedbackPage from 'pages/feedback';
import FZ54Page from 'pages/fz54';
import FZ54ConnectPage from 'pages/fz54/connect';
import FZ54CustomizePage from 'pages/fz54/customize';
import FZ54DetailedPage from 'pages/fz54/detailed';
import ProfilePage from 'pages/profile';
import FAQPage from 'pages/faq';

import LoginPage from 'pages/login';

const NotFoundPage = lazy(() => import('pages/404'));
const ErrorPage = lazy(() => import('pages/error'));
/* const LoginPage = lazy(() => import('pages/login')); */
const LogoutPage = lazy(() => import('pages/logout'));
const RegistrationPage = lazy(() => import('pages/registration'));
const PasswordRecoveryPage = lazy(() => import('pages/password-recovery'));
const PasswordUpdatePage = lazy(() => import('pages/password-update'));
const EmailConfirmationPage = lazy(() => import('pages/email-confirmation'));



/* const MainPage = lazy(() => import('pages/main'));
const SurveyPage = lazy(() => import('pages/survey'));
const DevelopmentPage = lazy(() => import('pages/development'));
const ProductionPage = lazy(() => import('pages/production'));
const FeedbackPage = lazy(() => import('pages/feedback'));
const FZ54Page = lazy(() => import('pages/fz54'));
const ProfilePage = lazy(() => import('pages/profile')); */

function RootRouteComponent() {
  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootRouteComponent />}>
      <Route 
        errorElement={<ErrorBoundary/>}

        >
        
        <Route path='logout' element={<LogoutPage />}/>
        <Route element={<EmptyPage/>}>
          <Route path='login' element={<LoginPage />}/>
          <Route path='registration' element={<RegistrationPage />}/>
          <Route path='password-recovery' element={<PasswordRecoveryPage />}/>
          <Route path='password-update/:type/:token' element={<PasswordUpdatePage />}/>
          <Route path='confirmation/:type' element={<EmailConfirmationPage />}/>
        </Route>  
        <Route element={<PrivatePage />}>
          <Route index element={<MainPage/>} />
          <Route path='survey' element={<SurveyPage/>}>
            <Route path=':step' element={<SurveyPage/>} />
          </Route>
          <Route path='development' element={<DevelopmentPage/>} />
          <Route path='production' element={<ProductionPage/>} />
          <Route path='feedback' element={<FeedbackPage/>} />
          <Route path='fz54' element={<FZ54Page/>} />
          <Route path='fz54/connect' element={<FZ54ConnectPage/>} />
          <Route path='fz54/customize' element={<FZ54CustomizePage/>} />
          <Route path='fz54/detailed' element={<FZ54DetailedPage/>} />
          <Route path='faq' element={<FAQPage/>} />
          <Route path='profile' element={<ProfilePage/>} />

          <Route
            path="*"
            index
            element={<NotFoundPage/>}
          />
        </Route>
      </Route>
    </Route>
  )
)

function ErrorBoundary() {
  let error = useRouteError();
  
  return <ErrorPage error={error} />;
}

