import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ArrowDownIcon } from 'components/elements/icons';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import classes from './development.module.scss';
import WhitePage from 'components/elements/white-paper';
import GrayPage from 'components/elements/gray-paper';
import Loader from 'components/elements/loader-vintage';
import Question from 'components/organizms/question-mark';
import BankTabs from "components/organizms/bank-tabs";
import TabTitle from "components/organizms/bank-tabs/title";
import AccordionContainer from "components/elements/accordion-container";
import Divider from '@mui/material/Divider';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  '::before': {
    backgroundColor: 'transparent'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownIcon height='6px' width='12px' />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

const ProductionPageView = ({ data, terminals, loading, customerState, onBankSelect, bankId, bankIndex, bankList }) => {

  const { t } = useTranslation('main');

  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
  return (
    <>
    <div className={cls('desktop')}>
    <BankTabs bankInfo={customerState.BankInfo} onClick={onBankSelect} bankId={bankId} bankList={bankList} />
    {
      data && 
      <>
        {
          t('production-page.items', { returnObjects: true }).map((i, index)=>{
            return (
              <WhitePage className={cls(classes['development-page__part'], (bankIndex === 0 && index === 0) ? classes['tabs__first']:'')} key={`wp-${index+1}`}>
              {
                loading && <Loader/>
              }            
              {
                !loading && <>
                <Accordion expanded={expanded === `panel${index+1}`} onChange={handleChange(`panel${index+1}`)}>
                  <AccordionSummary aria-controls={`panel${index+1}d-content`} id={`panel${index+1}d-header`} className={cls(classes['development-page__title'])}>
                    {t(i['title'])}
                  </AccordionSummary>
                  <AccordionDetails>
                    {
                      i['text'].map((k, ind)=>{
                        let obj = Object.entries(k)
                        if ( typeof k !== 'string' && !k.hasOwnProperty('content') && !(data['MerchantContractsInfo'] && 
                        data['MerchantContractsInfo'][0] && 
                        data['MerchantContractsInfo'][0][obj[0][0]])) {
                          return <></>
                        }  
                        return <GrayPage key={`gp-${ind+1}`} className={cls(classes['development-page__part'], classes['development-page__text'])}>
                          {
                            typeof k === 'string' && <div dangerouslySetInnerHTML={{ __html: k}} />
                          }
                          {
                            (typeof k === 'object' && !k.hasOwnProperty('content')) &&  <div>{obj[0][1]} {
                              data['MerchantContractsInfo'] && 
                              data['MerchantContractsInfo'][0] &&
                              data['MerchantContractsInfo'][0][obj[0][0]] ? 
                              String(data['MerchantContractsInfo'][0][obj[0][0]]):'xxx'}</div>
                          }
                          {
                            (typeof k === 'object' && k.hasOwnProperty('content')) &&  
                              <div className={cls(classes['with-question'])}>
                                <span dangerouslySetInnerHTML={{ __html: k.content}} />
                                {
                                  k.tooltip && <Question className={classes['question-appearance']} text={k.tooltip} />
                                }
                              </div>
                          }
                        </GrayPage>
                      })
                    }
                  </AccordionDetails>
                </Accordion>
                </>      
              }    

              </WhitePage>            
            )
          })
        }
        <div className={cls(classes['development-page__hint'])} dangerouslySetInnerHTML={{ __html: t('production-page.hint')}} />
        <div className={cls(classes['development-page__button-container'])}>
          <a className={cls(classes['development-page__button'])} target="_blank" rel="noreferrer" href={t('production-page.button.link')}>{t('production-page.button.name')}</a>
        </div>
      </>
    }
    </div>
    <div className={cls('mobile')}>
    {
      terminals && 
      <>
      {
        customerState.BankInfo.map((item, index)=>{
          return <AccordionContainer onOpen={()=>onBankSelect(item.BankId, index)} key={`bank-accordion-${index}`} index={index} title={<TabTitle item={item} disabled={!bankList[item.BankId]}/>} className={cls(classes['development-page__accordion-container'])}>
            <>
            {
              bankList[item.BankId] && <><Divider sx={{margin: '16px 0 0'}}/>
              {
                t('production-page.items', { returnObjects: true }).map((i, index)=>{
                  return (
                    <div key={`i-${index}`}>
                    {
                      loading && <Loader/>
                    }            
                    {
                      !loading && <>
                          
                          <div className={cls(classes['development-page__mobile-version-title'])}>{t(i['title'])}</div>
                          
                          {
                            i['text'].map((k, ind)=>{
                              let obj = Object.entries(k)
                              return <GrayPage key={`gp-${ind+1}`} className={cls(classes['development-page__part'], classes['development-page__text'])}>
                                {
                                  typeof k === 'string' && <div dangerouslySetInnerHTML={{ __html: k}} />
                                }
                                {
                                  (typeof k === 'object' && !k.hasOwnProperty('content')) &&  <div>{obj[0][1]} {
                                    terminals[item.BankId]['MerchantContractsInfo'] && 
                                    terminals[item.BankId]['MerchantContractsInfo'][0] &&
                                    terminals[item.BankId]['MerchantContractsInfo'][0][obj[0][0]] ? 
                                    String(terminals[item.BankId]['MerchantContractsInfo'][0][obj[0][0]]):'xxx'}</div>
                                }
                                {
                                  (typeof k === 'object' && k.hasOwnProperty('content')) &&  
                                    <div className={cls(classes['with-question'])}>
                                      <span dangerouslySetInnerHTML={{ __html: k.content}} />
                                      {
                                        k.tooltip && <Question className={classes['question-appearance']} text={k.tooltip} />
                                      }
                                    </div>
                                }
                              </GrayPage>
                            })
                          }

                      </>      
                    }    

                    </div>            
                  )
                })
              }
              </>
            }

            </>            
          </AccordionContainer>
        })
      }

        <div className={cls(classes['development-page__hint'])} dangerouslySetInnerHTML={{ __html: t('production-page.hint')}} />
        <div className={cls(classes['development-page__button-container'])}>
          <a className={cls(classes['development-page__button'])} target="_blank" rel="noreferrer" href={t('production-page.button.link')}>{t('production-page.button.name')}</a>
        </div>
        </>
    }
    </div>
    </>
  );
}

export default ProductionPageView;
