import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

const Element = ({ children, className, topperColor=false }) => {
  return (
    <div className={cls(classes['white-paper'], className, topperColor && classes[`white-paper__topper-color_${topperColor}`])}>
      {children}
    </div>
  );
}

export default Element