import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

const Element = ({ children, className, fresh=false }) => {
  return (
    <div className={cls(classes['white-paper'], className, fresh && classes['white-paper__fresh'])}>
      {children}
    </div>
  );
}

export default Element