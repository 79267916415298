import React from 'react'
import cls from 'classnames'
import { LoadingIcon } from '../icons'
import localStyles from './loader-vintage.module.scss'

const Loader = ({ enabled, className, over = false }) => {
  return (
    <div className={cls(localStyles.container, over && localStyles.over)}>
      <LoadingIcon width="48" height="48" className="loading" />
    </div>
  )
}

export default Loader
