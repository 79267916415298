import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import WhitePaper from 'components/elements/white-paper'
import { useTranslation } from 'react-i18next';
/* import { Bullet } from "components/elements/icons";  */

const WelcomeBlock = ({ customerState, status}) => {
  const { t } = useTranslation();
  
  return (
    <WhitePaper className={classes['welcome-block']}>
      <h2 className={cls(classes['welcome-block__title'])}>{t('main:welcome-block-title', {name: customerState?.CustomerName||'Безимянный'})}</h2>
      <div className={cls(classes['welcome-block__hint'])}>{t('main:welcome-block-hint')}</div>
      {/* <div className={cls(classes['welcome-block__list'])}>
        <div className={cls(classes['welcome-block__list-item'], classes['welcome-block__list-item_active'])}>
          <Bullet height="8"/>
          {
            t('main:welcome-block-item1')
          }
        </div>
        <div className={cls(classes['welcome-block__list-item'], status > 1 && classes['welcome-block__list-item_active'])}>
          <Bullet height="8"/>
          {
            t('main:welcome-block-item2')
          }
        </div>
        <div className={cls(classes['welcome-block__list-item'], (status === 3 || status === 7) && classes['welcome-block__list-item_active'])}>
          <Bullet height="8"/>
          {
            t('main:welcome-block-item3')
          }
        </div>
        <div className={cls(classes['welcome-block__list-item'], status === 7 && classes['welcome-block__list-item_active'])}>
          <Bullet height="8"/>
          {
            t('main:welcome-block-item4')
          }
        </div>
      </div> */}
    </WhitePaper>
  );
}

export default WelcomeBlock
