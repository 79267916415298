import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query'
import { useGetCustomerState } from 'queries/authorization'  

export const useAuthorization = () => {
  const queryClient = useQueryClient();
  let authorized = queryClient.getQueryData(["Authorized"]);
  const [ isAuthorized, setAuthorized ] = useState(authorized)
  const { mutate: auth } = useGetCustomerState()

  useEffect(() => {
    if(typeof isAuthorized === 'undefined') {
      auth(null, {
        onSuccess: (data)=>{
          setAuthorized(data.Success)
        },
        onError: (data)=>{
          setAuthorized(false)
        }
      })        
    }
     
  }, [isAuthorized, auth]); 

  return isAuthorized
}
