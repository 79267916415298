import React from "react";
import cls from 'classnames';
import classes from './auth.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
/* import AuthTitle from 'components/elements/auth-title'; */
import AuthFootnote from 'components/elements/auth-footnote';
import FormLogin from 'ddf/forms/auth/login'; 

const LoginView = (props) => {
  const { t } = useTranslation();
  const link = <> <Link to={'/registration'}>{t('auth:registration-link')}</Link></>

  return    <>
              {/* <AuthTitle text={t('auth:login-title')}/> */}
              <div className={cls('login-form', classes['auth-form'])}>
                <FormLogin {...props} />
              </div>
              <AuthFootnote text={t('auth:registration-advice')} link={link} textAlign='center' /> 
            </>
          
}

export default LoginView;