import axios from 'axios';

export const getBaseURLApi = () => process.env.NODE_ENV === 'production' ? '/CustomerApi' : 'https://dev.payture.com:9189/CustomerApi';

const createClient = (baseUrl) => {
  const cli = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    withCredentials: true
  })

  cli.__base = baseUrl 
  return cli
}
 
const client = createClient(getBaseURLApi()) 

/* const _axiosGetApi = client.get;

client.get = function(url, data = null) {
  const configuration = data ? {
    params: new URLSearchParams(data).toString()
  } : null

  return _axiosGetApi(url, configuration)
} */

client.interceptors.response.use(function(response) {
  return response.data;
}, function (error) {
  return Promise.reject(error);
});

/* const axiosGetFn = client.get;

client.get = function (url, data) {
  const configuration = data
    ? {
        params: data,
      }
    : null;

  return axiosGetFn(url, configuration);
}; */

client.upload = async function (url, file, additionalParams = {}) {
  var formData = new FormData();

  Object.keys(additionalParams).forEach((key) => {
    formData.append(key, additionalParams[key]);
  });

  if (file !== null) {
    formData.append("file", file);
  }

  return await client.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default client

