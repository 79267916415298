import React, { useState, useEffect } from "react";
import * as Queries from 'queries/authorization';
import ProductionPageVew from 'components/views/main/production';
import { useOutletContext } from "react-router-dom";
import { auth } from "queries/requests";

const ProductionPage = () => {
  const [ customerState ] = useOutletContext();
  const [ bankId, setBankId ] = useState(null); 
  const [ bankIndex, setBankIndex ] = useState(null); 
  const [ terminals, setTerminals ] = useState(); 
  const [ bankList, setBankList ] = useState([]);
  const { isLoading } = Queries.useGetPaytureTerminals();

  const handleBankSelect = (id, index) => {
    setBankId(id);
    setBankIndex(index); 
  }

  useEffect(() => {
    var list = {};
    var promises = customerState.BankInfo.map((item, index) => {
      return auth.getPaytureTerminals({environment:2, bankId: item.BankId})
      .then((data)=>{
        if (data.MerchantContractsInfo.length > 0) {
          list[item.BankId] = data.MerchantContractsInfo.length > 0;
        }    
        return {[item.BankId]: data};    
      })    
    });

    Promise.all(promises).then((response) => {
      var index = 0;
      for (var item in list) {
        if (list[item]) {
          setBankId(parseInt(item));
          setBankIndex(index);
          break;
        }
        index++;
      }
      
      var obj = {};
      response.forEach((elem) => {
        for (var key in elem) {
          obj[key] = elem[key];
        }
      });
      setTerminals(obj);
      setBankList(list); 
    });    
    
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (bankId) {
      //doBankRequest(bankId)
    }
    
    // eslint-disable-next-line
  },[bankId])

/*   const doBankRequest = (id) => {
    return new Promise((resolve, reject)=>getTerminals(
      {environment:2, bankId: id}, {
      onSuccess: (data) => {
        if(data.Success) {
            setTerminals(data);
            resolve(data);  
        }
      }
    }))
  } */

  return <ProductionPageVew  
          data={terminals&&terminals[bankId]} 
          terminals={terminals}
          loading={isLoading} 
          customerState={customerState} 
          bankIndex={bankIndex}
          bankId={bankId} 
          bankList={bankList}
          onBankSelect={handleBankSelect} 
         /> 
}

export default ProductionPage;
