export  const queryConfig = {
  shared: {
    suspense: true,
  },
  queries: {
/*     staleTime: 900719925474099,
    cacheTime: 900719925474099,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false */
  }
}
