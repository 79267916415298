import React, { lazy, Suspense, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cls from "classnames";
import classes from "./survey.module.scss";
import SurveyStepsList from 'components/organizms/survey-steps-list';
import WhitePaper from 'components/elements/white-paper';
import StepLabel from 'components/elements/step-label';
import Loader from 'components/elements/loader-vintage';
import StepDescription from 'components/elements/step-description';
import Modal from 'components/organizms/modal'
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccordionContainer from 'components/elements/accordion-container';
import { QiwiLogo, TinkoffLogo } from 'components/elements/icons';

const SPStep1 = lazy(() => import('ddf/forms/sp/step1'));
const SPStep2 = lazy(() => import('ddf/forms/sp/step2'));
const SPStep3 = lazy(() => import('ddf/forms/sp/step3'));
const SPStep4 = lazy(() => import('ddf/forms/sp/step4'));
const SPStep5 = lazy(() => import('ddf/forms/sp/step5'));
const SPStep6 = lazy(() => import('ddf/forms/sp/step6'));

const LLCStep1 = lazy(() => import('ddf/forms/llc/step1'));
const LLCStep2 = lazy(() => import('ddf/forms/llc/step2'));
const LLCStep3 = lazy(() => import('ddf/forms/llc/step3'));
const LLCStep4 = lazy(() => import('ddf/forms/llc/step4'));
const LLCStep5 = lazy(() => import('ddf/forms/llc/step5'));
const LLCStep6 = lazy(() => import('ddf/forms/llc/step6'));

const Forms = {
  "SP": [null, SPStep1, SPStep2, SPStep3, SPStep4, SPStep5, SPStep6],
  "LLC": [null, LLCStep1, LLCStep2, LLCStep3, LLCStep4, LLCStep5, LLCStep6]
}

const BankLogos = {
  1: <QiwiLogo height="38"/>,
  2: <TinkoffLogo height="38"/>
}

const StyledButtonCancel = styled(Button)((style) => ({
  
  width: '60%',
  ...style,
  color: '#8B74D8!important',
  borderRadius: '90px',
  backgroundColor: 'transparent!important',
  border: '2px solid #8B74D8!important', 
  textTransform: 'none',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  padding: '14px 24px',
  '&:active': {
    borderColor: '#625FBF!important',
    color: '#625FBF!important'
  },
  '&:hover': {
    borderColor: '#625FBF!important',
    color: '#625FBF!important'
  },
  '&:disabled': {
    borderColor: '#BFB8D5!important',
    color: '#BFB8D5!important'
  }
}));

export const StyledButton = styled(Button)(() => ({
  
  width: '100%',
  color: '#ffffff!important',
  borderRadius: '90px',
  backgroundColor: '#8B74D8',

  textTransform: 'none',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  padding: '14px 24px',
  '&:active': {
    backgroundColor: '#625FBF'
  },
  '&:hover': {
    backgroundColor: '#625FBF'
  },
  '&:disabled': {
    backgroundColor: '#BFB8D5'
  },
  '&.submitting': {
    backgroundColor: '#8B74D8',
    height: '48px',
    'svg': {
      animationName: 'rotation',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear'
    }
  }
}));

const SurveyPageView = ({ 
  step, customerState, onSubmit, onCancel, initialData, 
  isOpenStep6Modal = false, isOpenStepSavedModal = false, 
  onDownloadStep6Callback = null, onGetStep6Callback = null,
  isDownloadingStep6, onSetIsOpenStepSavedModal, loading,
  step6InitialData, opfs, countries
}) => {
  const { t } = useTranslation('main');  
  const FormComponent = Forms[customerState.CustomerType === 1 ? "SP":"LLC"][step];
  const [isOpenStep6, setIsOpenStep6] = useState(false);

  let changed = useRef();

  React.useEffect(() => {
    setIsOpenStep6(isOpenStep6Modal)
  }, [isOpenStep6Modal]); 

  const handleBlockerDataCompare = (state) => {
    //debugger
    changed.current = state.dirty;
  }

  const getStatusText = (bankItem) => {
    let result = ['',''];
    switch (bankItem.QuestionnaireBankStatus) {
      case 1:
      case 3:
        result = ['Заявление сформировано','Скачайте заявление и подпишите его, а затем загрузите скан в поле ниже'];
      break;
      case 2:
        result = ['Заявление загружено','Ваша заявка отправлена на согласование в банк'];
      break;
      case 4:
      case 5:
      case 6:
      case 7:
        result = ['Подключение одобрено','Рабочий доступ готов. Теперь вы можете принимать онлайн-платежи!'];
      break;
      case 8:
        result = ['Подключение отклонено','Подключение онлайн-платежей на ваш сайт отклонено банком'];
      break;
      default:
        result = ['',''];
    }
    return result;
  }

  if (!step) return <Loader/>

  return  <>
            <SurveyStepsList step={step} customerState={customerState} onSubmit={onSubmit} onCancel={onCancel} initialData={initialData} />
            <WhitePaper className={cls(classes['steps-list'])}>
              <StepLabel label={t(`survey-page.steps.${step}.part-name`)} className='bottom-margin'/>
              {
                step === 6 && <StepDescription step={step} withDescription={customerState.Bet} />
              }
{/*               {
                (loading && step !== 6) && <Loader over />
              } */}
              {
                initialData && 
                  <Suspense fallback={<Loader />}>
                    {
                      step !== 6 && !loading &&
                      <FormComponent 
                        onSubmit={onSubmit} 
                        readOnly={
                          !customerState.BankInfo.some((item) => item.QuestionnaireBankStatus === 1 || item.QuestionnaireBankStatus === 3)
                        } 
                        onCancel={onCancel} 
                        initialData={initialData} 
                        onBlock={handleBlockerDataCompare}
                        callback={onDownloadStep6Callback}
                        getCallback={onGetStep6Callback}
                        isLoading={isDownloadingStep6}
                        bankId={null}
                        opfs={opfs}
                        countries={countries}
                      />                        
                    }
                  </Suspense>
              }              
            </WhitePaper>
            {
              (step === 6) && (
                  customerState.BankInfo&&customerState.BankInfo.map((item, index)=>{
                    
                    return <AccordionContainer 
                            key={`step-bank-accordion-${index}`} 
                            index={index} 
                            title={<div className={classes.accordion__title}><div>{BankLogos[item.BankId]}</div><div dangerouslySetInnerHTML={{__html: t(`banks:statuses.${item.QuestionnaireBankStatus}`)}}/></div>}  
                            className={cls(classes['steps-list__accordion-container'])}
                            onOpen={()=>onGetStep6Callback(item.BankId)}
                          >
                            {
                              (!customerState.Bets.find((b)=>b.BankId===item.BankId)['Bet'] && item.QuestionnaireBankStatus === 1) ? 
                              <div>После согласования ставки в банке вам откроется доступ к скачиванию заявления.</div>:
                              (step6InitialData && step6InitialData[item.BankId]) && !loading &&
                                <Suspense fallback={<Loader />}>
                                  {
                                  <FormComponent 
                                    onSubmit={onSubmit} 
                                    readOnly={
                                      item.QuestionnaireBankStatus !== 1 && item.QuestionnaireBankStatus !== 3
                                    } 
                                    onCancel={onCancel} 
                                    initialData={step6InitialData[item.BankId]} 
                                    onBlock={handleBlockerDataCompare}
                                    callback={onDownloadStep6Callback}
                                    isLoading={isDownloadingStep6}
                                    bankId={item.BankId}
                                    statusText={getStatusText(item)}
                                  />
                                  }
                                </Suspense>                              
                            }
                          </AccordionContainer>
                  })
              )
            }                
               
            <Modal handleClose={() => onSetIsOpenStepSavedModal(false)} isOpen={isOpenStepSavedModal} >
              <WhitePaper>
                <div className={cls(classes['modal'])}>
                <div className={cls(classes['modal__title'])} dangerouslySetInnerHTML={{ __html: t(`main:stepsaved.title`) }} />
                <div className={cls(classes['modal__text'])} dangerouslySetInnerHTML={{ __html: t(`main:stepsaved.text`) }} />   
                <StyledButton  variant="outlined" style={{width: '100%'}} onClick={() => onSetIsOpenStepSavedModal(false)}>
                {t('main:stepsaved.button')}
                </StyledButton>
                </div>
              </WhitePaper>
            </Modal>
            <Modal handleClose={() => setIsOpenStep6(false)} isOpen={isOpenStep6}>
              <WhitePaper>
                <div className={cls(classes['modal'])}>
                <div className={cls(classes['modal__title'])} dangerouslySetInnerHTML={{ __html: t(`main:survey-page.steps.6.modal-success.title`) }} />
                <div className={cls(classes['modal__text'])} dangerouslySetInnerHTML={{ __html: t(`main:survey-page.steps.6.modal-success.text`) }} /> 
                <StyledButtonCancel  variant="outlined" style={{width: '100%'}} href={t('main:survey-page.steps.6.modal-success.button.link')}>
                {t('main:survey-page.steps.6.modal-success.button.name')}
                </StyledButtonCancel>  
                </div>
              </WhitePaper>
            </Modal>
          </>
}

export default SurveyPageView;
