export const styles = {
  fontFamily: 'Inter!important',
  '&.MuiTypography-root': {
    fontFamily: 'Inter',
    '&.MuiTypography-h1': {
      fontSize: '28px',
    },
    '&.MuiTypography-h2': {
      fontSize: '20px',
    },
    '&.MuiTypography-h5': {
      fontSize: '14px',
    },
    '&.MuiTypography-body1': {
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '24px',
      color: '#000000'
    },
    '& .MuiFormLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: '#000000!important',
        fontFamily: 'Inter'
      }
    }
  },
  '& .MuiInputBase-root': {
    borderRadius: '4px!important',
    border: '1px solid #D7D9DA',
    fontFamily: 'Inter!important',
    backgroundColor: 'transparent!important',
    color: '#000000',
    '&:after': {
      borderBottomColor: 'transparent!important'
    },
    '&:before': {
      borderBottomColor: 'transparent!important'
    },
    '&.Mui-focused': {
      border: '1px solid #AEB8BF',
      color: '#000000',
      '&:after': {
        borderBottomColor: 'transparent!important'
      },
      '&:before': {
        borderBottomColor: 'transparent!important'
      } 
    },
    '&.Mui-error': {
      borderColor: '#FF002E'
    },  
    '&.Mui-disabled': {
      background: 'rgba(129, 137, 154, 0.05)!important',
      border: '1px solid #D7D9DA!important',
      color: '#81899A'
    }    
  },

  '& input': {
    fontSize: '14px',
    lineHeight: '18px',
    border: 0,
    borderRadius: '4px!important',
    fontFamily: 'Inter',
    paddingBottom: '10px',
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: 'transparent !important',
      fontSize: '14px',
      lineHeight: '18px',
      fontFamily: 'Inter'
    }
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '12px',
    fontFamily: 'Inter',
    fontSize: '11px',
    lineHeight: '13px',
    color: '#FF002E'
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: '#AEB8BF',
    top: '4px',
    '&.Mui-error': {
      color: '#81899A'
    },
    '&.Mui-focused': {
      color: '#000000'
    },
    '&.Mui-disabled': {
      color: '#AEB8BF'
    }
  },
  '&.MuiFormLabel-root': {
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: '#000000',
    '&.Mui-error': {
      color: '#81899A'
    },
    '&.Mui-focused': {
      color: '#000000'
    },
    '&.Mui-disabled': {
      color: '#AEB8BF'
    }
  },
  '&.MuiFormControl-root': {
    margin: '0!important'
  },
  '& .MuiSvgIcon-root': {
    color: '#AEB8BF'
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
    color: '#8b74d8'
    }
  },
  '& .MuiCheckbox-root': {
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: '#AEB8BF',
    '&.Mui-checked': {
      color: 'red'
    }
  },
  '& .MuiAutocomplete-popper': {
    fontFamily: 'Inter!important'
  }
}