import client from './client'
import qs from 'qs'

/* import { encodeObject } from './utils' */

/* const config = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
} */

const uploadConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
}

const sessionAddition = () => { 
  return process.env.NODE_ENV === 'production' ? {}:{"aId": sessionStorage.getItem('SessionId')}; 
}

const buildQuery = (data) => {
  return qs.stringify(data, { addQueryPrefix: true })
}

const api = {
  getCustomerState: (data) => {
    return client.get('/GetCustomerState' + buildQuery(sessionAddition()))
  },

  postAuthorizeCustomer: (data) => {
    return client.post('/AuthorizeCustomer', data)
  },

  postRegistrationCustomer: (data) => {
    return client.post('/RegistrationCustomer', data)
  },

  postSetPasswordCustomer: (data) => {
    return client.post('/SetPasswordCustomer', data)
  },

  postRecoveryPasswordCustomer: (data) => {
    return client.post('/RecoveryPasswordCustomer', data)
  },

  getIpFirstQuestionnaireStep: (data) => {
    return client.get('/IpFirstQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}))
  },

  postIpFirstQuestionnaireStep: (data) => {
    return client.post('/IpFirstQuestionnaireStep' + buildQuery(sessionAddition()), data)
  },

  getIpSecondQuestionnaireStep: (data) => {
    return client.get('/IpSecondQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}))
  },

  postIpSecondQuestionnaireStep: (data) => {
    return client.post('/IpSecondQuestionnaireStep' + buildQuery(sessionAddition()), data)
  },

  getIpThirdQuestionnaireStep: (data) => {
    return client.get('/IpThirdQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}))
  },

  postIpThirdQuestionnaireStep: (data) => {
    return client.post('/IpThirdQuestionnaireStep' + buildQuery(sessionAddition()), data)
  },

  getYuLFirstQuestionnaireStep: (data) => {
    return client.get('/YuLFirstQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}))
  },

  postYuLFirstQuestionnaireStep: (data) => {
    return client.post('/YuLFirstQuestionnaireStep' + buildQuery(sessionAddition()), data)
  },

  getYuLSecondQuestionnaireStep: (data) => {
    return client.get('/YuLSecondQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}))
  },

  postYuLSecondQuestionnaireStep: (data) => {
    return client.post('/YuLSecondQuestionnaireStep' + buildQuery(sessionAddition()), data)
  },
  
  getYuLThirdQuestionnaireStep: (data) => {
    return client.get('/YuLThirdQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}))
  },

  postYuLThirdQuestionnaireStep: (data) => {
    return client.post('/YuLThirdQuestionnaireStep' + buildQuery(sessionAddition()), data)
  },

  getAllFourthQuestionnaireStep: (data) => {
    return client.get('/AllFourthQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}))
  },

  postAllFourthQuestionnaireStep: (data) => {
    return client.post('/AllFourthQuestionnaireStep' + buildQuery(sessionAddition()), data)
  },

  getAllFifthQuestionnaireStep: (data) => {
    return client.get('/AllFifthQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}))
  },

  postAllFifthQuestionnaireStep: (data) => {
    return client.post('/AllFifthQuestionnaireStep' + buildQuery(sessionAddition()), data)
  },

  getAllSixthQuestionnaireStep: (data) => {
    return client.get('/AllSixthQuestionnaireStep' + buildQuery({...data, ...sessionAddition()}), data.onlyFileName ? {}:{  responseType: "arraybuffer" })
  },

  postAllSixthQuestionnaireStep: ({bankId, formFile}) => {
    const formData = new FormData()
    formData.append('formFile', formFile);
    return client.post('/AllSixthQuestionnaireStep' + buildQuery({bankId: bankId, ...sessionAddition()}), formData, uploadConfig)
  },

  getPaytureTerminals: (data) => {
    return client.get('/GetPaytureTerminals' + buildQuery({...data, ...sessionAddition()}))
  },

  getManagerComments: () => {
    return client.get('/GetManagerComments' + buildQuery(sessionAddition()))
  },

  saveCustomerFile: ({fieldName, formFile}) => {
    const formData = new FormData()
    formData.append('formFile', formFile)
    return client.post('/SaveCustomerFile' + buildQuery({fieldName, ...sessionAddition()}), formData, uploadConfig)
  },
  
  getCustomerFile: (data) => {
    return client.get('/GetCustomerFile' + buildQuery({...data, ...sessionAddition()}), {  responseType: "arraybuffer" })
  },

  postLogoutCustomer: () => {
    return client.delete('/Logout');
  },

  getBets: () => {
    return client.get('/GetBets', {})
  },

  getOPFs: () => {
    return client.get('/GetOPFs', {})
  },
  
  getCountries: () => {
    return client.get('/GetCountries', {})
  }
  
}

api.client = client

export default api
