import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from 'components/organizms/footer';
import { useAuthorization } from 'components/hooks/withAuthorization';
import Loader from 'components/elements/loader-vintage';
import { Navigate } from "react-router-dom";

const EmptyPage = () => {
  const isAuthorized = useAuthorization();

  return  <>
            
            {
                (typeof isAuthorized === 'undefined' || isAuthorized === null) && <Loader/>
              }
              {
                isAuthorized === false && <><Outlet /><Footer/></>
              }
              {
                isAuthorized && <Navigate to="/" replace />
              }
          </>
}

export default EmptyPage