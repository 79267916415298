import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import classes from './styles.module.scss';
import FormFieldGrid from './form-field-grid';
import FileUploader from 'components/organizms/FileUploader';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { UploadedIcon, CloseIcon } from 'components/elements/icons';
import { validationError } from './validation-error';

const FileUpload = (props) => {
  const {
    input,
    isDisabled,
    validateOnMount,
    meta,
    FormFieldGridProps
  } = useFieldApi(props);
  
  const [selectedFiles, setSelectedFiles] = useState(null);
  const invalid = validationError(meta, validateOnMount)
  const nodeRef = React.createRef(null);

  useEffect(() => {
    if (selectedFiles) {
      input.onChange(selectedFiles);
    } else {
      input.onChange(null)
    }
    // eslint-disable-next-line
  }, [selectedFiles]);

  const handleFileSelect = React.useCallback((files) => {
    const nextValue = [];

    for (const file of files) {
      nextValue.push(file);
      input.onChange(nextValue);
    }
   
    setSelectedFiles(nextValue);
  }, [input]);

  useEffect(() => {
    if (input && typeof input.value === 'string' && input.value !== '') {
      var f = new File([""], input.value, {size: 1, type: "text/plain", lastModified: new Date()})
      setSelectedFiles([f]);
    }
  }, [input, input.value]);

  const handleRemoveFile = React.useCallback(
    (file, index) => {
      if (selectedFiles === null) {
        return;
      }

      const nextValue = selectedFiles.filter((item, i) => i !== index);
      if (nextValue.length > 0) {
        setSelectedFiles(nextValue);
      } else {
        setSelectedFiles(null);
      }
    },
    [selectedFiles]
  );

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <FileUploader
        className={cls(classes.upload__drop, !!invalid ? classes.file__error:'')}
        id={input.name}
        onChange={handleFileSelect}
        disabled={isDisabled}
      >
        {({ draggedOver }) => {
          if (draggedOver) {
            return (
              <span className={cls(classes.upload__label, classes.upload__label_dragText)}>
                Поместите файлы сюда
              </span>
            );
          } else {
            return (
              <span className={cls(classes.upload__label)}>
                <span className={cls(classes.upload__label_blue)}>Выберите файл</span>{" "}
                {/* <span className={cls(classes.upload__label_black)}>
                  или перетяните сюда
                </span> */}{" "}
                (pdf/jpeg/jpg/png файл до 5 МБ) {props.isRequired && <span aria-hidden="true" className={cls('MuiFormLabel-asterisk', 'MuiInputLabel-asterisk', 'css-wgai2y-MuiFormLabel-asterisk')}> *</span>}
              </span>
            );
          }
        }}
      </FileUploader>
      {(selectedFiles && selectedFiles[0] && selectedFiles[0]['name'] !== '') && (
        <div className={cls(classes['upload__selection'])}>
          <TransitionGroup>
                <CSSTransition
                  nodeRef={nodeRef}
                  key={input.value}
                  timeout={300}
                >
                  <div
                    ref={nodeRef}
                    className={cls(
                      classes.file
                    )}
                  >
                    <div className={cls(classes.file__layout, meta.error && classes.file__error)}>
                      <UploadedIcon height="24" width="24" className={classes.shrink} />
                      <span className={cls(classes.file__name)}>
                        {selectedFiles[0]['name']}
                      </span>
                      { meta.error &&
                        <div className={cls(classes.file__error_warning)}><span>{meta.error}</span></div>
                      }
                      <CloseIcon 
                        style={{ cursor: "pointer", marginLeft: !meta.error && "auto"}}
                        height='14'
                        width='14'
                        className={cls(classes.file__remove)}
                        onClick={() => handleRemoveFile(input, 0)}
                        disabled={!!invalid}                      
                      />
                    </div>
                  </div>
                </CSSTransition>
          </TransitionGroup>
        </div>
      )}
    </FormFieldGrid>
  );
};

export default FileUpload
