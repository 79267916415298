import React from 'react';
import cls from 'classnames';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import WhitePaperWithTopper from 'components/elements/white-paper-with-topper';
import classes from './fz54.module.scss';

import Img1 from 'assets/images/orangedata.svg';
import Img2 from 'assets/images/atol.svg';

const images = {
  "orange-data": Img1,
  "atol": Img2
}

const FZ54PageView = ({ customerState }) => {
  const { t } = useTranslation('main');
  const orderTitle = (customerState.CustomerType === 1 ? "ИП":"ЮЛ") + customerState.QuestionnaireId;
  return <div className={cls(classes['fz54-page'])}>
          <WhitePaperWithTopper className={cls(classes['fz54-page__block_top'])}>  
            <div className={cls(classes['fz54-page__block-chip'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.2.page.top.chip') }}/>
            <div className={cls(classes['fz54-page__block-description'], classes['fz54-page__block-description_cut'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.2.page.top.description') }}/>
          </WhitePaperWithTopper>    
          <div className={cls(classes['fz54-page__title'])}>{t('fz54-page.blocks.2.page.title')}</div>
          <div className={cls(classes['fz54-page__container_papers'])}>
            <WhitePaperWithTopper className={cls(classes['fz54-page__block'])} topperColor='orange'>  
              <div className={cls(classes['fz54-page__block-image'])}>
                <img src={images[t('fz54-page.blocks.2.page.blocks.1.image')]} alt=""/>
              </div>
              <div className={cls(classes['fz54-page__block-title_inner'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.2.page.blocks.1.title') }}/>
              <div className={cls(classes['fz54-page__block-description'], classes['fz54-page__block-description_same-height'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.2.page.blocks.1.description') }}/>
              <div className={cls(classes['fz54-page__controls'], classes['fz54-page__controls_full-width'])}>
                <Link className={cls('button-bordered')} to={t('fz54-page.blocks.2.page.blocks.1.button.link') + "&subject=Настройка онлайн-кассы по Заявке №"+orderTitle}>{t('fz54-page.blocks.2.page.blocks.1.button.name')}</Link>
              </div>
            </WhitePaperWithTopper>
            <WhitePaperWithTopper className={cls(classes['fz54-page__block'])} topperColor='red'>  
              <div className={cls(classes['fz54-page__block-image'])}>
                <img src={images[t('fz54-page.blocks.2.page.blocks.2.image')]} alt=""/>
              </div>
              <div className={cls(classes['fz54-page__block-title_inner'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.2.page.blocks.2.title') }}/>
              <div className={cls(classes['fz54-page__block-description'], classes['fz54-page__block-description_same-height'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.blocks.2.page.blocks.2.description') }}/>
              <div className={cls(classes['fz54-page__controls'], classes['fz54-page__controls_full-width'])}>
                <Link className={cls('button-bordered')} to={t('fz54-page.blocks.2.page.blocks.2.button.link') + "&subject=Настройка онлайн-кассы по Заявке №"+orderTitle}>{t('fz54-page.blocks.2.page.blocks.2.button.name')}</Link>
              </div>
            </WhitePaperWithTopper>
          </div>
        </div>                 
}

export default FZ54PageView;
