import React, { useState } from 'react';
import cls from 'classnames';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { BurgerIcon, MenuHomeIcon, MenuSurveyIcon, 
  MenuDevelopmentIcon, MenuProductionIcon, 
  MenuFeedbackIcon, MenuFZ54Icon, ProfileIcon, LogoutIcon, QuestionMark } from 'components/elements/icons';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import classes from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const MenuListCustomized = styled(MenuList)(() => ({
  //gap: '16px',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  ' > * + *': { 
    marginTop: '16px'
  }
}));

const MenuItemCustomized = styled(MenuItem)(() => ({
  '& .MuiTypography-root': {
    fontFamily: 'Inter',
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '18px'
  },
  borderRadius: '8px',
  padding: '16px',
  width: '100%',
  '&.Mui-selected': {
    background: '#8B74D8',
    boxShadow: '0px 4px 25px rgba(92, 151, 255, 0.1)',
    color: '#ffffff',
    '&:hover': {
      background: '#8B74D8',
      boxShadow: '0px 4px 25px rgba(92, 151, 255, 0.1)'
    },
    '& path': {
      stroke: '#fff!important'
    }  
  },
  '&:hover': {
    background: '#F4F7FC',
    boxShadow: '0px 4px 25px rgba(92, 151, 255, 0.1)',
    color: '#000000'
  }

}));

export default function MobileMenu({customerState}) {
  const [ classNameBurger, setClassNameBurger ] = useState('');
  const [ show, setShow ] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const handleBurgerClick = () => {
    setClassNameBurger(classNameBurger ? '':'active');
    setShow(show ? false:true);
  }

  return (
          <div className={cls(classes['mobile-menu'])}>
            <BurgerIcon width='32' onClick={handleBurgerClick} className={classNameBurger}/>
            {
              show && <div className={cls(classes['inner-menu'])}>
                        <MenuListCustomized className={cls(classes['inner-menu__list'])}>
                          <MenuItemCustomized component={Link} to='/' onClick={handleBurgerClick} selected={location.pathname === '/'}>
                            <MenuHomeIcon height='16' className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText><span dangerouslySetInnerHTML={{ __html:t('main:menu-main') }}/></ListItemText>
                          </MenuItemCustomized>
                          <MenuItemCustomized component={Link} to='/survey' onClick={handleBurgerClick} selected={location.pathname.startsWith('/survey')}>
                            <MenuSurveyIcon height='16' className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText><span dangerouslySetInnerHTML={{ __html:t('main:menu-survey') }}/></ListItemText>
                            {/* { hasNewComments && <div className={cls(classes['inner-menu__new-message-indicator'])} />  } */}
                          </MenuItemCustomized>
                          <MenuItemCustomized disabled={!customerState.HasSandboxTerminals} component={Link} to='/development' onClick={handleBurgerClick} selected={location.pathname === '/development'}>
                            <MenuDevelopmentIcon height='16' className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText><span dangerouslySetInnerHTML={{ __html:t('main:menu-test') }}/></ListItemText>
                          </MenuItemCustomized>
                          <MenuItemCustomized disabled={!customerState.HasProductionTerminals} component={Link} to='/production' onClick={handleBurgerClick} selected={location.pathname === '/production'}>
                            <MenuProductionIcon height='16' className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText><span dangerouslySetInnerHTML={{ __html:t('main:menu-prod') }}/></ListItemText>
                          </MenuItemCustomized>
                          <MenuItemCustomized component={Link} to='/feedback' onClick={handleBurgerClick} selected={location.pathname === '/feedback'}>
                            <MenuFeedbackIcon height='16' className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText className={cls(customerState.HasNewComments && classes['fresh'])}><span dangerouslySetInnerHTML={{ __html:t('main:menu-feedback') }}/></ListItemText>
                            {/* { hasNewComments && <div className={cls(classes['inner-menu__new-message-indicator'])} />  } */}
                          </MenuItemCustomized>
                          <MenuItemCustomized component={Link} to='/fz54' onClick={handleBurgerClick} selected={location.pathname.startsWith('/fz54')}>
                            <MenuFZ54Icon height='16' className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText><span dangerouslySetInnerHTML={{ __html:t('main:menu-fz54') }}/></ListItemText>
                          </MenuItemCustomized>
                          <MenuItemCustomized component={Link} to='/faq' onClick={handleBurgerClick} selected={location.pathname.startsWith('/faq')}>
                            <QuestionMark height='16' color="#000000" className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText><span dangerouslySetInnerHTML={{ __html: t('main:menu-faq')}}/></ListItemText>
                          </MenuItemCustomized>
                          <MenuItemCustomized component={Link} to='/profile' onClick={handleBurgerClick} selected={location.pathname === '/profile'}>
                            <ProfileIcon height='16' className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText><span dangerouslySetInnerHTML={{ __html:t('main:menu-profile') }}/></ListItemText>
                          </MenuItemCustomized>
                          <MenuItemCustomized component={Link} to='/logout' onClick={handleBurgerClick} selected={location.pathname === '/logout'}>
                            <LogoutIcon height='16' className={cls(classes['inner-menu__icon'])}/>
                            <ListItemText><span dangerouslySetInnerHTML={{ __html:t('main:menu-logout') }}/></ListItemText>
                          </MenuItemCustomized>

                        </MenuListCustomized>
                      </div>
            }
          </div>
  )
}