import React, { useEffect } from 'react';
import ReactDadataBox from "react-dadata-box";
import PropTypes from 'prop-types';
import { TextField as MuiTextField } from '@mui/material';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormFieldGrid from './form-field-grid';
import { validationError } from './validation-error';
import { meta, input } from '@data-driven-forms/common/prop-types-templates';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';

import { styled } from '@mui/material/styles';
import { styles } from './styles';
import { footnoteTranslate } from './utils';

import { isMultiLined } from 'ddf/utils/is-multilined';
import { DADATA_TOKEN } from 'ddf/utils/dadata-token';

const CommonTextField = (props) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    inputProps,
    FormFieldGridProps,
    footnote,
    ...rest
  } = useFieldApi(props);

  const invalid = validationError(meta, validateOnMount);
  const formOptions = useFormApi();

  useEffect(()=>{
    input.onChange(input.value);
  }, [input])

  return (
    <FormFieldGrid {...FormFieldGridProps}>
       
      <ReactDadataBox 
        token={DADATA_TOKEN} 
        type='okved2' 
        query={input.value}
        customStyles={{
          "react-dadata__suggestions": 'dadata-note-none'
        }}
        onChange={value => {
          input.onChange(value.value)
        }}
        customInput={({ onChange, onKeyDown, onBlur, onFocus, value }) => (
            <MuiTextField
              {...input}
              variant='filled'
              multiline={isMultiLined(input.name) ? true : false}
              fullWidth
              error={!!invalid}
              helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
              disabled={formOptions.getState().submitting || isDisabled}
              label={label}
              placeholder={placeholder}
              required={isRequired}
              inputProps={{
                readOnly: isReadOnly, 
                ...inputProps
              }}
              onKeyDown={e => {
                onKeyDown(e)
              }}
              onChange={e => {
                onChange(e);
                input.onChange(e.currentTarget.value)
              }}
              onBlur={e => {
                onBlur(e)
                input.onBlur()
              }}
              onFocus={e=>{
                onFocus(e)
                input.onFocus()
              }}
              value={value}
              {...rest}
              
            /> 
          )
        }
      />
      {footnote && footnoteTranslate(footnote)}
    </FormFieldGrid>
  );
};

CommonTextField.propTypes = {
  input,
  meta,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  description: PropTypes.node,
  inputProps: PropTypes.object,
  FormFieldGridProps: PropTypes.object,
};

const TextField = styled(CommonTextField)(() => (styles));

export default TextField;
