import React from "react";
import OrderNumber from 'components/elements/order-number';
import ProfileMenu from 'components/organizms/profile-menu';
import MobileMenu from 'components/organizms/mobile-menu';
import MyPayments from 'components/organizms/my-payments';
import cls from 'classnames';
import classes from './styles.module.scss';

const Header = ({ className, number, customerState }) => {
  return (
    <div className={cls(classes[className], classes['sticky'])}>
      <MobileMenu customerState={customerState}/>
      <OrderNumber number={customerState ? ((customerState.CustomerType === 1 ? 'ИП':'ЮЛ')+customerState.QuestionnaireId):''} />
      <div className={cls(classes["right-part-container"])}>
      {
        customerState.HasProductionTerminals && <MyPayments/>
      }
      <ProfileMenu name={customerState ? customerState.CustomerName:''}/>
      </div>
    </div>
  )
}

export default Header
