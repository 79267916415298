import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { useTranslation } from 'react-i18next';
import GradientPaper from 'components/elements/gradient-paper';
import DashedPaper from 'components/elements/dashed-paper';
import StepNumber from 'components/elements/step-number';
import { LockerIcon, SurveyConnectionApprovalIcon, ArrowRightIcon } from "components/elements/icons";
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

const StyledButton = styled(Button)({
    backgroundColor: '#ffffff',
    padding: '14px 24px',
    //gap: '16px',
    boxShadow: '0px 8px 16px rgba(46, 123, 192, 0.5)',
    borderRadius: '90px',
    color: '#4BAFF3',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#4BAFF3',
      backgroundColor: '#ffffff',
    },
    ' > * + *': { 
      marginLeft: '16px'
    }
});
const SurveyConnectionApproval = ({className, status}) => {
  const { t } = useTranslation();
  return (
    <div className={cls('flex', 'gap16')}>
    <StepNumber number='4' active={status === 7} finished={true} lineClassName='no-bottom-border' className={cls(className)} />
    {
      status === 7 ? <GradientPaper className={cls(className, 'flex', classes['container'])}>
        <SurveyConnectionApprovalIcon height='88' width="87"/>
        <div className={cls(classes['survey-connection-approval'])}>
          <div className={cls(classes['survey-connection-approval__text-block'])}>
            <div className={cls(classes['survey-connection-approval__title'])}>
              {t('main:survey-connection-approval-title')}
            </div>
            <div className={cls(classes['survey-connection-approval__hint'])}>
              {t('main:survey-connection-approval-hint')}
            </div>             
          </div>
          {
            <StyledButton className={cls(classes['button'])} component={Link} to="/production"><span>{t('main:pass')}</span><ArrowRightIcon height="16"/></StyledButton>
          }
        </div> 
      </GradientPaper>:
      <DashedPaper className={cls(className)}>
        <LockerIcon height='32'  className={cls(classes['survey-connection-approval__locker'])}/><div className={cls(classes['survey-connection-approval__title_passive'])}>{t('main:survey-connection-approval-title')}</div>
      </DashedPaper>
    }
    </div>  
  );
}

export default SurveyConnectionApproval
