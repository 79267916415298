import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { useTranslation } from 'react-i18next';

const TabTitle = ({ item, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <span>
      <span className={cls(classes['bank-tabs-container__bank-name'], disabled ? classes['bank-tabs-container__disabled']:"")}>{t(`banks:items.${item.BankId}.name`)}</span>
      <span className={cls(classes['bank-tabs-container__status'], disabled ? classes['bank-tabs-container__disabled']:"")} dangerouslySetInnerHTML={{ __html: t(`banks:statuses.${item.QuestionnaireBankStatus}`)}}/>
    </span> 
  );
}

export default TabTitle
