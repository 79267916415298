import React from 'react';
import ReactDadataBox from "react-dadata-box";
import PropTypes from 'prop-types';
import { TextField as MuiTextField } from '@mui/material';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormFieldGrid from './form-field-grid';
import { validationError } from './validation-error';
import { meta, input } from '@data-driven-forms/common/prop-types-templates';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';

import { styled } from '@mui/material/styles';
import { styles } from './styles';
import { footnoteTranslate } from './utils';
import { DADATA_TOKEN } from 'ddf/utils/dadata-token';
import { isMultiLined } from 'ddf/utils/is-multilined';

const CommonTextField = (props) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    inputProps,
    FormFieldGridProps,
    footnote,
    ...rest
  } = useFieldApi(props);
  
  const formOptions = useFormApi();
  
  return (
    <FormFieldGrid {...FormFieldGridProps}>
      
      <ReactDadataBox 
        token={DADATA_TOKEN} 
        query={input.value}
        type='fms_unit' 
        customStyles={{
          "react-dadata__suggestions": 'dadata-note-none'
        }}
        onChange={value => {
          input.onChange(value.value)
/*           if (input.name === 'PassportIssuedByWhomAttorney') {
            formOptions.change('DivisionCodeAttorney', value.data.code); 
          } else if (input.name === 'PassportIssuedByWhomExecutive') {
            formOptions.change('DivisionCodeExecutive', value.data.code); 
          } else {
            let namePrefix = input.name.split('.');
            if (namePrefix && namePrefix.length > 1) {
              formOptions.change(namePrefix[0]+'.DivisionCode', value.data.code); 
            } else {
              formOptions.change('DivisionCode', value.data.code); 
            }            
          } */
        }}
        customInput={({ onChange, onKeyDown, onBlur, onFocus, value }) => {
          const invalid = validationError(meta, validateOnMount);
          return <MuiTextField
          {...input}
          variant='filled'
          multiline={isMultiLined(input.name) ? true : false}
          fullWidth
          error={!!invalid}
          helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
          disabled={formOptions.getState().submitting || isDisabled}
          label={label}
          placeholder={placeholder}
          required={isRequired}
          inputProps={{
            readOnly: isReadOnly, 
            ...inputProps
          }}
          onKeyDown={e => {
            if (e.keyCode !== 13) {
              onKeyDown(e)
            }
          }}
          onChange={(e) => {
            onChange(e);
            input.onChange(e.currentTarget.value)
          }}
          onFocus={(e) => {
            input.onFocus()
            onFocus(e)
          }}
          onBlur={e => {
            input.onBlur()
            onBlur(e)
          }}

          value={value}
          {...rest}
        /> 
        }}
      />
      {footnote && footnoteTranslate(footnote)}
    </FormFieldGrid>
  );
};

CommonTextField.propTypes = {
  input,
  meta,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  description: PropTypes.node,
  inputProps: PropTypes.object,
  FormFieldGridProps: PropTypes.object,
};

const TextField = styled(CommonTextField)(() => (styles));

export default TextField;
