import React from 'react';
import cls from 'classnames';
import Logo from 'components/elements/logo';
import Menu from 'components/organizms/menu';
import Header from 'components/organizms/header';
import Footer from 'components/organizms/footer';
import classes from './main.module.scss';

const MainContainer = ({ children, customerState, ...props }) => {
  return  <>
            <main className={cls(classes['main'])}>
              <div  className={cls(classes['main__left-block'])}>
                <Logo className={cls('logo__main')} />
                <Menu className={cls('menu__block')} customerState={customerState} />
              </div>
              <div className={cls(classes['main__right-block'])}>
                <Header className={cls('header')} customerState={customerState} {...props} />
                {children}
              </div>
            </main>
            <Footer/>
          </>
}

export default MainContainer;
