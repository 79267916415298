import React, { useEffect, useState } from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import WhitePaper from 'components/elements/white-paper';
import StatusList from './status-list';
import BankTabs from "components/organizms/bank-tabs";
import TabTitle from "../bank-tabs/title";
import AccordionContainer from "components/elements/accordion-container";
import WelcomeBlock from 'components/organizms/main-welcome-block';

const ContentsBlock = ({ customerState }) => {
  
  const [ bankId, setBankId ] = useState(0); 
  const [ bankIndex, setBankIndex ] = useState(0); 

  const getQuestionnaireBankStatus = (bankId) => {
    return (customerState.BankInfo&&customerState.BankInfo.filter((item)=>item.BankId === bankId)[0])||{}; 
  }

  const handleBankSelect = (id, index) => {
    setBankId(id);
    setBankIndex(index);
  }

  useEffect(()=>{
    handleBankSelect(customerState.BankInfo && customerState.BankInfo.length > 0 ? customerState.BankInfo[0]['BankId']:1, 0);
    // eslint-disable-next-line
  }, [])

  return (
    <>
    <WelcomeBlock customerState={customerState} status={getQuestionnaireBankStatus(bankId)['QuestionnaireBankStatus']} />
    <div className={cls('desktop')}>
      <BankTabs bankInfo={customerState.BankInfo} onClick={handleBankSelect} bankId={bankId} />
      <WhitePaper className={cls(classes['contents-block'], classes['tabs'], bankIndex === 0 ? classes['tabs__first']:'')}>
        <StatusList  customerState={customerState} status={getQuestionnaireBankStatus(bankId)['QuestionnaireBankStatus']}/>   
      </WhitePaper>
    </div>
    <div className={cls('mobile')}>
      {
        customerState.BankInfo&&customerState.BankInfo.map((item, index)=>{
          return <AccordionContainer key={`bank-accordion-${index}`} index={index} title={<TabTitle item={item}/>}  className={cls(classes['contents-block__accordion-container'])}>
            <StatusList  customerState={customerState} status={getQuestionnaireBankStatus(item.BankId)['QuestionnaireBankStatus']}/> 
          </AccordionContainer>
        })
      }
      
    </div>
    </>
  );
}

export default ContentsBlock
