import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

const Element = ({ label, className = '' }) => {
  return (
    <div className={cls(classes['step-label'], classes[className])}>
      {label}
    </div>
  );
}

export default Element
