import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

const Element = ({ active, finished, declined = false, rejected = false, number, lineClassName='', className='' }) => {
  return (
    <div className={cls('line-vertical', classes['mobile-hidden'], lineClassName, active && 'active', finished && 'finished')}>
      <div className={cls(classes['step-number-wrapper'], className)}>
        <div className={cls(classes['step-number'], active && classes['step-number_active'], declined && classes['step-number_declined'], rejected && classes['step-number_rejected'])}>
          {number}
        </div>         
      </div>
    </div>

  );
}

export default Element