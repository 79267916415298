import React, { useEffect } from "react";
import MainPageVew from 'components/views/main/main';
import { useOutletContext } from "react-router-dom";
import { useGetCustomerState } from 'queries/authorization' 

const MainPage = () => {
  const [ customerState, setCustomerState ] = useOutletContext();
  const { mutate } = useGetCustomerState();
  useEffect(()=>{
    mutate()
    // eslint-disable-next-line
  }, [])
  return <MainPageVew customerState={customerState} setCustomerState={setCustomerState} /> 
}

export default MainPage;
