import React, { useState } from 'react';
import i18next from 'i18next'
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Modal from 'components/organizms/modal';
import classes from './styles.module.scss';
import Zoom from '@mui/material/Zoom';
import { QuestionMark } from 'components/elements/icons';
import WhitePaper from 'components/elements/white-paper';
import { StyledButtonCancel } from 'components/elements/buttons';
import cls from 'classnames'
import { useTranslation } from 'react-i18next';
import PassportScan1CheckedImage from 'assets/images/passport-scan-1-checked.jpg'
import PassportScan2CheckedImage from 'assets/images/passport-scan-2-checked.jpg'


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#8B74D8',
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(14),
    borderRadius: '8px',
    padding: '12px 16px',
    lineHeight: theme.typography.pxToRem(18),
    maxWidth: 350
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#8B74D8'
  }
}));

export const FootnoteModal = (obj) => {
  const { t } = useTranslation('main'); 
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return <>
          <div className={classes['modal-link']} onClick={handleClick}>{i18next.t(obj.hint)}</div>
          <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
              <WhitePaper>
                <div className={cls(classes['modal'])}>
                <div className={cls(classes['modal__title'])} dangerouslySetInnerHTML={{ __html: t(`main:survey-page.steps.passport-scan.modal.title`) }} />
                <div className={cls(classes['modal__text'])}>
                  <ul>
                    <li><span>загрузите фото оригинала документа или скан</span></li>
                    <li><span>на фото должны быть видны все четыре угла документа</span></li>
                    <li><span>не закрывайте данные руками или другими предметами, не используйте графический редактор</span></li>
                    <li><span>загружайте файлы не более 5 Мб в формате jpg, png или pdf</span></li>
                  </ul>
                  <div className={cls(classes['image-info'])}>
                    <div className={cls(classes['image-info__row'])}>
                      <div><img src={PassportScan1CheckedImage} height='120px' alt="Первая страница паспорта" /></div>
                      <div><span>Подтверждение<br/>личности</span></div>
                    </div>
                    <div className={cls(classes['image-info__row'])}>
                      <div><img src={PassportScan2CheckedImage} height='120px' alt="Страница паспорта с регистрацией" /></div>
                      <div><span>Подтверждение<br/>регистрации</span></div>
                    </div>
                  </div>
                </div> 
                <StyledButtonCancel  variant="outlined" style={{width: '100%'}} onClick={handleClick}>
                {t('main:survey-page.steps.passport-scan.modal.button.name')}
                </StyledButtonCancel>  
                </div>
              </WhitePaper>
            </Modal>
         </>
}

export const footnoteTranslate = (obj) => {
  

  if (typeof obj === 'string') {
    return i18next.t(obj)
  } else {
    switch (obj.type) {
      case 'link':
        return <Link to={obj.link} className={classes['link']}>{i18next.t(obj.message, { threshold: obj.threshold })}</Link> 
      case 'help':
        return <div className={classes['help']} style={obj.style || null}>
                <div>{i18next.t(obj.message, { threshold: obj.threshold })}</div>
                <HtmlTooltip 
                  title={<div dangerouslySetInnerHTML={{ __html: i18next.t(obj.hint)}}/>} 
                  TransitionComponent={Zoom}
                  arrow
                  enterTouchDelay={10}
                >
                  <div className={classes['help__question-icon_container']}>
                    <QuestionMark className={cls(classes['help__question-icon'], obj.svgClass)}/>
                  </div>
                </HtmlTooltip>
               </div>
      case 'passive-help':
        return <div className={classes['passive-help']}>{i18next.t(obj.message, { threshold: obj.threshold })}</div>
      case 'list': 
        const listItems = i18next.t(obj.listItems).split(";");
        return <div className={classes['footnote']}>
          <p className={classes['footnote__title']}>
            {i18next.t(obj.listTitle)}
          </p>
          <ul className={classes['footnote__list']}>
            {listItems.map((item, index) => <li key={index} className={classes['footnote__item']}>{i18next.t(item)}</li>)}
          </ul>
        </div>
      case 'exclusive':
        return <div className={classes['help']} style={obj.style || null}>
                <div>Ставка согласовывается индивидуально. В течение 1 рабочего дня с вами свяжется менеджер для уточнения деталей</div>
                <HtmlTooltip 
                  title={<div dangerouslySetInnerHTML={{ __html: i18next.t(obj.hint)}}/>} 
                  TransitionComponent={Zoom}
                  arrow
                  enterTouchDelay={10}
                >
                  <div className={classes['help__question-icon_container']}><QuestionMark className={classes['help__question-icon']} width="13.33px" heught="13.33px" /></div>
                </HtmlTooltip>
               </div>
      case 'modal':
        return FootnoteModal(obj);
      default:
        return i18next.t(obj.message, { threshold: obj.threshold })
    }
  }
} 
