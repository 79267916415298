import React, { useEffect } from 'react';
import { IMaskMixin } from 'react-imask';
import { TextField } from "./";
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import { validationError } from './validation-error';
import FormFieldGrid from './form-field-grid';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';

const MaskedInput = IMaskMixin(({inputRef, ...props}) => (
      <TextField
        {...props}
        inputRef={inputRef}
      />
    ));

const PhoneField = (props) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    inputProps,
    FormFieldGridProps,
    ...rest
  } = useFieldApi(props);
  const formOptions = useFormApi();

  useEffect(() => {
    if(input.value && !isNaN(input.value)) {
      let arr = input.value.split("")
      arr.splice(0, 0, "+"); 
      arr.splice(2, 0, " (");
      arr.splice(6, 0, ") ");
      arr.splice(10, 0, "-");
      arr.splice(13, 0, "-");
  
      input.value = arr.join("")
    }
    // eslint-disable-next-line
  }, [])
  
  const invalid = validationError(meta, validateOnMount);
  input.value = String(input.value)
  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <MaskedInput
        {...input}
        mask={'+7 (000) 000-00-00'}
        lazy={true} 
        onAccept={(value, mask)=>{
          input.onChange(value)
        }}
        fullWidth
        error={!!invalid}
        helperText={invalid || helperText || description}
        disabled={formOptions.getState().submitting || isDisabled}
        label={label}
        /* placeholder={placeholder} */
        required={isRequired}
        inputProps={{
          readOnly: isReadOnly,
          ...inputProps
        }}
        {...rest}
      />
    </FormFieldGrid>
  );
};

export default PhoneField
