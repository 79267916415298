import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormFieldGrid from './form-field-grid';
import { validationError } from './validation-error';
import { meta, input } from '@data-driven-forms/common/prop-types-templates';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import customStyles from './styles.module.scss';
import './styles.scss';
import { styled } from '@mui/material/styles';
import { styles } from './styles';
import { footnoteTranslate } from './utils';
import Slider from '@mui/material/Slider';

import { IMaskMixin, IMask } from 'react-imask';
import { TextField } from "./";

const marks = [
  {
    value: 0,
    label: '',
  },
  {
    value: 3000000,
    label: '3 млн',
  },
  {
    value: 5000000,
    label: '5 млн',
  },
  {
    value: 10000000,
    label: '10+ млн',
  },
];

function calculateValue(x) {
  return x//10**x
}
function uncalculateValue(x) {
  return x//Math.log10(x)
}

const MaskedInput = IMaskMixin(({inputRef, ...props}) => (
      <TextField
        {...props}
        inputRef={inputRef}
      />
    ));

class NullableMaskedNumber extends IMask.MaskedNumber {
  // @ts-ignore
  get typedValue() {
    return this.unmaskedValue !== ''
      ? // @ts-ignore
        super.typedValue
      : null;
  }
  set typedValue(num) {
    super.typedValue = num;
  }
}

const CommonTextField = (props) => {
  const {
    input,
    min,
    maxInput,
    maxSlider,
    sliderInterval,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    inputProps,
    FormFieldGridProps,
    footnote,
    inputLabelProps,
    ...rest
  } = useFieldApi(props);
  const invalid = validationError(meta, validateOnMount);
  const formOptions = useFormApi();

  const [valueSlider, setValueSlider] = useState(input.value === "" ? "":Number(input.value));
  const [valueInput, setValueInput] = useState(input.value === "" ? "":Number(input.value));

  const handleSliderChange = (event, newValue) => {
    setValueSlider(newValue);
    setValueInput(calculateValue(newValue))
  };

  const handleInputChange = (value) => {
    setValueInput(value === '' ? '' : Number(value));
    setValueSlider(value === '' ? '' : Number(uncalculateValue(value)))
  };

  var maskOptions = {
    mask:[
      { mask: '' },
      {
        mask: 'num ₽',
        lazy: false,
        blocks: {
          num: {
            mask: NullableMaskedNumber, 
            thousandsSeparator: ' ', 
            padFractionalZeros: false,
            normalizeZeros: true,
            min: 0,
            max: maxInput,
          }
        }
      }
    ]
}

  input.value = String(input.value)

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <MaskedInput
        {...input}
        {...maskOptions}
        value={String(valueInput)}
        lazy={false} 
        onAccept={(value, mask)=>{
          handleInputChange(mask._unmaskedValue);
          input.onChange(mask._unmaskedValue)
        }}
        fullWidth
        error={!!invalid}
        helperText={invalid || helperText || description}
        disabled={formOptions.getState().submitting || isDisabled}
        label={label}
        /* placeholder={placeholder} */
        required={isRequired}
        inputProps={{
          readOnly: isReadOnly,
          ...inputProps
        }}
        InputLabelProps={inputLabelProps}
        {...rest}
      />
      <Slider
        className={customStyles['MuiSlider-root']}
        value={typeof valueSlider === 'number' ? valueSlider : 0}
        onChange={(e, value)=>{
          //debugger
          handleSliderChange(e, value)
          input.onChange(calculateValue(value))
        }}
        min={min}
        max={maxSlider}
        marks={marks}
        step={100000}
        defaultValue={input.value === "" ? "":Number(input.value)}
      />
      <div style={{marginBottom: '32px'}}></div>
      {footnote && footnoteTranslate(footnote)}
    </FormFieldGrid>
  );
};

CommonTextField.propTypes = {
  input,
  meta,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  description: PropTypes.node,
  inputProps: PropTypes.object,
  FormFieldGridProps: PropTypes.object,
};

const TurnoverField = styled(CommonTextField)(() => (styles));

export default TurnoverField;



