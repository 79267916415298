import React from "react";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const ContentsBlock = () => {  
  const { t } = useTranslation('main');
  return (
    <div className={classes['contacts']}>
      <div className={classes["contacts__block"]}>
        <div className={classes["contacts__title"]}>
        {t('feedback-page.contacts.block.1.title')}
        </div>
        <div className={classes["contacts__content"]}>
          <div className={classes["contacts__cell"]}>
            <div className={classes["contacts__caption"]}>
            {t('feedback-page.contacts.block.1.cell.1.caption')}
            </div>
            <div className={classes["contacts__text"]}>
            <a href={t('feedback-page.contacts.block.1.cell.1.link')}>{t('feedback-page.contacts.block.1.cell.1.text')}</a>
            </div>
          </div>
          <div className={classes["contacts__cell"]}>
            <div className={classes["contacts__caption"]}>
            {t('feedback-page.contacts.block.1.cell.2.caption')}
            </div>
            <div className={classes["contacts__text"]}>
            <a href={t('feedback-page.contacts.block.1.cell.2.link')}>{t('feedback-page.contacts.block.1.cell.2.text')}</a>
            </div>
          </div>
        </div>
      </div>
      <div className={classes["contacts__block"]}>
        <div className={classes["contacts__title"]}>
        {t('feedback-page.contacts.block.2.title')}
        </div>
        <div className={classes["contacts__content"]}>
          <div className={classes["contacts__cell"]}>
            <div className={classes["contacts__caption"]}>
            {t('feedback-page.contacts.block.2.cell.1.caption')}
            </div>
            <div className={classes["contacts__text"]}>
            <a href={t('feedback-page.contacts.block.2.cell.1.link')}>{t('feedback-page.contacts.block.2.cell.1.text')}</a>
            </div>
          </div>
          <div className={classes["contacts__cell"]}>
            <div className={classes["contacts__caption"]}>
            {t('feedback-page.contacts.block.2.cell.2.caption')}
            </div>
            <div className={classes["contacts__text"]}>
            <a href={t('feedback-page.contacts.block.2.cell.2.link')}>{t('feedback-page.contacts.block.2.cell.2.text')}</a>
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
}

export default ContentsBlock
