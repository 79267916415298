import { schema } from 'ddf/schemas/forms/auth/login'
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import FormTemplate from 'ddf/templates/common';

import customActionMapper from 'ddf/mappers/ddf-action-mapper'
import customValidateMapper from 'ddf/mappers/ddf-validate-mapper'
import customComponentMapper from 'ddf/mappers/ddf-component-mapper'

const Form = ({onSubmit, ...props}) => {
  let loader = props.loader;

  return  <FormRenderer
            actionMapper={customActionMapper}
            validatorMapper={customValidateMapper}
            componentMapper={customComponentMapper}
            FormTemplate={(props) => <FormTemplate loadingButton={loader} {...props} />}
            schema={schema(props.loader)}
            onSubmit={onSubmit}
            /* validate={()=>{console.log('validate')}} */
          />
}
export default Form
