/* import './wdyr.js'; */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { queryConfig } from 'queries/config';
import 'translates';
import App from 'app';

import 'themes/layout.scss';

const queryClient = new QueryClient({
  defaultOptions: queryConfig
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryClientProvider client={queryClient}>
    <Suspense fallback={null}>
      <App />      
    </Suspense>
  </QueryClientProvider>
);
