import React, { useState } from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { useTranslation } from 'react-i18next';
import GradientPaper from 'components/elements/gradient-paper';
import StepNumber from 'components/elements/step-number';
import DashedPaper from 'components/elements/dashed-paper';
import WhitePaper from 'components/elements/white-paper';
import { LockerIcon, SurveyModificationIcon, ArrowRightIcon } from "components/elements/icons";
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import Modal from 'components/organizms/modal'

const StyledButton = styled(Button)({
    backgroundColor: '#ffffff',
    padding: '14px 24px',
    //gap: '16px',
    boxShadow: '0px 8px 16px rgba(46, 123, 192, 0.5)',
    borderRadius: '90px',
    color: '#4BAFF3',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#4BAFF3',
      backgroundColor: '#ffffff',
    },
    ' > * + *': { 
      marginLeft: '16px'
    }
});
const SurveyModification = ({className, status}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  
  const handleModal = () => {
    setIsOpen(true);
  }

  return (
    <>
    <div className={cls('flex', 'gap16')}>
      <StepNumber number='3' active={status === 7 || status === 8 || status === 3} finished={status === 7} declined={status === 8} rejected={status === 3} className={cls(className)} />
      {
        (status === 7 || status === 8 || status === 3) ? <GradientPaper className={cls(className, 'flex', classes['container'], (status === 8 || status === 3) && classes['error'])}>
          <SurveyModificationIcon height='88' width="87"/>
          <div className={cls(classes['survey-modification'])}>
            <div className={cls(classes['survey-modification__text-block'])}>
              <div className={cls(classes['survey-modification__title'])}>
                {status === 7 && t('main:survey-modification-title')}
                {status === 3 && t('main:survey-modification-title-status-3')}
                {status === 8 && t('main:survey-modification-title-status-8')}
              </div>
              <div className={cls(classes['survey-modification__hint'])}>
                {status === 7 && t('main:survey-modification-hint')}
                {status === 3 && t('main:survey-modification-hint-status-3')}
                {status === 8 && t('main:survey-modification-hint-status-8')}
              </div>             
            </div>
            {
              status === 3 && <StyledButton className={cls(classes['button'])} component={Link} to="/feedback"><span>{t('main:pass')}</span><ArrowRightIcon height="16"/></StyledButton>
            }
            {
              status === 8 && <StyledButton className={cls(classes['button'])} component={Button} onClick={handleModal}><span>{t('main:more')}</span><ArrowRightIcon height="16"/></StyledButton>
            }
          </div>
        </GradientPaper>:
        <DashedPaper className={cls(className)}>
          <LockerIcon height='32' className={cls(classes['survey-modification__locker'])}/><div className={cls(classes['survey-modification__title_passive'])}>{t('main:survey-modification-title')}</div>
        </DashedPaper>
      }
    </div>  
    <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen} >
      <WhitePaper>
        <div className={cls(classes['modal'])}>
        <div className={cls(classes['modal__title'])} dangerouslySetInnerHTML={{ __html: t(`main:status8modal.title`) }} />
        <div className={cls(classes['modal__text'])} dangerouslySetInnerHTML={{ __html: t(`main:status8modal.text`) }} />   
        </div>
      </WhitePaper>
    </Modal>
    </>
  );
}

export default SurveyModification
