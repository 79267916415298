export const schema = (loading) => {
  let schema = {
    fields: [{
      component: 'TextField',
      name: 'email',
      isRequired: true,
      inputProps: {
        autoComplete: 'email',
        //autoFocus: true   
      },
      actions: {
        "label": ["translateString", 'forms:email'],
        "validate": ["translateValidate",
          [{
            type: 'required'
          }, {
            type: 'max-length', threshold: 100
          }, {
            type: 'email'
          }]
        ]
      },
      inputLabelProps: { shrink: true },
    }, {
      component: 'PasswordField',
      type: 'password',
      name: 'password',
      isRequired: true,
      inputProps:{
        autoComplete: 'current-password',
        //autoFocus: true 
      },
      actions: {
        "label": ["translateString", "forms:password"],
        "validate": ["translateValidate",  
          [{
            type: 'required'
          }]
        ]
      },
      inputLabelProps: { shrink: true },
      footnote: {
        type: 'link',
        message: 'forms:password-recovery-advice',
        link: '/password-recovery'
      }     
    } ],
    submitLabel: 'forms:login-button'
  }

  if (loading) {
    schema.fields = schema.fields.map((i)=>{
      return {...i, isReadOnly: true, isDisabled: true}
    })    
  }

  return schema;
}
