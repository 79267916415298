import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

const Element = ({ active=false, finished=false, disabled=false, step, name, lineClassName='', className='' }) => {

  return (

      <div className={cls(classes['step-number-container'])}>
          <div className={
            cls(
              classes['step-number'], 
              active && classes['step-number_active'], 
              finished && classes['step-number_finished'],
              disabled && classes['step-number_disabled']
            )
          }>
          {
            step
          }  
          </div>        

      {
        <span 
          dangerouslySetInnerHTML={{ __html: name}} 
          className={
            cls(
              classes['step-number__name'], 
              active   && classes['step-number__name_active'], 
              finished && classes['step-number__name_finished'],
              disabled && classes['step-number__name_disabled']
            )
          } 
        />
      }
    </div>
  );
}

export default Element