import React from 'react';
import { IMaskMixin } from 'react-imask';
import { TextField } from "./";
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import { validationError } from './validation-error';
import FormFieldGrid from './form-field-grid';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';

const MaskedInput = IMaskMixin(({inputRef, ...props}) => (
    <TextField
       inputRef={inputRef}
       {...props}
    />
));

const PassportField = (props) => {
    const {
        input,
        isReadOnly,
        isDisabled,
        placeholder,
        isRequired,
        label,
        helperText,
        description,
        validateOnMount,
        meta,
        inputProps,
        FormFieldGridProps,
        ...rest
      } = useFieldApi(props);
    const formOptions = useFormApi();
    const invalid = validationError(meta, validateOnMount);
    input.value = String(input.value)
    return (
        <FormFieldGrid {...FormFieldGridProps}>
            <MaskedInput
                {...input}
                mask={'0000 000000'}
                lazy={true} 
                onAccept={(value, mask)=>{
                  input.onChange(value)
                }}
                fullWidth
                error={!!invalid}
                helperText={invalid || helperText || description}
                disabled={formOptions.getState().submitting || isDisabled}
                label={label}
                /* placeholder={placeholder} */
                required={isRequired}
                inputProps={{
                  readOnly: isReadOnly,
                  ...inputProps
                }}
                {...rest}
            />
        </FormFieldGrid>
    );
};

export default PassportField