import React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import cls from 'classnames';
import { ProfileTopIcon, ArrowDownIcon, ProfileIcon, LogoutIcon } from 'components/elements/icons';
import classes from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { bindTrigger, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';

const icons = {
  "ProfileIcon": <ProfileIcon height='16' width='16' />,
  "LogoutIcon": <LogoutIcon height='16' width='16' />
};

export default function ProfileMenu({ name }) {
  const {t} = useTranslation('main');
  //const location = useLocation();
  let navigate = useNavigate();
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  
  const handleClick = (event, link) => {
    event.nativeEvent.preventDefault()
    popupState.close()
    setTimeout(()=>navigate(link), 300)
  }
  
  return (
          <div className={cls(classes['profile-menu'])}>

                
                <div className={cls(classes['profile-menu__content'])} {...bindTrigger(popupState)}>
                  
                    <div
                    className={cls(classes['profile-menu__tooltip'])}
                    >
                      <Avatar sx={{ width: 40, height: 40, bgcolor: '#4BAFF3' }}>
                        <ProfileTopIcon height='16' />
                      </Avatar>
                      <div className={cls(classes['profile-menu__name'])}>{name||'Ахмед'}</div>
                      <ArrowDownIcon height='6' className={cls(popupState.isOpen ? classes['profile-menu__arrowdown_reverse']:'')}/>
                    </div>
                  
                </div>
                  
                <Menu
                {...bindMenu(popupState)}
                  sx={{
                    '& .MuiButtonBase-root': {
                      fontFamily: 'Inter!important',
                      fontStyle: 'normal',
                      fontWeight: '500!important',
                      fontSize: '14px!important',
                      lineHeight: '18px!important',
                      textTransform: 'uppercase!important',
                      color: '#000000!important',
                      borderBottom: '1px solid #D7D9DA',
                      '&:last-child': {
                        borderBottom: '0'
                      }
                    }
                  
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      borderRadius: '8px',
                      filter: 'drop-shadow(0px 4px 25px rgba(79, 119, 188, 0.34))',

                      mt: 1.5,
                      '& svg': {
                        marginRight: '16px'
                      },
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      }
                    },
                  }} 
                >
                  {
                    t('profile.items', {returnObjects: true}).map((i, index)=>{
                      return (
                        <MenuItem key={`pm-${index+1}`} onClick={(event)=>handleClick(event, i.link)}>
                          {icons[i.icon]} {i.title}
                        </MenuItem>
                      )
                    })
                  }
                </Menu>
                

          </div>
  )
}