import React, { useEffect } from 'react';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import { COUNTRY_OPTIONS } from 'constants';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';

const FieldListener = (props) => {

  const {
    prefix,
    input
  } = useFieldApi(props);

  const { getState } = useFormApi();

  const values = getState().values;
  const country = values[`${prefix}CountryCode`];
  const city = values[`${prefix}City`];
  const region = values[`${prefix}Region`];
  useEffect(() => {
    if (country) {
      input.onChange({country: country && COUNTRY_OPTIONS.find((i)=>i.value===country).label, ...input.value});
    }
    if (city) {
      input.onChange({city: city, ...input.value});
    }
    if (region) {
      input.onChange({city: region, ...input.value});
    }
  }, [country, city, region, input]);

  return null;
};
const FieldListenerWrapper = (props) => {
  return <FormSpy subcription={{ values: true }}>{() => <FieldListener {...props}/>}</FormSpy>;
}

export default FieldListenerWrapper; 
