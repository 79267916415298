export const setupYandexMetrika = (counterId) => {
  const scriptLoadingPromise = (function (m, e, t, r, i, k, a) {
    return new Promise((res, rej) => {
      m[i] =
        m[i] ||
        function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      k = e.createElement(t);
      a = e.getElementsByTagName(t)[0];
      k.async = 1;
      k.src = r;
      k.onload = res;
      k.onerror = rej;
      a.parentNode.insertBefore(k, a);
    });
  })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  return scriptLoadingPromise
    .then((result) => {
      window.ym(counterId, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
      });

      return result;
    })
    .catch((err) => {
      console.warn("Failed to load Yandex Metrika script", err);
      return null;
    });
};
