import React, { memo } from "react";
import { Button, Grid } from "@mui/material";
import {
  useFieldApi,
  useFormApi,
} from "@data-driven-forms/react-form-renderer";
import FieldArray from "@data-driven-forms/react-form-renderer/field-array";
import isEqual from 'lodash/isEqual';
import FormFieldGrid from "./form-field-grid";
import styles from "./field-array.module.scss";
import AddIcon from "../../assets/images/add-llp-icon.svg";

const CustomFieldArray = (props) => {
  const { itemDefault, fields, ...rest } = useFieldApi(props);
  return (
    <FormFieldGrid>
      <FieldArray name={rest.input.name} className={styles.fieldArray}>
        {(cosi) => {
          return (
            <>
              <div className={styles.fieldArray__body}>
                {cosi.fields.map((name, index) => {
                  return (
                    <ArrayItem
                      key={name}
                      name={name}
                      fields={fields}
                      fieldIndex={index}
                      remove={cosi.fields.remove}
                      isDisabled={props.isDisabled === true}
                    />
                  );
                })}
              </div>
              <FieldArrayButton
                onClick={() => cosi.fields.push(itemDefault)}
                src={AddIcon}
                label="Добавить"
                isDisabled={props.isDisabled === true}
              />
            </>
          );
        }}
      </FieldArray>
    </FormFieldGrid>
  );
};
 
const ArrayItem = memo(
  ({ fields, name, remove, fieldIndex, isDisabled = false }) => {
  const { renderForm } = useFormApi();

  const editedFields = fields.map((field) => ({
    ...field,
    name: `${name}.${field.name}`,
  }));

  return (
    <Grid xs={12} item container rowSpacing={2}>
      <Grid xs={12} item>
        <FieldArrayButton onClick={() => remove(fieldIndex)} isDisabled={isDisabled} label="Удалить" />
      </Grid>
      {renderForm(editedFields)}
    </Grid>
  );
}, ({ remove: _prevRemove, ...prev }, { remove: _nextRemove, ...next }) => isEqual(prev, next)
);

const FieldArrayButton = ({ onClick, src = false, label, isDisabled = false }) => {
  return (
    <div className={styles.buttonWrapper}>
      <Button onClick={onClick} className={styles.button} disabled={isDisabled}>
        {src ? (
          <img className={styles.button__icon} src={src} alt="Icon" />
        ) : null}
        {label}
      </Button>
    </div>
  );
};

export default CustomFieldArray;
