import React from "react";
import PropTypes from "prop-types";
import { meta, input } from "@data-driven-forms/common/prop-types-templates";
import { useFieldApi } from "@data-driven-forms/react-form-renderer";
import clsx from "clsx";

import FormFieldGrid from "../form-field-grid";
import { footnoteTranslate } from '../utils';
import classes from "./textarea.module.scss";

export const Textarea = (props) => {
  const {
    //input,
    //meta,
    isRequired,
    FormFieldGridProps,
    label,
    //validateOnMount,
    footnote
  } = useFieldApi(props);

  const [value, setValue] = React.useState("");

  const isEmpty = value.trim() === "" ? true : false;

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <div className={classes.root}>
        <textarea
          id="textarea"
          required={isRequired}
          resize={'vertical'}
          onChange={(e) => setValue(e.target.value)}
        />
        <label
          htmlFor="textarea"
          className={clsx(
            classes.root__label,
            !isEmpty && classes.root__label_full
          )}
        >
          {label}
          {isRequired && <span style={{ color: '#EB5757', paddingLeft: '2px' }} >*</span>}
        </label>
      </div>
      {footnote && footnoteTranslate(footnote)}
    </FormFieldGrid>
  );
};

Textarea.propTypes = {
  input,
  meta,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
};
