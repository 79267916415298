import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { AvatarIcon } from "components/elements/icons";
import WhitePaperBordered from 'components/elements/white-paper-bordered'
import { Link } from "react-router-dom";

const FeedbackMessages = ({ messages }) => {

  const formatTime = (time) => {
    let convertTime = new Date(time),
      date,
      currentDay = new Date().getDate(),
      commentTime = convertTime.getHours() + ':' + convertTime.getMinutes();

    switch (convertTime.getDate()) {
      case currentDay - 1:
        date = 'Вчера'
        break;
      case currentDay:
        date = 'Сегодня'
        break;
      default:
        date = convertTime.getDate() + '.' + (convertTime.getMonth() + 1) + '.' + convertTime.getFullYear()
    }

    return date + ', ' + commentTime
  }

  const sortedMessages = (array) => {
    return array.sort((a,b) => new Date(a.Date) - new Date(b.Date))
  }

  return (
    <>
      {
        messages && sortedMessages(messages).map((message, key) => {
          // eslint-disable-next-line
          const { IsNew, ManagerName, Comment, Date, QuestionnairesId, Type } = message;
          let isNewDeliveredClass = IsNew ? classes['feedback-messages__container-new'] : '';

          return (
            <div key={`manager-comment_${key}`} className={cls(classes['feedback-messages'])}>
              {/* eslint-disable-next-line */}
              <WhitePaperBordered className={cls(classes['feedback-messages__container'], isNewDeliveredClass)}>
                <div className={cls(classes['feedback-messages__avatar'])}>
                  <AvatarIcon />
                </div>
                {
                  IsNew && <div className={cls(classes['feedback-messages__indicator'])} /> 
                }
                <div className={cls(classes['feedback-messages__message'])}>
                  <div className={cls(classes['feedback-messages__message_manager-name'])}>{ManagerName}</div>
                  {Comment}
                  <div className={cls(classes['feedback-messages__message_link'])}><Link to='/survey'>Перейти в анкету</Link></div>
                </div>
              </WhitePaperBordered> 
              <div className={cls(classes['feedback-messages__date'])}>{formatTime(Date)}</div>
            </div>
          )
        })
      }
    </>
  );
}

export default FeedbackMessages
