import React, { useEffect, useState } from 'react'
import MainContainer from 'components/views/main/container';
import { useAuthorization } from 'components/hooks/withAuthorization';
import { Outlet } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import { useQueryClient, QueryObserver } from '@tanstack/react-query';
import Loader from 'components/elements/loader-vintage';

const PrivatePageLayout = () => {
  const isAuthorized = useAuthorization();
  const queryClient = useQueryClient();
  const [ customerState, setCustomerState ] = useState(queryClient.getQueryData( ['CustomerState'] )||{});
  const observer = new QueryObserver(queryClient, { queryKey: ['CustomerState'] })
 
  useEffect(() => {
    const unsubscribe = observer.subscribe((result) => {
      if (result.status === 'success') {
        setCustomerState(result.data)        
      }
    })    
    
    return () => unsubscribe()
    // eslint-disable-next-line
  }, [])

  return  <>

              {
                (typeof isAuthorized === 'undefined' || isAuthorized === null) && <Loader/>
              }  
              {
                isAuthorized === false && <Navigate to="/login" replace />
              }
              {
                isAuthorized && <MainContainer customerState={customerState}>
                                  <Outlet context={[customerState, setCustomerState]} />
                                </MainContainer>
              }   
                
          </>
}

export default PrivatePageLayout
