import React, { useState } from "react";
import * as Queries from 'queries/authorization';
import DevelopmentPageVew from 'components/views/main/development';
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";

const DevelopmentPage = () => {
  const [ customerState ] = useOutletContext();
  const [ terminals, setTerminals ] = useState(); 

  const { mutate: getTerminals } = Queries.useGetPaytureTerminals();


  useEffect(() => {
    new Promise((resolve, reject)=>getTerminals(
      {environment:1}, {
      onSuccess: (data) => {
        if(data.Success) {
            setTerminals(data);
            resolve();  
        }
      }
    })
    // eslint-disable-next-line
  )}, [])

  return <DevelopmentPageVew 
          data={terminals} 
          customerState={customerState} 
         /> 
}

export default DevelopmentPage;
