import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next  } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "ru", 
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/ru/{{ns}}.json`
    },
    fallbackLng: "ru", 
    ns: ["auth", "footer", "main", "errors", "forms", "messages", "banks"],
    defaultNS: "auth",
    debug: false,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'],
      useSuspense: true
    }
  });

export default i18n
