import React from 'react';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { siteKey } from 'constants/captcha';

const Captcha = ({ onGetToken, visible, onCaptchaHidden }) => {

  const handleGetToken = (token) => {
    onGetToken && onGetToken(token);
  }
  
  return (
    <>
      {
        (process.env.REACT_APP_CAPTCHA_DISABLED !== 'true') && 
          <InvisibleSmartCaptcha
            sitekey={siteKey}
            onSuccess={handleGetToken}
            onChallengeHidden={onCaptchaHidden}
            visible={visible}
            hideShield={true}
          />        
      }
    </>
  );
}

export default Captcha;
