export const CITIZENSHIP_OPTIONS = [
  {
    value: "Россия",
    label: "Россия",
  },
  {
    value: "Афганистан",
    label: "Афганистан",
  },
  {
    value: "Албания",
    label: "Албания",
  },
  {
    value: "Алжир",
    label: "Алжир",
  },
  {
    value: "Американское Самоа",
    label: "Американское Самоа",
  },
  {
    value: "Андорра",
    label: "Андорра",
  },
  {
    value: "Ангола",
    label: "Ангола",
  },
  {
    value: "Ангилья",
    label: "Ангилья",
  },
  {
    value: "Антарктида",
    label: "Антарктида",
  },
  {
    value: "Антигуа и Барбуда",
    label: "Антигуа и Барбуда",
  },
  {
    value: "Аргентина",
    label: "Аргентина",
  },
  {
    value: "Армения",
    label: "Армения",
  },
  {
    value: "Аруба",
    label: "Аруба",
  },
  {
    value: "Австралия",
    label: "Австралия",
  },
  {
    value: "Австрия",
    label: "Австрия",
  },
  {
    value: "Азербайджан",
    label: "Азербайджан",
  },
  {
    value: "Багамы",
    label: "Багамы",
  },
  {
    value: "Бахрейн",
    label: "Бахрейн",
  },
  {
    value: "Бангладеш",
    label: "Бангладеш",
  },
  {
    value: "Барбадос",
    label: "Барбадос",
  },
  {
    value: "Беларусь",
    label: "Беларусь",
  },
  {
    value: "Бельгия",
    label: "Бельгия",
  },
  {
    value: "Белиз",
    label: "Белиз",
  },
  {
    value: "Бенин",
    label: "Бенин",
  },
  { value: "Бермудские о-ва", label: "Бермудские о-ва" },
  {
    value: "Бутан",
    label: "Бутан",
  },
  {
    value: "Боливия",
    label: "Боливия",
  },
  {
    value: "Босния и Герцеговина",
    label: "Босния и Герцеговина",
  },
  {
    value: "Ботсвана",
    label: "Ботсвана",
  },
  {
    value: "Бразилия",
    label: "Бразилия",
  },
  {
    value: "Британская территория в Индийском океане",
    label: "Британская территория в Индийском океане",
  },
  {
    value: "Болгария",
    label: "Болгария",
  },
  { value: "Буркина- Фасо", label: "Буркина- Фасо" },
  {
    value: "Бурунди",
    label: "Бурунди",
  },
  {
    value: "Камбоджа",
    label: "Камбоджа",
  },
  {
    value: "Камерун",
    label: "Камерун",
  },
  {
    value: "Канада",
    label: "Канада",
  },
  { value: "Кабо-Верде", label: "Кабо-Верде" },
  {
    value: "Острова Кайман",
    label: "Острова Кайман",
  },
  {
    value: "Центрально-Африканская Республика",
    label: "Центрально-Африканская Республика",
  },
  {
    value: "Чад",
    label: "Чад",
  },
  {
    value: "Чили",
    label: "Чили",
  },
  {
    value: "Китай",
    label: "Китай",
  },
  {
    value: "Колумбия",
    label: "Колумбия",
  },
  {
    value: "Коморы",
    label: "Коморы",
  },
  { value: "Конго - Браззавиль", label: "Конго - Браззавиль" },
  {
    value: "Конго - Киншаса",
    label: "Конго - Киншаса",
  },
  { value: "Коста-Рика", label: "Коста-Рика" },
  { value: "Кот-д’Ивуар", label: "Кот-д’Ивуар" },
  {
    value: "Хорватия",
    label: "Хорватия",
  },
  {
    value: "Куба",
    label: "Куба",
  },
  {
    value: "Кипр",
    label: "Кипр",
  },
  {
    value: "Чехия",
    label: "Чехия",
  },
  {
    value: "Дания",
    label: "Дания",
  },
  {
    value: "Джибути",
    label: "Джибути",
  },
  {
    value: "Доминика",
    label: "Доминика",
  },
  {
    value: "Доминиканская Республика",
    label: "Доминиканская Республика",
  },
  {
    value: "Эквадор",
    label: "Эквадор",
  },
  {
    value: "Египет",
    label: "Египет",
  },
  {
    value: "Сальвадор",
    label: "Сальвадор",
  },
  {
    value: "Экваториальная Гвинея",
    label: "Экваториальная Гвинея",
  },
  {
    value: "Эритрея",
    label: "Эритрея",
  },
  {
    value: "Эстония",
    label: "Эстония",
  },
  {
    value: "Эфиопия",
    label: "Эфиопия",
  },
  {
    value: "Фолклендские(Мальвинские) о-ва",
    label: "Фолклендские(Мальвинские) о-ва",
  },
  { value: "Фарерские о-ва", label: "Фарерские о-ва" },
  {
    value: "Фиджи",
    label: "Фиджи",
  },
  {
    value: "Финляндия",
    label: "Финляндия",
  },
  {
    value: "Франция",
    label: "Франция",
  },
  {
    value: "Французская Гвиана",
    label: "Французская Гвиана",
  },
  {
    value: "Французская Полинезия",
    label: "Французская Полинезия",
  },
  {
    value: "Французские Южные территории",
    label: "Французские Южные территории",
  },
  {
    value: "Габон",
    label: "Габон",
  },
  {
    value: "Гамбия",
    label: "Гамбия",
  },
  {
    value: "Грузия",
    label: "Грузия",
  },
  {
    value: "Германия",
    label: "Германия",
  },
  {
    value: "Гана",
    label: "Гана",
  },
  {
    value: "Гибралтар",
    label: "Гибралтар",
  },
  {
    value: "Греция",
    label: "Греция",
  },
  {
    value: "Гренландия",
    label: "Гренландия",
  },
  {
    value: "Гренада",
    label: "Гренада",
  },
  {
    value: "Гваделупа",
    label: "Гваделупа",
  },
  {
    value: "Гуам",
    label: "Гуам",
  },
  {
    value: "Гватемала",
    label: "Гватемала",
  },
  {
    value: "Гернси",
    label: "Гернси",
  },
  {
    value: "Гвинея",
    label: "Гвинея",
  },
  { value: "Гвинея-Бисау", label: "Гвинея-Бисау" },
  {
    value: "Гайана",
    label: "Гайана",
  },
  {
    value: "Гаити",
    label: "Гаити",
  },
  {
    value: "о-ва Херд и Макдональд",
    label: "о-ва Херд и Макдональд",
  },
  {
    value: "Ватикан",
    label: "Ватикан",
  },
  {
    value: "Гондурас",
    label: "Гондурас",
  },
  {
    value: "Гонконг",
    label: "Гонконг",
  },
  {
    value: "Венгрия",
    label: "Венгрия",
  },
  {
    value: "Исландия",
    label: "Исландия",
  },
  {
    value: "Индия",
    label: "Индия",
  },
  {
    value: "Индонезия",
    label: "Индонезия",
  },
  {
    value: "Иран",
    label: "Иран",
  },
  {
    value: "Ирак",
    label: "Ирак",
  },
  {
    value: "Ирландия",
    label: "Ирландия",
  },
  {
    value: "Израиль",
    label: "Израиль",
  },
  {
    value: "Италия",
    label: "Италия",
  },
  {
    value: "Ямайка",
    label: "Ямайка",
  },
  {
    value: "Япония",
    label: "Япония",
  },
  {
    value: "Джерси",
    label: "Джерси",
  },
  {
    value: "Иордания",
    label: "Иордания",
  },
  {
    value: "Казахстан",
    label: "Казахстан",
  },
  {
    value: "Кения",
    label: "Кения",
  },
  {
    value: "Кирибати",
    label: "Кирибати",
  },
  {
    value: "КНДР",
    label: "КНДР",
  },
  {
    value: "Республика Корея",
    label: "Республика Корея",
  },
  {
    value: "Косово",
    label: "Косово",
  },
  {
    value: "Кувейт",
    label: "Кувейт",
  },
  {
    value: "Киргизия",
    label: "Киргизия",
  },
  {
    value: "Лаос",
    label: "Лаос",
  },
  {
    value: "Латвия",
    label: "Латвия",
  },
  {
    value: "Ливан",
    label: "Ливан",
  },
  {
    value: "Лесото",
    label: "Лесото",
  },
  {
    value: "Либерия",
    label: "Либерия",
  },
  {
    value: "Ливия",
    label: "Ливия",
  },
  {
    value: "Лихтенштейн",
    label: "Лихтенштейн",
  },
  {
    value: "Литва",
    label: "Литва",
  },
  {
    value: "Люксембург",
    label: "Люксембург",
  },
  {
    value: "Макао",
    label: "Макао",
  },
  {
    value: "Северная Македония",
    label: "Северная Македония",
  },
  {
    value: "Мадагаскар",
    label: "Мадагаскар",
  },
  {
    value: "Малави",
    label: "Малави",
  },
  {
    value: "Малайзия",
    label: "Малайзия",
  },
  {
    value: "Мальдивы",
    label: "Мальдивы",
  },
  {
    value: "Мали",
    label: "Мали",
  },
  {
    value: "Мальта",
    label: "Мальта",
  },
  {
    value: "Маршалловы Острова",
    label: "Маршалловы Острова",
  },
  {
    value: "Мартиника",
    label: "Мартиника",
  },
  {
    value: "Мавритания",
    label: "Мавритания",
  },
  {
    value: "Маврикий",
    label: "Маврикий",
  },
  {
    value: "Майотта",
    label: "Майотта",
  },
  {
    value: "Мексика",
    label: "Мексика",
  },
  {
    value: "Федеративные Штаты Микронезии",
    label: "Федеративные Штаты Микронезии",
  },
  {
    value: "Молдова",
    label: "Молдова",
  },
  {
    value: "Монако",
    label: "Монако",
  },
  {
    value: "Монголия",
    label: "Монголия",
  },
  {
    value: "Черногория",
    label: "Черногория",
  },
  {
    value: "Монтсеррат",
    label: "Монтсеррат",
  },
  {
    value: "Марокко",
    label: "Марокко",
  },
  {
    value: "Мозамбик",
    label: "Мозамбик",
  },
  {
    value: "Мьянма",
    label: "Мьянма",
  },
  {
    value: "Намибия",
    label: "Намибия",
  },
  {
    value: "Науру",
    label: "Науру",
  },
  {
    value: "Непал",
    label: "Непал",
  },
  {
    value: "Нидерланды",
    label: "Нидерланды",
  },
  {
    value: "Кюрасао",
    label: "Кюрасао",
  },
  {
    value: "Новая Каледония",
    label: "Новая Каледония",
  },
  {
    value: "Новая Зеландия",
    label: "Новая Зеландия",
  },
  {
    value: "Никарагуа",
    label: "Никарагуа",
  },
  {
    value: "Нигер",
    label: "Нигер",
  },
  {
    value: "Нигерия",
    label: "Нигерия",
  },
  {
    value: "Ниуэ",
    label: "Ниуэ",
  },
  {
    value: "Норвегия",
    label: "Норвегия",
  },
  {
    value: "Оман",
    label: "Оман",
  },
  {
    value: "Пакистан",
    label: "Пакистан",
  },
  {
    value: "Палау",
    label: "Палау",
  },
  {
    value: "Палестина",
    label: "Палестина",
  },
  {
    value: "Панама",
    label: "Панама",
  },
  {
    value: "Папуа—Новая Гвинея",
    label: "Папуа—Новая Гвинея",
  },
  {
    value: "Парагвай",
    label: "Парагвай",
  },
  {
    value: "Перу",
    label: "Перу",
  },
  {
    value: "Филиппины",
    label: "Филиппины",
  },
  {
    value: "Польша",
    label: "Польша",
  },
  {
    value: "Португалия",
    label: "Португалия",
  },
  { value: "Пуэрто-Рико", label: "Пуэрто-Рико" },
  {
    value: "Катар",
    label: "Катар",
  },
  {
    value: "Реюньон",
    label: "Реюньон",
  },
  {
    value: "Румыния",
    label: "Румыния",
  },
  {
    value: "Руанда",
    label: "Руанда",
  },
  { value: "Сент-Люсия", label: "Сент-Люсия" },
  { value: "Сен-Мартен", label: "Сен-Мартен" },
  {
    value: "Самоа",
    label: "Самоа",
  },
  { value: "Сан-Марино", label: "Сан-Марино" },
  { value: "Сан-Томе и Принсипи", label: "Сан-Томе и Принсипи" },
  {
    value: "Саудовская Аравия",
    label: "Саудовская Аравия",
  },
  {
    value: "Сенегал",
    label: "Сенегал",
  },
  {
    value: "Сербия",
    label: "Сербия",
  },
  {
    value: "Сейшельские Острова",
    label: "Сейшельские Острова",
  },
  { value: "Сьерра-Леоне", label: "Сьерра-Леоне" },
  {
    value: "Сингапур",
    label: "Сингапур",
  },
  { value: "Синт-Мартен", label: "Синт-Мартен" },
  {
    value: "Словакия",
    label: "Словакия",
  },
  {
    value: "Словения",
    label: "Словения",
  },
  {
    value: "Соломоновы Острова",
    label: "Соломоновы Острова",
  },
  {
    value: "Сомали",
    label: "Сомали",
  },
  { value: "Южно-Африканская Республика", label: "Южно-Африканская Республика" },
  {
    value: "Южный Суда",
    label: "Южный Судан",
  },
  {
    value: "Испания",
    label: "Испания",
  },
  { value: "Шри-Ланка", label: "Шри-Ланка" },
  {
    value: "Судан",
    label: "Судан",
  },
  {
    value: "Суринам",
    label: "Суринам",
  },
  {
    value: "Эсватини",
    label: "Эсватини",
  },
  {
    value: "Швеция",
    label: "Швеция",
  },
  {
    value: "Швейцария",
    label: "Швейцария",
  },
  {
    value: "Сирия",
    label: "Сирия",
  },
  {
    value: "Тайвань",
    label: "Тайвань",
  },
  {
    value: "Таджикистан",
    label: "Таджикистан",
  },
  {
    value: "Танзания",
    label: "Танзания",
  },
  {
    value: "Таиланд",
    label: "Таиланд",
  },
  {
    value: "Восточный Тимор",
    label: "Восточный Тимор",
  },
  {
    value: "Togo",
    label: "Того",
  },
  {
    value: "Токелау",
    label: "Токелау",
  },
  {
    value: "Тонга",
    label: "Тонга",
  },
  {
    value: "Тринидад и Тобаго",
    label: "Тринидад и Тобаго",
  },
  {
    value: "Тунис",
    label: "Тунис",
  },
  {
    value: "Турция",
    label: "Турция",
  },
  {
    value: "Туркменистан",
    label: "Туркменистан",
  },
  {
    value: "Тувалу",
    label: "Тувалу",
  },
  {
    value: "Уганда",
    label: "Уганда",
  },
  {
    value: "Украина",
    label: "Украина",
  },
  {
    value: "ОАЭ",
    label: "ОАЭ",
  },
  {
    value: "Великобритания",
    label: "Великобритания",
  },
  {
    value: "Соединенные Штаты Америки",
    label: "Соединенные Штаты Америки",
  },
  {
    value: "Уругвай",
    label: "Уругвай",
  },
  {
    value: "Узбекистан",
    label: "Узбекистан",
  },
  {
    value: "Вануату",
    label: "Вануату",
  },
  {
    value: "Венесуэла",
    label: "Венесуэла",
  },
  {
    value: "Вьетнам",
    label: "Вьетнам",
  },
  {
    value: "Виргинские о- ва(Великобритания)",
    label: "Виргинские о- ва(Великобритания)",
  },
  { value: "Виргинские о- ва(США)", label: "Виргинские о- ва(США)" },
  {
    value: "Уоллис и Футуна",
    label: "Уоллис и Футуна",
  },
  {
    value: "Западная Сахара",
    label: "Западная Сахара",
  },
  {
    value: "Йемен",
    label: "Йемен",
  },
  {
    value: "Замбия",
    label: "Замбия",
  },
  { value: "Зимбабве", label: "Зимбабве" },
];

export const COUNTRY_OPTIONS = [
  {
    value: "RUS",
    label: "Российская Федерация",
    dadata: "Россия"
  },
  {
      value: "AFG",
      label: "Афганистан"
  },
  {
      value: "ALA",
      label: "Аландские острова"
  },
  {
      value: "ALB",
      label: "Албания"
  },
  {
      value: "DZA",
      label: "Алжир"
  },
  {
      value: "ASM",
      label: "американское Самоа"
  },
  {
      value: "AND",
      label: "Андорра"
  },
  {
      value: "AGO",
      label: "Ангола"
  },
  {
      value: "AIA",
      label: "Ангилья"
  },
  {
      value: "ATA",
      label: "Антарктида"
  },
  {
      value: "ATG",
      label: "Антигуа и Барбуда"
  },
  {
      value: "ARG",
      label: "Аргентина"
  },
  {
      value: "ARM",
      label: "Армения"
  },
  {
      value: "ABW",
      label: "Аруба"
  },
  {
      value: "AUS",
      label: "Австралия"
  },
  {
      value: "AUT",
      label: "Австрия"
  },
  {
      value: "AZE",
      label: "Азербайджан"
  },
  {
      value: "BHS",
      label: "Багамы"
  },
  {
      value: "BHR",
      label: "Бахрейн"
  },
  {
      value: "BGD",
      label: "Бангладеш"
  },
  {
      value: "BRB",
      label: "Барбадос"
  },
  {
      value: "BLR",
      label: "Беларусь"
  },
  {
      value: "BEL",
      label: "Бельгия"
  },
  {
      value: "BLZ",
      label: "Белиз"
  },
  {
      value: "BEN",
      label: "Бенин"
  },
  {
      value: "BMU",
      label: "Бермуды"
  },
  {
      value: "BTN",
      label: "Бутан"
  },
  {
      value: "BOL",
      label: "Боливия"
  },
  {
      value: "BES",
      label: "Бонэйр, Синт-Эстатиус и Саба"
  },
  {
      value: "BIH",
      label: "Босния и Герцеговина"
  },
  {
      value: "BWA",
      label: "Ботсвана"
  },
  {
      value: "BVT",
      label: "Остров Буве"
  },
  {
      value: "BRA",
      label: "Бразилия"
  },
  {
      value: "IOT",
      label: "Британская территория Индийского океана"
  },
  {
      value: "BRN",
      label: "Бруней-Даруссалам"
  },
  {
      value: "BGR",
      label: "Болгария"
  },
  {
      value: "BFA",
      label: "Буркина-Фасо"
  },
  {
      value: "BDI",
      label: "Бурунди"
  },
  {
      value: "KHM",
      label: "Камбоджа"
  },
  {
      value: "CMR",
      label: "Камерун"
  },
  {
      value: "CAN",
      label: "Канада"
  },
  {
      value: "CPV",
      label: "Кабо-Верде"
  },
  {
      value: "CYM",
      label: "Каймановы острова"
  },
  {
      value: "CAF",
      label: "Центрально-Африканская Республика"
  },
  {
      value: "TCD",
      label: "Чад"
  },
  {
      value: "CHL",
      label: "Чили"
  },
  {
      value: "CHN",
      label: "Китай"
  },
  {
      value: "CXR",
      label: "Остров Рождества"
  },
  {
      value: "CCK",
      label: "Кокосовые (Килинг) острова"
  },
  {
      value: "COL",
      label: "Колумбия"
  },
  {
      value: "COM",
      label: "Коморские острова"
  },
  {
      value: "COG",
      label: "Конго"
  },
  {
      value: "COD",
      label: "Конго, Демократическая Республика Конго"
  },
  {
      value: "COK",
      label: "Острова Кука"
  },
  {
      value: "CRI",
      label: "Коста-Рика"
  },
  {
      value: "CIV",
      label: "Берег Слоновой Кости"
  },
  {
      value: "HRV",
      label: "Хорватия"
  },
  {
      value: "CUB",
      label: "Куба"
  },
  {
      value: "CUW",
      label: "Кюрасао"
  },
  {
      value: "CYP",
      label: "Кипр"
  },
  {
      value: "CZE",
      label: "Чехия"
  },
  {
      value: "DNK",
      label: "Дания"
  },
  {
      value: "DJI",
      label: "Джибути"
  },
  {
      value: "DMA",
      label: "Доминика"
  },
  {
      value: "DOM",
      label: "Доминиканская Республика"
  },
  {
      value: "ECU",
      label: "Эквадор"
  },
  {
      value: "EGY",
      label: "Египет"
  },
  {
      value: "SLV",
      label: "Эль Сальвадор"
  },
  {
      value: "GNQ",
      label: "Экваториальная Гвинея"
  },
  {
      value: "ERI",
      label: "Эритрея"
  },
  {
      value: "EST",
      label: "Эстония"
  },
  {
      value: "ETH",
      label: "Эфиопия"
  },
  {
      value: "FLK",
      label: "Фолклендские (Мальвинские) острова"
  },
  {
      value: "FRO",
      label: "Фарерские острова"
  },
  {
      value: "FJI",
      label: "Фиджи"
  },
  {
      value: "FIN",
      label: "Финляндия"
  },
  {
      value: "FRA",
      label: "Франция"
  },
  {
      value: "GUF",
      label: "Французская Гвиана"
  },
  {
      value: "PYF",
      label: "Французская Полинезия"
  },
  {
      value: "ATF",
      label: "Южные Французские Территории"
  },
  {
      value: "GAB",
      label: "Габон"
  },
  {
      value: "GMB",
      label: "Гамбия"
  },
  {
      value: "GEO",
      label: "Грузия"
  },
  {
      value: "DEU",
      label: "Германия"
  },
  {
      value: "GHA",
      label: "Гана"
  },
  {
      value: "GIB",
      label: "Гибралтар"
  },
  {
      value: "GRC",
      label: "Греция"
  },
  {
      value: "GRL",
      label: "Гренландия"
  },
  {
      value: "GRD",
      label: "Гренада"
  },
  {
      value: "GLP",
      label: "Гваделупа"
  },
  {
      value: "GUM",
      label: "Гуам"
  },
  {
      value: "GTM",
      label: "Гватемала"
  },
  {
      value: "GGY",
      label: "Гернси"
  },
  {
      value: "GIN",
      label: "Гвинея"
  },
  {
      value: "GNB",
      label: "Гвинея-Бисау"
  },
  {
      value: "GUY",
      label: "Гайана"
  },
  {
      value: "HTI",
      label: "Гаити"
  },
  {
      value: "HMD",
      label: "Остров Херд и острова Макдональд"
  },
  {
      value: "VAT",
      label: "Святой Престол (государство-город Ватикан)"
  },
  {
      value: "HND",
      label: "Гондурас"
  },
  {
      value: "HKG",
      label: "Гонконг"
  },
  {
      value: "HUN",
      label: "Венгрия"
  },
  {
      value: "ISL",
      label: "Исландия"
  },
  {
      value: "IND",
      label: "Индия"
  },
  {
      value: "IDN",
      label: "Индонезия"
  },
  {
      value: "IRN",
      label: "Иран, Исламская Республика"
  },
  {
      value: "IRQ",
      label: "Ирак"
  },
  {
      value: "IRL",
      label: "Ирландия"
  },
  {
      value: "IMN",
      label: "Остров Мэн"
  },
  {
      value: "ISR",
      label: "Израиль"
  },
  {
      value: "ITA",
      label: "Италия"
  },
  {
      value: "JAM",
      label: "Ямайка"
  },
  {
      value: "JPN",
      label: "Япония"
  },
  {
      value: "JEY",
      label: "Джерси"
  },
  {
      value: "JOR",
      label: "Иордания"
  },
  {
      value: "KAZ",
      label: "Казахстан"
  },
  {
      value: "KEN",
      label: "Кения"
  },
  {
      value: "KIR",
      label: "Кирибати"
  },
  {
      value: "PRK",
      label: "Корея, Народно-Демократическая Республика"
  },
  {
      value: "KOR",
      label: "Корея, Республика"
  },
  {
      value: "XKX",
      label: "Косово"
  },
  {
      value: "KWT",
      label: "Кувейт"
  },
  {
      value: "KGZ",
      label: "Кыргызстан"
  },
  {
      value: "LAO",
      label: "Лаосская Народно-Демократическая Республика"
  },
  {
      value: "LVA",
      label: "Латвия"
  },
  {
      value: "LBN",
      label: "Ливан"
  },
  {
      value: "LSO",
      label: "Лесото"
  },
  {
      value: "LBR",
      label: "Либерия"
  },
  {
      value: "LBY",
      label: "Ливийская арабская джамахирия"
  },
  {
      value: "LIE",
      label: "Лихтенштейн"
  },
  {
      value: "LTU",
      label: "Литва"
  },
  {
      value: "LUX",
      label: "Люксембург"
  },
  {
      value: "MAC",
      label: "Макао"
  },
  {
      value: "MKD",
      label: "Македония, бывшая югославская Республика"
  },
  {
      value: "MDG",
      label: "Мадагаскар"
  },
  {
      value: "MWI",
      label: "Малави"
  },
  {
      value: "MYS",
      label: "Малайзия"
  },
  {
      value: "MDV",
      label: "Мальдивы"
  },
  {
      value: "MLI",
      label: "Мали"
  },
  {
      value: "MLT",
      label: "Мальта"
  },
  {
      value: "MHL",
      label: "Маршалловы острова"
  },
  {
      value: "MTQ",
      label: "Мартиника"
  },
  {
      value: "MRT",
      label: "Мавритания"
  },
  {
      value: "MUS",
      label: "Маврикий"
  },
  {
      value: "MYT",
      label: "Майотта"
  },
  {
      value: "MEX",
      label: "Мексика"
  },
  {
      value: "FSM",
      label: "Микронезия, Федеративные Штаты"
  },
  {
      value: "MDA",
      label: "Молдова, Республика"
  },
  {
      value: "MCO",
      label: "Монако"
  },
  {
      value: "MNG",
      label: "Монголия"
  },
  {
      value: "MNE",
      label: "Черногория"
  },
  {
      value: "MSR",
      label: "Монтсеррат"
  },
  {
      value: "MAR",
      label: "Марокко"
  },
  {
      value: "MOZ",
      label: "Мозамбик"
  },
  {
      value: "MMR",
      label: "Мьянма"
  },
  {
      value: "NAM",
      label: "Намибия"
  },
  {
      value: "NRU",
      label: "Науру"
  },
  {
      value: "NPL",
      label: "Непал"
  },
  {
      value: "NLD",
      label: "Нидерланды"
  },
  {
      value: "ANT",
      label: "Нидерландские Антильские острова"
  },
  {
      value: "NCL",
      label: "Новая Каледония"
  },
  {
      value: "NZL",
      label: "Новая Зеландия"
  },
  {
      value: "NIC",
      label: "Никарагуа"
  },
  {
      value: "NER",
      label: "Нигер"
  },
  {
      value: "NGA",
      label: "Нигерия"
  },
  {
      value: "NIU",
      label: "Ниуэ"
  },
  {
      value: "NFK",
      label: "Остров Норфолк"
  },
  {
      value: "MNP",
      label: "Северные Марианские острова"
  },
  {
      value: "NOR",
      label: "Норвегия"
  },
  {
      value: "OMN",
      label: "Оман"
  },
  {
      value: "PAK",
      label: "Пакистан"
  },
  {
      value: "PLW",
      label: "Палау"
  },
  {
      value: "PSE",
      label: "Палестинская территория, оккупированная"
  },
  {
      value: "PAN",
      label: "Панама"
  },
  {
      value: "PNG",
      label: "Папуа - Новая Гвинея"
  },
  {
      value: "PRY",
      label: "Парагвай"
  },
  {
      value: "PER",
      label: "Перу"
  },
  {
      value: "PHL",
      label: "Филиппины"
  },
  {
      value: "PCN",
      label: "Питкэрн"
  },
  {
      value: "POL",
      label: "Польша"
  },
  {
      value: "PRT",
      label: "Португалия"
  },
  {
      value: "PRI",
      label: "Пуэрто-Рико"
  },
  {
      value: "QAT",
      label: "Катар"
  },
  {
      value: "REU",
      label: "Воссоединение"
  },
  {
      value: "ROM",
      label: "Румыния"
  },
  {
      value: "RWA",
      label: "Руанда"
  },
  {
      value: "BLM",
      label: "Сен-Бартелеми"
  },
  {
      value: "SHN",
      label: "Святой Елены"
  },
  {
      value: "KNA",
      label: "Сент-Китс и Невис"
  },
  {
      value: "LCA",
      label: "Сент-Люсия"
  },
  {
      value: "MAF",
      label: "Сен-Мартен"
  },
  {
      value: "SPM",
      label: "Сен-Пьер и Микелон"
  },
  {
      value: "VCT",
      label: "Святой Винсент и Гренадины"
  },
  {
      value: "WSM",
      label: "Самоа"
  },
  {
      value: "SMR",
      label: "Сан-Марино"
  },
  {
      value: "STP",
      label: "Сан-Томе и Принсипи"
  },
  {
      value: "SAU",
      label: "Саудовская Аравия"
  },
  {
      value: "SEN",
      label: "Сенегал"
  },
  {
      value: "SRB",
      label: "Сербия"
  },
  {
      value: "SCG",
      label: "Сербия и Черногория"
  },
  {
      value: "SYC",
      label: "Сейшельские острова"
  },
  {
      value: "SLE",
      label: "Сьерра-Леоне"
  },
  {
      value: "SGP",
      label: "Сингапур"
  },
  {
      value: "SXM",
      label: "Сен Мартен"
  },
  {
      value: "SVK",
      label: "Словакия"
  },
  {
      value: "SVN",
      label: "Словения"
  },
  {
      value: "SLB",
      label: "Соломоновы острова"
  },
  {
      value: "SOM",
      label: "Сомали"
  },
  {
      value: "ZAF",
      label: "Южная Африка"
  },
  {
      value: "SGS",
      label: "Южная Георгия и Южные Сандвичевы острова"
  },
  {
      value: "SSD",
      label: "южный Судан"
  },
  {
      value: "ESP",
      label: "Испания"
  },
  {
      value: "LKA",
      label: "Шри-Ланка"
  },
  {
      value: "SDN",
      label: "Судан"
  },
  {
      value: "SUR",
      label: "Суринам"
  },
  {
      value: "SJM",
      label: "Шпицберген и Ян Майен"
  },
  {
      value: "SWZ",
      label: "Свазиленд"
  },
  {
      value: "SWE",
      label: "Швеция"
  },
  {
      value: "CHE",
      label: "Швейцария"
  },
  {
      value: "SYR",
      label: "Сирийская Арабская Республика"
  },
  {
      value: "TWN",
      label: "Тайвань, провинция Китая"
  },
  {
      value: "TJK",
      label: "Таджикистан"
  },
  {
      value: "TZA",
      label: "Танзания, Объединенная Республика"
  },
  {
      value: "THA",
      label: "Таиланд"
  },
  {
      value: "TLS",
      label: "Тимор-Лешти"
  },
  {
      value: "TGO",
      label: "Идти"
  },
  {
      value: "TKL",
      label: "Токелау"
  },
  {
      value: "TON",
      label: "Тонга"
  },
  {
      value: "TTO",
      label: "Тринидад и Тобаго"
  },
  {
      value: "TUN",
      label: "Тунис"
  },
  {
      value: "TUR",
      label: "индюк"
  },
  {
      value: "TKM",
      label: "Туркменистан"
  },
  {
      value: "TCA",
      label: "Острова Теркс и Кайкос"
  },
  {
      value: "TUV",
      label: "Тувалу"
  },
  {
      value: "UGA",
      label: "Уганда"
  },
  {
      value: "UKR",
      label: "Украина"
  },
  {
      value: "ARE",
      label: "Объединенные Арабские Эмираты"
  },
  {
      value: "GBR",
      label: "объединенное Королевство"
  },
  {
      value: "USA",
      label: "Соединенные Штаты"
  },
  {
      value: "UMI",
      label: "Внешние малые острова США"
  },
  {
      value: "URY",
      label: "Уругвай"
  },
  {
      value: "UZB",
      label: "Узбекистан"
  },
  {
      value: "VUT",
      label: "Вануату"
  },
  {
      value: "VEN",
      label: "Венесуэла"
  },
  {
      value: "VNM",
      label: "Вьетнам"
  },
  {
      value: "VGB",
      label: "Виргинские острова, Британские"
  },
  {
      value: "VIR",
      label: "Виргинские острова, США"
  },
  {
      value: "WLF",
      label: "Уоллис и Футуна"
  },
  {
      value: "ESH",
      label: "Западная Сахара"
  },
  {
      value: "YEM",
      label: "Йемен"
  },
  {
      value: "ZMB",
      label: "Замбия"
  },
  {
      value: "ZWE",
      label: "Зимбабве"
  }
];