import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { useTranslation } from 'react-i18next';
import GradientPaper from 'components/elements/gradient-paper';
import DashedPaper from 'components/elements/dashed-paper';
import StepNumber from 'components/elements/step-number';
import { LockerIcon, SurveyConnectionAgreementIcon, ArrowRightIcon } from "components/elements/icons";
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

const StyledButton = styled(Button)({
    backgroundColor: '#ffffff',
    padding: '14px 24px',
    /* gap: '16px', */
    boxShadow: '0px 8px 16px rgba(46, 123, 192, 0.5)',
    borderRadius: '90px',
    color: '#4BAFF3',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#4BAFF3',
      backgroundColor: '#ffffff',
    },
    ' > * + *': { 
      marginLeft: '16px'
    }
});

const SurveyConnectionAgreement = ({className, status}) => {
  const { t } = useTranslation();
  return (
    <div className={cls('flex', 'gap16')}>
    <StepNumber number='2' active={status > 1} finished={status === 3 || status === 7 || status === 8} className={cls(className)} />
    {
      status > 1 ? <GradientPaper className={cls(className, 'flex', classes['container'])}>
        <SurveyConnectionAgreementIcon height='88' width="87"/>
        <div className={cls(classes['survey-connection-agreement'])}>
          <div className={cls(classes['survey-connection-agreement__text-block'])}>
            <div className={cls(classes['survey-connection-agreement__title'])}>
              {t('main:survey-connection-agreement-title')}
            </div>
            <div className={cls(classes['survey-connection-agreement__hint'])}>
              {t('main:survey-connection-agreement-hint')}
            </div>             
          </div>
          {
            <StyledButton className={cls(classes['button'])} component={Link} to="/development"><span>{t('main:pass')}</span><ArrowRightIcon height="16"/></StyledButton>
          }
        </div>     
      </GradientPaper>:
      <DashedPaper className={cls(className)}>
        <LockerIcon height='32' className={cls(classes['survey-connection-agreement__locker'])} /><div className={cls(classes['survey-connection-agreement__title_passive'])}>{t('main:survey-connection-agreement-title')}</div>
      </DashedPaper>
    }
    </div>  
  );
}

export default SurveyConnectionAgreement
