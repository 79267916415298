import React from "react";
import cls from "classnames";
import { SurveyTopIcon } from "components/elements/icons";
import classes from "./styles.module.scss";
import { useTranslation } from 'react-i18next';

const Element = ({ number }) => {
  const { t } = useTranslation();
  return (
    <div className={cls(classes['order-number'])}>
      <SurveyTopIcon height='17' className={cls(classes['order-number__icon'])}/>
      <span className={cls(classes['order-number__span'])}>{t('main:order')}</span>
      {number}
    </div>
  );
}

export default Element
