import React from 'react';
import { TextField as MuiTextField } from '@mui/material';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormFieldGrid from './form-field-grid';
import { validationError } from './validation-error';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { styled } from '@mui/material/styles';
import { styles } from './styles';
import { footnoteTranslate } from './utils';
import { isMultiLined } from 'ddf/utils/is-multilined';

const CustomTextField = ({defaultValue, ...props}) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    inputProps,
    FormFieldGridProps,
    footnote,
    inputLabelProps = {},
    ...rest
  } = useFieldApi(props);

  const invalid = validationError(meta, validateOnMount);
  const formOptions = useFormApi();

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <MuiTextField
        {...input}
        variant='filled'
        multiline={isMultiLined(input.name) ? true : false}
        fullWidth
        error={!!invalid}
        helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
        disabled={formOptions.getState().submitting || isDisabled}
        label={label}
        placeholder={placeholder}
        required={isRequired}
        inputProps={{
          readOnly: isReadOnly, 
          ...inputProps
        }}
        {...rest}
        InputLabelProps={inputLabelProps}
      />
      {footnote && footnoteTranslate(footnote)}
    </FormFieldGrid>
  );
};

const TextField = styled(CustomTextField)(() => (styles));

export default TextField;
