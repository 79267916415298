import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

const Element = ({ children, className }) => {
  return (
    <div className={cls(classes['gradient-paper'], className)}>
      {children}
    </div>
  );
}

export default Element