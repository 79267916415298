import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import SurveyPageVew from 'components/views/main/survey';
import * as Queries from 'queries/authorization';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { prepareDataForDBSave, prepareDataFromDB } from "components/utils/prepareData";
import downloadFile from "js-file-download";
import * as DBApi from 'queries/requests'

const getRequests = {
  'SP': [
    null,
    Queries.useGetIpFirstQuestionnaireStep,
    Queries.useGetIpSecondQuestionnaireStep,
    Queries.useGetIpThirdQuestionnaireStep,
    Queries.useGetAllFourthQuestionnaireStep,
    Queries.useGetAllFifthQuestionnaireStep,
    Queries.useGetAllSixthQuestionnaireStep,
  ],
  'LLC': [
    null,
    Queries.useGetYuLFirstQuestionnaireStep,
    Queries.useGetYuLSecondQuestionnaireStep,
    Queries.useGetYuLThirdQuestionnaireStep,
    Queries.useGetAllFourthQuestionnaireStep,
    Queries.useGetAllFifthQuestionnaireStep,
    Queries.useGetAllSixthQuestionnaireStep,
  ]
};

const postRequests = {
  'SP': [
    null,
    Queries.usePostIpFirstQuestionnaireStep,
    Queries.usePostIpSecondQuestionnaireStep,
    Queries.usePostIpThirdQuestionnaireStep,
    Queries.usePostAllFourthQuestionnaireStep,
    Queries.usePostAllFifthQuestionnaireStep,
    Queries.usePostAllSixthQuestionnaireStep,
  ],
  'LLC': [
    null,
    Queries.usePostYuLFirstQuestionnaireStep,
    Queries.usePostYuLSecondQuestionnaireStep,
    Queries.usePostYuLThirdQuestionnaireStep,
    Queries.usePostAllFourthQuestionnaireStep,
    Queries.usePostAllFifthQuestionnaireStep,
    Queries.usePostAllSixthQuestionnaireStep,
  ]
};

const computeStepNumber = (customerState, step = null) => {
  
  if (!customerState.QuestionnaireSteps.FirstQuestionnaireStep) {
    return 1;
  }
  if (!customerState.QuestionnaireSteps.SecondQuestionnaireStep) {
    return 2;
  }
  if (!customerState.QuestionnaireSteps.ThirdQuestionnaireStep) {
    return 3;
  }
  if (!customerState.QuestionnaireSteps.FourthQuestionnaireStep) {
    return 4;
  }
  if (!customerState.QuestionnaireSteps.FifthQuestionnaireStep) {
    return 5;
  }
  if (!customerState.QuestionnaireSteps.SixthQuestionnaireStep) {
    return 6;
  }
  return step||6;
}

const SurveyPage = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  let params = useParams();
  const [ customerState, setCustomerState ] = useOutletContext();
  const { data: countries, isLoading: isCountriesLoading } = Queries.useGetCountries({staleTime: Infinity});
  const [ step, setStep ] = useState(params.step ? parseInt(params.step.replace(/\D/g,'')):computeStepNumber(customerState));
  const [ data, setData] = useState(null);
  const [ step6Data, setStep6Data] = useState({});
  const [ redirect, setRedirect] = useState(0);
  const [ isOpenStep6Modal, setIsOpenStep6Modal ] = useState(false);
  const [ isOpenStepSavedModal, setIsOpenStepSavedModal ] = useState(false);
  const { mutate: get, isLoading: isQueryLoading } = getRequests[customerState.CustomerType === 1 ? "SP":"LLC"][step]();
  const { mutate: post, isLoading: isMutationLoading } = postRequests[customerState.CustomerType === 1 ? "SP":"LLC"][step]();
  const { isLoading: isSaveFileLoading } = Queries.useSaveCustomerFile();
  const { isLoading: isGetFileLoading } = Queries.useGetCustomerFile();
  const { data: opfs, isLoading: isGetOPFsLoading } = Queries.useGetOPFs({staleTime: Infinity});

  useEffect(()=>{
    setStep(params.step ? parseInt(params.step.replace(/\D/g,'')):computeStepNumber(customerState));
    // eslint-disable-next-line
  }, [params])

  const saveStep = (values, isMessageOnStepComplete) => {
    const requestData = Object.assign({}, {...values});
    setData(values);
    return new Promise((resolve, reject)=>post(prepareDataForDBSave(requestData), {
      onSuccess: (data) => {
        if(data.Success) {
          setCustomerState(data.CustomerState);
          if (isMessageOnStepComplete) {
            setIsOpenStepSavedModal(true);
          }
          resolve();
        } else {
          resolve([t(`errors:${data.ErrorCode}`)])
        }   
      },
      onError: (error) => {
        resolve(error.code)
      }
    }))
  }

  const saveStep6 = (values) => {
    return new Promise((resolve, reject)=>post({bankId: values.BankId, "formFile": values && values['SignedStatement2']['inputFiles'][0]}, {
      onSuccess: (data) => {
        if(data.Success) {
          setCustomerState(data.CustomerState)
          setIsOpenStep6Modal(true);
          resolve();
        } else {
          resolve([t(`errors:${data.ErrorCode}`)])
        }   
      },
      onError: (error) => {
        resolve(error.code)
      }
    }))
  }

  const handleDownloadFileFromStep6 = (bankId) => {
    return new Promise((resolve, reject)=>get({ onlyFileName: false, bankId: bankId }, {
      onSuccess: (data) => {
        downloadFile(data, t(`banks:items.${bankId}.download_name`));
        resolve();
      },
      onError: (error) => {
        resolve(error.code)
      }
    }))  
  }

/*   const handleDownloadFile = () => {
    return new Promise((resolve, reject)=>getFile({ fieldName: 'SignedStatement' }, {
      onSuccess: (data) => {
        downloadFile(data, "Заявление.pdf");

        resolve()
      },
      onError: (error) => {
        resolve(error.code)
      }
    }))  
  } */

  const handleUploadFile = (files) => {
    let requests = files.map((data)=>DBApi.auth.saveCustomerFile(data));
    return Promise.all(requests);
  }
  
  const handlePreSave = (values) => {
    console.log(values)
    return doSave(values, true);
  }

  const handleSubmit = (values) => {
    handleSentYM(step)
    values['IsNeedCheckRequiredFields'] = true;
    return doSave(values);
  } 

  const doSave = (values, isMessageOnStepComplete = false) => {
    if ((values.hasOwnProperty('FileLicense2') && values.FileLicense2 && values.FileLicense2.inputFiles && values.FileLicense2.inputFiles[0].size > 0) || 
    (values.hasOwnProperty('FileAttorney2') && values.FileAttorney2 && values.FileAttorney2.inputFiles && values.FileAttorney2.inputFiles[0].size > 0) ||
    (values.hasOwnProperty('FilePassportScan2') && values.FilePassportScan2 && values.FilePassportScan2.inputFiles && values.FilePassportScan2.inputFiles[0].size > 0) ||
    (values.hasOwnProperty('FilePassportScanExtra2') && values.FilePassportScanExtra2 && values.FilePassportScanExtra2.inputFiles && values.FilePassportScanExtra2.inputFiles[0].size > 0) ||
    (values.hasOwnProperty('FilePassportScanAttorney2') && values.FilePassportScanAttorney2 && values.FilePassportScanAttorney2.inputFiles && values.FilePassportScanAttorney2.inputFiles[0].size > 0) ||
    (values.hasOwnProperty('FilePassportScanAttorneyExtra2') && values.FilePassportScanAttorneyExtra2 && values.FilePassportScanAttorneyExtra2.inputFiles && values.FilePassportScanAttorneyExtra2.inputFiles[0].size > 0) ||
    (values.hasOwnProperty('FilePassportScanExecutive2') && values.FilePassportScanExecutive2 && values.FilePassportScanExecutive2.inputFiles && values.FilePassportScanExecutive2.inputFiles[0].size > 0) ||
    (values.hasOwnProperty('FilePassportScanExecutiveExtra2') && values.FilePassportScanExecutiveExtra2 && values.FilePassportScanExecutiveExtra2.inputFiles && values.FilePassportScanExecutiveExtra2.inputFiles[0].size > 0)
    ) {
      let files = [];

      let fileFieldNames = ['FileLicense', 'FileAttorney', 
                            'FilePassportScan', 'FilePassportScanExtra', 
                            'FilePassportScanAttorney', 'FilePassportScanAttorneyExtra', 
                            'FilePassportScanExecutive', 'FilePassportScanExecutiveExtra'];

      files = fileFieldNames.map((fieldName)=>{
        if (values.hasOwnProperty(`${fieldName}2`) && values[`${fieldName}2`] && values[`${fieldName}2`]['inputFiles'] && values[`${fieldName}2`]['inputFiles'][0].size > 0) {
          return {"fieldName": fieldName, "formFile": values[`${fieldName}2`]['inputFiles'][0]}
        }
        return null;
      }).filter(function( element ) {
        return element !== null;
      });

      return handleUploadFile(files).then((responses) => {
        let flag = true;
        let errorCode = '';

        responses.forEach((i)=>{
          if (!i.Success) {
            flag = false;
            errorCode = i.ErrorCode;
          } 
        })

        return flag ? commonSaveStep(values, isMessageOnStepComplete):[t(`errors:${errorCode}`)];
      }).catch(error => {
        return error
      });
    } else if (values.hasOwnProperty('SignedStatement')) {
      if (values.hasOwnProperty('SignedStatement2') && values.SignedStatement2 && values.SignedStatement2.inputFiles && values.SignedStatement2.inputFiles[0].size > 0) {
        return saveStep6(values)
      } else {
        return saveStep6(null)
      }
    } else {
        return commonSaveStep(values, isMessageOnStepComplete)
    }
  }
  
  const handleSentYM = (step) => {
    switch (step) {
      case 1:
        window.ym && window.ym(92846897,'reachGoal','s1')
      break;
      case 2:
        window.ym && window.ym(92846897,'reachGoal','s2')
      break;
      case 3:
        window.ym && window.ym(92846897,'reachGoal','s3')
      break;
      case 4:
        window.ym && window.ym(92846897,'reachGoal','s4')
      break;
      case 5:
        window.ym && window.ym(92846897,'reachGoal','s5')
      break;
      case 6:
        window.ym && window.ym(92846897,'reachGoal','s6')
      break;
      default:
        console.log('YM sent step error ' + step)
    }
  }

  const commonSaveStep = (values, isMessageOnStepComplete) => {
    return isMessageOnStepComplete ? saveStep(values, isMessageOnStepComplete):saveStep(values, isMessageOnStepComplete).then(()=>{
      setRedirect(step + 1);
    })   
  }

  useEffect(()=>{
    if (redirect) {
      navigate(`/survey/s${computeStepNumber(customerState, redirect)}`)
    }
    // eslint-disable-next-line
  }, [redirect])

  const getStepData = (bankId = null) => {
    return new Promise((resolve, reject)=>get(step === 6 ? {onlyFileName: true, bankId: bankId}:null, {
      onSuccess: (data) => {
        if(data.Success) {
          if (step !== 6) {
            setData(prepareDataFromDB(data?.Data, step, customerState.CustomerType));
          } else {
            setStep6Data({ ...step6Data, [bankId]: prepareDataFromDB(data?.Data, step, customerState.CustomerType )});
          }
          resolve()
        } else {
          resolve([t(`errors:${data?.ErrorCode}`)])
        }
      },
      onError: (error) => {
        resolve(error.code)
      }
    }))     
  }

  useLayoutEffect(()=>{
    setData(null);
  }, [step])

  useEffect(()=>{
    if (step && step !== 6) {
      setRedirect(null);
      getStepData();
    }
    // eslint-disable-next-line
  }, [step])

  const handleGetStep6 = (bankId)=>{
    if (step === 6) {
      setRedirect(null);
      getStepData(bankId);
    }
  }

  return  <SurveyPageVew 
            step={step} 
            customerState={customerState} 
            initialData={data} 
            step6InitialData={step6Data}
            onSubmit={handleSubmit} 
            onCancel={handlePreSave} 
            loading={isQueryLoading||isMutationLoading||isGetFileLoading||isSaveFileLoading||isGetOPFsLoading||isCountriesLoading}
            isOpenStep6Modal={isOpenStep6Modal}
            isOpenStepSavedModal={isOpenStepSavedModal}
            onSetIsOpenStepSavedModal={setIsOpenStepSavedModal}
            onDownloadStep6Callback={handleDownloadFileFromStep6}
            onGetStep6Callback={handleGetStep6}
            isDownloadingStep6={isQueryLoading}
            opfs={opfs}
            countries={countries}
          /> 
}

export default SurveyPage;
