import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import * as DBApi from 'queries/requests'

export function useGetCustomerState() {
  const queryCache = useQueryClient()
  return useMutation({ mutationFn: DBApi.auth.getCustomerState,
    onSuccess: (data) => {
      queryCache.setQueryData(['Authorized'], data.Success);
      queryCache.setQueryData(['CustomerState'], data.CustomerState);
    },
    onError: () => {
      console.log('get session ERROR')
      queryCache.setQueryData(['Authorized'], false)
    }
  });
}

export function usePostAuthorizeCustomer() {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.postAuthorizeCustomer, {
    onSuccess: (data) => {
      queryCache.setQueryData(['Authorized'], data.Success);
    },
    onError: () => {

      console.log('Login ERROR')
      queryCache.setQueryData(['Authorized'], false)
    }
  });
}

export function usePostRegistrationCustomer() {
  return useMutation(DBApi.auth.postRegistrationCustomer, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('registration ERROR')
    }
  });
}

export function useGetBets() {
  return useQuery(["bets"], DBApi.auth.getBets)
}

export function useGetOPFs(options) {
  return useQuery(["opfs"], DBApi.auth.getOPFs, options)
}

export function useGetCountries(options) {
  return useQuery(["countries"], DBApi.auth.getCountries, options)
}

export function useGetPaytureTerminals(data) {
  return useMutation(DBApi.auth.getPaytureTerminals, {
    onSuccess: (data) => {
      
    },
    onError: () => {

    }
  });
}

export function useGetManagerComments() {
  return useQuery(["comments"], DBApi.auth.getManagerComments, {staleTime : 1000})
}

export function usePostLogoutCustomer () {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.postLogoutCustomer, {
    onSuccess: (data) => {
      queryCache.setQueryData(['Authorized'], false);
    },
    onError: () => {

    }
  });
}

export function usePostSetPasswordCustomer() {
  return useMutation(DBApi.auth.postSetPasswordCustomer, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('registration ERROR')
    }
  });
}

export function usePostRecoveryPasswordCustomer() {
  return useMutation(DBApi.auth.postRecoveryPasswordCustomer, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('registration ERROR')
    }
  });
}

export function useGetIpFirstQuestionnaireStep () {
  const queryCache = useQueryClient();
  return useMutation({ mutationFn: DBApi.auth.getIpFirstQuestionnaireStep,
    onSuccess: (data) => {
      if (data.Success) {
        queryCache.setQueryData(['Step1'], data.Data);
        queryCache.setQueryData(['CustomerState'], data.CustomerState);        
      }
    },
    onError: () => {

      console.log('')
    }
  });
}
export function useGetIpSecondQuestionnaireStep () {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.getIpSecondQuestionnaireStep, {
    onSuccess: (data) => {
      if (data.Success) {
        queryCache.setQueryData(['Step2'], data.Data);
        queryCache.setQueryData(['CustomerState'], data.CustomerState);        
      }
    },
    onError: () => {

      console.log('')
    }
  });
}
export function useGetIpThirdQuestionnaireStep () {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.getIpThirdQuestionnaireStep, {
    onSuccess: (data) => {
      if (data.Success) {
        queryCache.setQueryData(['Step3'], data.Data);
        queryCache.setQueryData(['CustomerState'], data.CustomerState);       
      }
    },
    onError: () => {

      console.log('')
    }
  });
}
export function useGetYuLFirstQuestionnaireStep () {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.getYuLFirstQuestionnaireStep, {
    onSuccess: (data) => {
      if (data.Success) {
        queryCache.setQueryData(['Step1'], data.Data);
        queryCache.setQueryData(['CustomerState'], data.CustomerState);        
      }
    },
    onError: () => {

      console.log('')
    }
  });
}
export function useGetYuLSecondQuestionnaireStep () {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.getYuLSecondQuestionnaireStep, {
    onSuccess: (data) => {
      if (data.Success) {
        queryCache.setQueryData(['Step2'], data.Data);
        queryCache.setQueryData(['CustomerState'], data.CustomerState);        
      }
    },
    onError: () => {

      console.log('')
    }
  });
}
export function useGetYuLThirdQuestionnaireStep () {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.getYuLThirdQuestionnaireStep, {
    onSuccess: (data) => {
      if (data.Success) {
        queryCache.setQueryData(['Step3'], data.Data);
        queryCache.setQueryData(['CustomerState'], data.CustomerState);        
      }
    },
    onError: () => {

      console.log('')
    }
  });
}

export function useGetAllFourthQuestionnaireStep () {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.getAllFourthQuestionnaireStep, {
    onSuccess: (data) => {
      if (data.Success) {
        queryCache.setQueryData(['Step4'], data.Data);
        queryCache.setQueryData(['CustomerState'], data.CustomerState);        
      }
    },
    onError: () => {

      console.log('')
    }
  });
}
export function useGetAllFifthQuestionnaireStep () {
  const queryCache = useQueryClient();
  return useMutation(DBApi.auth.getAllFifthQuestionnaireStep, {
    onSuccess: (data) => {
      if (data.Success) {
        queryCache.setQueryData(['Step5'], data.Data);
        queryCache.setQueryData(['CustomerState'], data.CustomerState);        
      }
    },
    onError: () => {

      console.log('')
    }
  });
}
export function useGetAllSixthQuestionnaireStep () {
  return useMutation(DBApi.auth.getAllSixthQuestionnaireStep, {
    onSuccess: () => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}

export function usePostIpFirstQuestionnaireStep () {
  return useMutation(DBApi.auth.postIpFirstQuestionnaireStep, {
    onSuccess: (data) => {

    },
    onError: () => {
      console.log('')
    }
  });
}
export function usePostIpSecondQuestionnaireStep () {
  return useMutation(DBApi.auth.postIpSecondQuestionnaireStep, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}
export function usePostIpThirdQuestionnaireStep () {
  return useMutation(DBApi.auth.postIpThirdQuestionnaireStep, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}
export function usePostYuLFirstQuestionnaireStep () {
  return useMutation(DBApi.auth.postYuLFirstQuestionnaireStep, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}
export function usePostYuLSecondQuestionnaireStep () {
  return useMutation(DBApi.auth.postYuLSecondQuestionnaireStep, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}
export function usePostYuLThirdQuestionnaireStep () {
  return useMutation(DBApi.auth.postYuLThirdQuestionnaireStep, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}

export function usePostAllFourthQuestionnaireStep () {
  return useMutation(DBApi.auth.postAllFourthQuestionnaireStep, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}
export function usePostAllFifthQuestionnaireStep () {
  return useMutation(DBApi.auth.postAllFifthQuestionnaireStep, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}
export function usePostAllSixthQuestionnaireStep () {
  return useMutation(DBApi.auth.postAllSixthQuestionnaireStep, {
    onSuccess: (data) => {
      
    },
    onError: () => {

      console.log('')
    }
  });
}

export function useSaveCustomerFile () {
  return useMutation(DBApi.auth.saveCustomerFile, {
    onSuccess: (data) => {
      if (data.Success) {
      
      }
    },
    onError: () => {

      console.log('')
    }
  });
}

export function useGetCustomerFile () {
  return useMutation(DBApi.auth.getCustomerFile, {
    onSuccess: (data) => {

    },
    onError: () => {

      console.log('')
    }
  });
}