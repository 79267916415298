import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ArrowDownIcon } from 'components/elements/icons';

import WhitePage from 'components/elements/white-paper';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  '::before': {
    backgroundColor: 'transparent'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownIcon height='6px' width='12px' />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: 0,
  '& .MuiAccordionSummary-content': {
    marginBottom: '0',
    marginTop: '0',
  },    
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

const AccordionContainer = ({ index, title, children, className = '', onOpen = null, expandedOuter = null, onChange = null}) => {

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    onChange && onChange(`panel${index+1}`, newExpanded)

    setExpanded(newExpanded ? panel : false);
  
    (onOpen && newExpanded) && onOpen();
  };

  return (
    <WhitePage key={`wp-${index+1}`} className={className}>
      <Accordion expanded={expanded === `panel${index+1}`} onChange={handleChange(`panel${index+1}`)}>
        <AccordionSummary aria-controls={`panel${index+1}d-content`} id={`panel${index+1}d-header`}>
          {title}
        </AccordionSummary>
        <AccordionDetails sx={{mt: 2}}>
          {children}
        </AccordionDetails>
      </Accordion>
    </WhitePage>            
  );
}

export default AccordionContainer;
