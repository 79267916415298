import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { useTranslation } from 'react-i18next';
import GradientPaper from 'components/elements/gradient-paper';
import DashedPaper from 'components/elements/dashed-paper';
import StepNumber from 'components/elements/step-number';
import { LockerIcon, SurveyStepsIcon, ArrowRightIcon } from 'components/elements/icons';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

const StyledButton = styled(Button)({
    backgroundColor: '#ffffff',
    padding: '14px 24px',
    //gap: '16px',
    boxShadow: '0px 8px 16px rgba(46, 123, 192, 0.5)',
    borderRadius: '90px',
    color: '#4BAFF3',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#4BAFF3',
      backgroundColor: '#ffffff',
    },
    ' > * + *': { 
      marginLeft: '16px'
    }
});

const SurveySteps = ({status, className='', customerState}) => {
  const { t } = useTranslation();

  return (
      <div className={cls('flex', 'gap16')}>
        <StepNumber number='1' active={status > 0} finished={status > 1} lineClassName={'no-top-border'} className={'no-top-margin'} />
        {
          status > 0 ? <GradientPaper className={cls('flex', classes['container'])}>
            <SurveyStepsIcon />
            <div className={classes['survey-steps']}>
              <div className={cls(classes['survey-steps__top'], status !== 1 && classes['survey-steps__margin-bottom'])}>
                <div className={cls(classes['survey-steps__text-block'])}>
                  <h3 className={cls(classes['survey-steps__title'])}>{t('main:survey-steps-title')}</h3>
                  <div className={cls(classes['survey-steps__hint'])}>{t('main:survey-steps-hint')}</div>
                </div>
                {
                  status === 1 && <StyledButton className={cls(classes['button'])} component={Link} to="/survey"><span>{t('main:fill-survey')}</span><ArrowRightIcon height="16"/></StyledButton>
                }
              </div>
              <div className={cls(classes['survey-steps__list'])}>
                <Link to='/survey/s1' className={cls(classes['survey-steps__list-item'], customerState.QuestionnaireSteps.FirstQuestionnaireStep && classes['survey-steps__list-item_active'])}/>
                <Link to='/survey/s2' className={cls(classes['survey-steps__list-item'], customerState.QuestionnaireSteps.SecondQuestionnaireStep && classes['survey-steps__list-item_active'])} />
                <Link to='/survey/s3' className={cls(classes['survey-steps__list-item'], customerState.QuestionnaireSteps.ThirdQuestionnaireStep && classes['survey-steps__list-item_active'])} />
                <Link to='/survey/s4' className={cls(classes['survey-steps__list-item'], customerState.QuestionnaireSteps.FourthQuestionnaireStep && classes['survey-steps__list-item_active'])} />
                <Link to='/survey/s5' className={cls(classes['survey-steps__list-item'], customerState.QuestionnaireSteps.FifthQuestionnaireStep && classes['survey-steps__list-item_active'])} />
                {
                  (customerState.QuestionnaireSteps.FirstQuestionnaireStep &&
                  customerState.QuestionnaireSteps.SecondQuestionnaireStep &&
                  customerState.QuestionnaireSteps.ThirdQuestionnaireStep &&
                  customerState.QuestionnaireSteps.FourthQuestionnaireStep &&
                  customerState.QuestionnaireSteps.FifthQuestionnaireStep) ?
                    <Link to='/survey/s6' 
                      className={cls(classes['survey-steps__list-item'], 
                      customerState.QuestionnaireSteps.SixthQuestionnaireStep && classes['survey-steps__list-item_active'])} />
                    :
                    <span className={cls(classes['survey-steps__list-item'])}></span>
                }
              </div>
            </div>
          </GradientPaper>:
          <DashedPaper className={cls(className)}>
            <LockerIcon height='32'  className={cls(classes['survey-steps__locker'])}/><div className={cls(classes['survey-steps__title_passive'])}>{t('main:survey-connection-agreement-title')}</div>
          </DashedPaper>
        }
      </div>

  );
}

export default SurveySteps
