import React from "react";
import Logo from 'components/elements/logo';
import cls from 'classnames';
import classes from './auth.module.scss';

const AuthContainer = ({children}) => {
  return  <main className={cls(classes['main'])}>
            <div  className={cls(classes['main__auth-block'])}>
              <Logo className={cls('logo__auth')}/>
              {children}
            </div>
          </main>
}

export default AuthContainer;
