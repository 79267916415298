  import i18next from 'i18next'
  import { Link } from 'react-router-dom';

  const customActionMapper = {
    translateString: (obj) => { 
      if (typeof obj === 'string') {
        return i18next.t(obj)
      } else {
        if (obj.type === 'Link') {
          return <Link to={obj.link}>{i18next.t(obj.message)}</Link> 
        } else {
          return i18next.t(obj.message, { threshold: obj.threshold })          
        }

      }
    },
    translateValidate: (a1) => {
      let res = a1.map((i, index) => {
        return {
          ...i,
          key: index,
          message: i.hasOwnProperty("message")
            ? i18next.t(`messages:${i.message}`, { threshold: i.threshold })
            : i18next.t(`messages:${i.type}`, { threshold: i.threshold }),
        };
      });
      return res;
    },
  }

  export default customActionMapper 