import React from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import WhitePaperWithTopper from 'components/elements/white-paper-with-topper';
import { DownloadIcon } from 'components/elements/icons';
import classes from './fz54.module.scss';
import Img1 from 'assets/images/atol-img-1.jpg';
import Img2 from 'assets/images/atol-img-2.jpg';
import Img3 from 'assets/images/atol-img-3.jpg';
import { Link } from "react-router-dom";
import PdfAtol from 'assets/pdfs/atol.pdf';

const images = {
  "atol1": Img1,
  "atol2": Img2,
  "atol3": Img3
}

const FZ54ModalView = () => {
  const { t } = useTranslation('main');

  return <WhitePaperWithTopper className={cls(classes['modal'])}>
          <div className={cls(classes['fz54-modal'])}>
            <div className={cls(classes['fz54-modal__title'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.modals.atol.title')}}/>
            <div className={cls(classes['fz54-modal__description'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.modals.atol.description')}}/>
            <div className={cls(classes['fz54-modal-steps'])}>
            {
              t('fz54-page.modals.atol.steps', { returnObjects: true }).map((i, index)=>{
                return <div key={`step-${index}`} className={cls(classes['fz54-modal-steps__item'])}>
                  <div className={cls(classes['fz54-modal-steps__name'])} dangerouslySetInnerHTML={{ __html: i.name }} />
                  <div className={cls(classes['fz54-modal-steps__text'])} dangerouslySetInnerHTML={{ __html: i.text }} />
                </div>
              })
            }
            </div>
            <div className={cls(classes['fz54-modal__subtitle'])} dangerouslySetInnerHTML={{ __html: t('fz54-page.modals.atol.subtitle')}}/>
            <div className={cls(classes['fz54-modal-blocks'])}>
            {
              t('fz54-page.modals.atol.blocks', { returnObjects: true }).map((i, index)=>{
                return <div key={`block-${index}`} className={cls(classes['fz54-modal-blocks__item'])}>
                  <div className={cls(classes['fz54-modal-blocks__image'])}>
                    <img src={images[i.image]} alt=""/>
                  </div>
                  <div className={cls(classes['fz54-modal-blocks__name'])} dangerouslySetInnerHTML={{ __html: i.title }} />
                  <div className={cls(classes['fz54-modal-blocks__text'])} dangerouslySetInnerHTML={{ __html: i.text }} />
                </div>
              })
            }
            </div>
            <div className={cls(classes['fz54-modal__controls'], classes['fz54-modal__controls_full-width'])}>
              <Link className={cls('button-bordered', classes['align-center'])} to={PdfAtol} target="_blank" download><DownloadIcon height="16px" color="#A593E1" /><span>{t('fz54-page.modals.atol.button.name')}</span></Link>
            </div>
          </div>
        </WhitePaperWithTopper>       
}

export default FZ54ModalView;
