import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { MyPaymentsIcon } from 'components/elements/icons';

const Element = () => {
  return (
    <a href="https://backend.payture.com" rel="noreferrer" target="_blank" className={cls(classes['my-payments-label'])}>
      <span>Мои платежи</span> <MyPaymentsIcon height='16' width='16' />
    </a>
  );
}

export default Element
