import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

const Element = ({ text, link = '', textAlign = 'left', style = {}}) => {
  return (
    <div className={cls(classes['footnote'])} style={{...style, 'textAlign': textAlign}}>
      <span dangerouslySetInnerHTML={{ __html: text}}/> <span>{link}</span>
    </div>
  );
}

export default Element
