import React, { useState } from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { useTranslation } from 'react-i18next';
import Sheet from 'react-modal-sheet';
import SurveyStepNumber from 'components/elements/survey-step-number';
import SurveySimpleStepNumber from 'components/elements/survey-simple-step-number';
import WhitePaper from 'components/elements/white-paper';
import GradientPaper from 'components/elements/gradient-paper';
import { BurgerIcon } from 'components/elements/icons';
import SurveyStepsMobileMenu from 'components/organizms/survey-steps-mobile-menu';

const SurveyStepsList = ({ step, customerState }) => {
  const { t } = useTranslation('main');  
  const [ classNameBurger, setClassNameBurger ] = useState('');
  const [ show, setShow ] = useState(false);
  
  const finished = [
    null,
    customerState.QuestionnaireSteps?.FirstQuestionnaireStep,
    customerState.QuestionnaireSteps?.SecondQuestionnaireStep,
    customerState.QuestionnaireSteps?.ThirdQuestionnaireStep,
    customerState.QuestionnaireSteps?.FourthQuestionnaireStep,
    customerState.QuestionnaireSteps?.FifthQuestionnaireStep,
    customerState.QuestionnaireSteps?.SixthQuestionnaireStep 
  ]

  const handleBurgerClick = () => {
    setClassNameBurger(classNameBurger ? '':'active');
    setShow(show ? false:true);
  }

  return (
    <>
      {/* desktop list view */}
      <WhitePaper  className={cls(classes['steps-list__desktop'])}>
        <div className={cls(classes['steps-list__contents'])}>
          <SurveyStepNumber className={cls(classes['steps-list__flex-left'])} active={step === 1} finished={finished[1]} step='1' name={t('survey-page.steps.1.name')} />
          <SurveyStepNumber className={cls(classes['steps-list__flex-center'])} active={step === 2} finished={finished[2]} step='2' name={t('survey-page.steps.2.name')} />
          <SurveyStepNumber className={cls(classes['steps-list__flex-center'])} active={step === 3} finished={finished[3]} step='3' name={t('survey-page.steps.3.name')} />
          <SurveyStepNumber className={cls(classes['steps-list__flex-center'])} active={step === 4} finished={finished[4]} step='4' name={t('survey-page.steps.4.name')} />
          <SurveyStepNumber className={cls(classes['steps-list__flex-center'])} active={step === 5} finished={finished[5]} step='5' name={t('survey-page.steps.5.name')} />
          <SurveyStepNumber className={cls(classes['steps-list__flex-right'])} active={step === 6} disabled={!finished[1]||!finished[2]||!finished[3]||!finished[4]||!finished[5]} finished={finished[6]} step='6' name={t('survey-page.steps.6.name')} />
        </div>
        <div className={cls(classes['steps-list__hint'])}>{t('survey-page.hint')}</div>
      </WhitePaper>
      {/* mobile list view */}
      <GradientPaper className={cls(classes['steps-list__mobile'])}>
        <SurveySimpleStepNumber finished={finished[step]} name={t(`survey-page.steps.${(parseInt(step)+0)}.name`)} step={step} />
        <BurgerIcon width='32' onClick={handleBurgerClick} className={classNameBurger} color='white' />
      </GradientPaper>    
      {/* mobile bottom modal */}
      <Sheet isOpen={show} detent="content-height" onClose={() => handleBurgerClick()}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <SurveyStepsMobileMenu customerState={customerState} activeNumber={parseInt(step)} callback={handleBurgerClick}/>
            <div className={cls(classes['steps-list__hint'])}>{t('survey-page.hint')}</div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop /* onClick={() => handleBurgerClick()} */ />
      </Sheet>   
    </>
  );
}

export default SurveyStepsList
