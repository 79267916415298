import React, { useState, useEffect } from 'react';
import * as Queries from 'queries/authorization';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthContainer from 'components/views/auth/container';
import LoginView from 'components/views/auth/login';
import Captcha from 'captcha';

const LoginPageContainer = () => {  
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [ captchaToken, setCaptchaToken ] = useState(null);
  const [ captchaVisible, setCaptchaVisible ] = useState(false);
  const { mutate: login, isLoading } = Queries.usePostAuthorizeCustomer()
  const { mutate: auth } = Queries.useGetCustomerState();
  const [ data, setData ] = useState(null);

  const handleGetCaptchaToken = (token) => {
    setCaptchaToken(token);
  }

  const handleCaptchaSubmit = (data) => {
    setData(data);
    setCaptchaVisible(true);
    if (process.env.REACT_APP_CAPTCHA_DISABLED === 'true') {
      handleGetCaptchaToken('123');
    } 
  }

  useEffect(()=>{
    if (captchaToken) {
      handleLogin(data)
    }
    // eslint-disable-next-line
  }, [captchaToken])

  const showMessageOnStepComplete = (text) => {
    document.querySelector('.form-submit-error').innerHTML = text;
  }

  const handleLogin = (data) => {
    window.ym && window.ym(92846897,'reachGoal','login');
    data['captchaToken'] = JSON.parse(JSON.stringify(captchaToken));
        
    setCaptchaToken(null);
    setCaptchaVisible(false); 
    setData(null);

    return new Promise((resolve, reject)=>login(data, {
      onSuccess: (data) => {

        if(data.Success) {
          sessionStorage.setItem('SessionId', data.CookieSessionId);
          auth({'SessionId': data.CookieSessionId}, {
            onSuccess: (res) => {
              if(res.Success) {
                navigate('/');
              } else {
                setTimeout(()=>{showMessageOnStepComplete(t(`errors:${res.ErrorCode}`));},500);
                resolve([t(`errors:${res.ErrorCode}`)])
              }  
            }
          })
        } else {
          setTimeout(()=>{showMessageOnStepComplete(t(`errors:${data.ErrorCode}`));},500);
          resolve([t(`errors:${data.ErrorCode}`)])
        }   
      },
      onError: (error) => {
        resolve(error.code)
      }
    })) 
  }
  
  return (
          <AuthContainer>
            <LoginView loader={isLoading||captchaVisible||false} onSubmit={handleCaptchaSubmit} />
            {
              !isLoading && <Captcha onGetToken={handleGetCaptchaToken} visible={captchaVisible} onCaptchaHidden={()=>{setCaptchaVisible(false)}}/>
            }
          </AuthContainer>
  )
}

export default LoginPageContainer;
