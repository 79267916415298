import dayjs from "dayjs";

const substituteData = (key, data) => {
  if ((/^Has/.test(key)) && key !== 'HasAtolOrOrange') {
    data[key] = data[key] === 1 ? true:false;
  }
  if (data[key]) {
    switch (key) {
      case "Phone":
      case "PhoneAttorney":
      case "PhonePointOfSale":
      case "PhoneContact":
      case "PhoneBoss":
      case "PhoneClaims":
      case "PhoneNotifications":
      case "PhoneFinance":
      case "PhoneTechnical":
      case "PhoneOrganization":
      case "PhoneExecutive":
      case "Snils":
      case "DivisionCode":
      case "DivisionCodeAttorney": 
      case "DivisionCodeExecutive":
        data[key] = data[key].replace(/[^0-9]/g, '')
      break;
      case "FileLicense":
      case "FileAttorney":
      case 'FilePassportScan': 
      case 'FilePassportScanExtra': 
      case 'FilePassportScanAttorney':
      case 'FilePassportScanAttorneyExtra':
      case 'FilePassportScanExecutive':
      case 'FilePassportScanExecutiveExtra':
        delete data[key];
      break;
      case 'DateOfRegistration':
      case 'DateLicense':
      case 'PeriodLicense':
      case 'BirthDate':
      case 'PassportDateOfIssue':
      case 'StartDateStay':
      case 'EndDateStay':
      case 'BirthDateExecutive':
      case 'PassportDateOfIssueExecutive':
      case 'StartDateExecutive':
      case 'EndDateExecutive':
      case 'StartDate':
      case 'EndDate':
      case 'BirthDateAttorney':
      case 'PassportDateOfIssueAttorney':
      case 'StartDateAttorney':
      case 'EndDateAttorney':
        data[key] = data[key] ? dayjs(data[key]).format('YYYY-MM-DDT00:00:00'):null;
      break;
      default:
        // do nothing
    }
  }
  return data
}

export const prepareDataForDBSave = (originalData) => {
  let data = JSON.parse(JSON.stringify(originalData));
  for (var key in data) {
    data = substituteData(key, data)
    if (key === "BeneficiariesInfo" && data[key]) {
      for (var i = 0; i < data[key].length; i++) {
        for (var innerKey in data[key][i]) {
          data[key][i] = substituteData(innerKey, data[key][i])
        }
      }
    }
  }
  return data;
}

export const prepareDataFromDB = (originalData, step, type) => {
  let data = JSON.parse(JSON.stringify(originalData));
  for (var key in data) {
    if (/^Has/.test(key)) {
      data[key] = data[key] === true ? 1:2;
    }
    if ((key === "Citizenship" || key === "CitizenshipAttorney" || key === "CitizenshipExecutive") && data[key] === null) {
      data[key] = "Россия"
    }

    if (key === "BeneficiariesInfo" && data[key]) {
      for (var i = 0; i < data[key].length; i++) {
        for (var innerKey in data[key][i]) {
          
          if (/^Has/.test(innerKey)) {
            data[key][i][innerKey] = data[key][i][innerKey] === true ? 1:2;
          }
          if ((innerKey === "Citizenship" || innerKey === "CitizenshipAttorney" || innerKey === "CitizenshipExecutive") && data[key][i][innerKey] === null) {
            data[key][i][innerKey] = "Россия"
          }
        }        
      }

    }

  }
  
  return data;
}
