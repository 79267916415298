import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";
import { useTranslation } from 'react-i18next';

const Element = ({ step, className = '', withDescription = true }) => {
  const { t } = useTranslation('main'); 
  return (
    <div className={cls(classes['step-description'], className)}>
      <div className={cls(classes['step-description__caption'])} dangerouslySetInnerHTML={{ __html: t(`survey-page.steps.${step}.caption`)}} />
      {
        withDescription && 
          <div className={cls(classes['step-description__description'])} dangerouslySetInnerHTML={{ __html: t(`survey-page.steps.${step}.description`)}} />
      }
    </div>
  );
}

export default Element
