import React from "react";
import * as Queries from 'queries/authorization';
import FeedbackPageVew from 'components/views/main/feedback';

const FeedbackPage = () => {
  const { data } = Queries.useGetManagerComments()

  return <FeedbackPageVew comments={(data && data.ManagerComments)||null} /> 
}

export default FeedbackPage;
