import React from "react";
import cls from "classnames";
import classes from "./styles.module.scss";

import TabTitle from "./title";

const BankTabs = ({ bankId, bankInfo=[], onClick, bankList = null }) => {
  return (
    <ul className={cls(classes['bank-tabs-container'])}>
    {
      bankInfo.map((item, index)=>{
        return <li 
                onClick={(!bankList || bankList[item.BankId]) ? ()=>onClick(item.BankId, index):null} 
                className={
                  cls(classes['bank-tabs-container__li'], 
                  bankId === item.BankId ? classes['bank-tabs-container__li_active']:'',
                  (bankList && !bankList[item.BankId]) ? classes['bank-tabs-container__disabled']:''
                  )
                }
                key={`bank-tab-${index}`}
               >
                <TabTitle item={item}/>
               </li>
      })
    }
    </ul> 
  );
}

export default BankTabs
