import React from 'react';
import cls from 'classnames';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import WhitePaper from 'components/elements/white-paper';
import FeedbackMessages from 'components/organizms/feedback-messages';
import FeedbackContacts from 'components/organizms/feedback-contacts';
import { FeedbackEmptyIcon } from 'components/elements/icons';
import classnames from './feedback.module.scss';

import MUITabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const Tabs = styled(MUITabs)(({ theme }) => ({
  overflow: 'visible',
  '& .MuiTabs-scroller': {
    overflow: 'visible!important'
  },
  '& .MuiTabs-indicator': {
    height: '3px',
    bottom: '-2px',
    backgroundColor: '#8B74D8'
  },
  '& .MuiTab-root': {
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000000',
    '&.Mui-selected': {
      color: '#8B74D8'
    }
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

const FeedbackPageView = ({ comments }) => {
  //comments = [{IsNew:"", ManagerName:"", Comment:"", Date:"2022-12-12", QuestionnairesId:"", Type:""}]
  const { t } = useTranslation('main');
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };  

  return  <div className={cls(classnames['feedback-page'])}>
            {
              (!comments || comments.length === 0)  && <WhitePaper className={cls(classnames['feedback-page__empty-container'])}>
                <div className={cls(classnames['feedback-page__empty'])}>
                  <FeedbackEmptyIcon height='200' width='200' />
                  <div className={cls(classnames['feedback-page__title'])}>
                    {t('feedback-page.title')}
                  </div>
                  <div className={cls(classnames['feedback-page__hint'])}>
                    {t('feedback-page.hint')}
                  </div>
                </div>
              </WhitePaper>
            }
            {
              (comments && comments.length > 0) && 
              <WhitePaper>
                <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                  <Tabs
                    onChange={handleChange}
                    value={value}
                    aria-label="Tabs where selection follows focus"
                    selectionFollowsFocus
                  >
                    {
                      t('feedback-page.tabs', { returnObjects: true }).map((i, index)=>{
                        return <Tab key={`t-${index+1}`} label={i} />
                      })
                    }
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <FeedbackMessages messages={comments} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <FeedbackContacts />
                </TabPanel>
              </WhitePaper>              
            }

          </div>
}

export default FeedbackPageView;

