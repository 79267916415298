import React from 'react';
import ContentsBlock from 'components/organizms/main-contents-block';

const MainPageView = ({ ...props }) => {
  return  <>
            <ContentsBlock {...props} />
          </>
}

export default MainPageView;
