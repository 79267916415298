import React from "react";
import { useOutletContext } from "react-router-dom";
import ProfilePageVew from 'components/views/main/profile';

const ProfilePage = () => {
  const [ customerState, setCustomerState ] = useOutletContext();
  return <ProfilePageVew customerState={customerState} setCustomerState={setCustomerState} /> 
}

export default ProfilePage;
